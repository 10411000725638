import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { VoteStyled } from './styled';
import { iconImages } from 'assets';
import { useSelector } from 'react-redux';
import { userService } from 'apiService';
import {
  InputSuggestVote,
  BoxVote,
  // ChartTable
} from 'components';
import { toast } from 'react-toastify';

export const Vote = (props) => {
  const [_data, _setData] = useState();
  const [_trickFecthData, _setTrickFecthData] = useState();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      let res = await userService.GET_VOTE_LIST();
      if (res && res.success) {
        _setData(res.data);
      }
    };
    fetchData();
  }, [_trickFecthData]);

  const handleClickVote = async (e) => {
    if (auth) {
      let action = 'vote_success';
      // Tracking user event
      ReactGA.event({
        category: 'creator',
        action: action,
        value: parseInt(e),
      });
      let res = await userService.POST_VOTE_DETAIL(e);
      if (res && res.success) {
        _setTrickFecthData(!_trickFecthData);
        toast.success('โหวต สำเร็จ');
        action = 'vote_success';
      } else {
        toast.error('คุณโหวตครบแล้วต่อหนึ่งวัน หรือโหวตซ้ำคนเดิม');
        action = 'vote_fail';
      }
    } else {
      toast.error('กรุณาล็อคอินก่อนทำการโหวต');
    }
  };

  // renderRangk = () => {};

  return (
    <VoteStyled>
      <div
        className="text_title_2"
        style={{ backgroundImage: `url(${iconImages['bg1.png']})` }}
      >
        <div className="group_text">
          <div className="text_layer_2">VOTE YOUR WOW !</div>
          <div className="text_detail">โหวดศิลปินดาราที่คุณอยากเจอใน meWOW</div>
        </div>
      </div>
      <div className="vote_form">
        <InputSuggestVote handleClickVote={handleClickVote} />
      </div>
      <div className="group_vote">
        {_data &&
          _data.map((e, i) => (
            <div key={i} className="box_wrap">
              <BoxVote
                theme_standard
                index_key={e.id}
                src={
                  e.posters.length > 0
                    ? e.posters[0].uri
                    : iconImages['yellowbg_member.png']
                }
                icon_pic={
                  e.current_rank !== e.previous_rank &&
                  (e.current_rank > e.previous_rank
                    ? iconImages['red_arrow.png']
                    : iconImages['blue_light_arrow.png'])
                }
                vote={e.total_votes}
                num_text={e.current_rank !== '0' ? e.current_rank : ''}
                name_text={e.displayname}
                onClick={handleClickVote}
              />
            </div>
          ))}
      </div>
      {/* <div className="chart_layout">
        <ChartTable theme_standard data={data} />
      </div> */}
    </VoteStyled>
  );
};
