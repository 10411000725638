import React from 'react';
import { connect } from 'react-redux';
import { AgentContainerStyled } from './styled';
import { adminService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ReactTable, ButtonCustom } from 'components';
import { ROUTE_PATH } from 'helpers';
import { Helmet } from "react-helmet";

class AgentContainer extends React.Component {
  state = { data: [] };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_AGENT_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  handdleClickAdd = (e) => {
    this.props.history.push(ROUTE_PATH.A_AGENT + '/create');
  };

  handdleClickDetail = (e) => {
    this.props.history.push(ROUTE_PATH.A_AGENT + '/' + e);
  };

  render() {
    const { data } = this.state;
    return (
      <AgentContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="main_layout">
          <div className="tab_layout">
            <div className="title_layout">
              AR System
            </div>
            <div className="button_layer">
              <ButtonCustom
                theme_standard
                label="New AR"
                onClick={this.handdleClickAdd}
              />
            </div>
          </div>
          <ReactTable
            columns={columns({ handdleClickDetail: this.handdleClickDetail })}
            data={data}
          />
        </div>
      </AgentContainerStyled>
    );
  }
}

const columns = ({ handdleClickDetail }) => [
  {
    Header: 'Displayname',
    accessor: 'displayname',
    style: { width: '350px' },
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Detail',
    accessor: 'id',
    style: { width: '10px' },
    Cell: (e) => (
      <button onClick={() => handdleClickDetail(e.value)}>detail</button>
    ),
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentContainer);
