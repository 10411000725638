import React, { useState, useEffect } from 'react';
import { CategoriesStyled } from './styled';
import { userService } from 'apiService';
import { BoxImage } from 'components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

export const Categories = (props) => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let res = await userService.GET_CATEGORIES_LIST();
      if (res && res.data) {
      setData(res.data);
        }
    };
    fetchData();
  }, []);

  const handleClickCategories = (slug) => {
    props.propsHistory.push(`/main/categories/${slug}`);
  };
  return (
    <CategoriesStyled>
      {data && data.length > 0 &&(
        <>
          <div className="title_container">
            <div className="text_title">Categories</div>
          </div>
          <div className="hightlight_layer">
            {data && (
              <Carousel
                infinite
                responsive={responsive}
              >
                {data &&
                  data.map((e, i) => (
                    <div
                      key={i}
                      className="box_wrap"
                      onClick={() => handleClickCategories(e.slug)}
                    >
                      <BoxImage
                        theme_categories
                        classManual="dynamic"
                        src={(e.poster_photo_uri && e.poster_photo_uri.length > 0) && e.poster_photo_uri}
                        txttop = {e.title}
                      />
                    </div>
                  ))}
              </Carousel>
            )}
          </div>
          <div className="box_booking_container_mobile a">
            {data &&
              data.map((e, i) => (
                <div
                  key={i}
                  className="box_wrap"
                  onClick={() => handleClickCategories(e.slug)}
                >
                 <BoxImage
                    theme_categories
                    src={(e.poster_photo_uri && e.poster_photo_uri.length > 0) && e.poster_photo_uri}
                    txttop = {e.title}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </CategoriesStyled>
  );
};
// const data = [
//     {
//         title : 'World of Entertainer',
//         src : 'entertainer.png'
//     },
//     {
//         title : 'Underworld of Hiphop',
//         src : 'hiphop.png'
//     },
//     {
//         title : 'Heaven of Models',
//         src : 'model.png'
//     },
//     {
//         title : 'Field of Sport',
//         src : 'sport.png'
//     },
//     {
//         title : 'Hall of Music',
//         src : 'music.png'
//     },
// ]
