import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const UpComingContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  max-width: 1400px;
  margin: auto;
  .text_title {
    font-size: ${FONT.SIZE_20};
    color: ${COLOR.YELLOW_4};
    text-shadow: 2px 2px 2px ${COLOR.BLUE_6};
    text-align: center;
    padding: 20px;
    background: ${COLOR.WHITE_1};
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    .item_wrap {
      width: calc(20% - 10px);
      margin: 5px;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .container {
      .item_wrap {
        width: calc(33% - 10px);
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .container {
      .item_wrap {
        width: calc(50% - 10px);
      }
    }
  }
`;
