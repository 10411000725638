import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const InputCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    width: 100%;
    display: flex;

    .input_layer {
      width: 100%;
      margin-right: 10px;
      position: relative;
      .input {
        width: 96%;
        font-size: ${FONT.SIZE_5};
        font-family: unicaOne_regular;
        padding: 10px 20px;
        ::placeholder {
          color: ${COLOR.GREY_2};
          font-family: unicaOne_regular;
        }
      }
      .error_wrap {
        color: ${COLOR.RED_1};
        font-size: ${FONT.SIZE_1};
      }
      .label_on_input {
        position: absolute;
        top: 12px;
        right: 20px;
        color: ${COLOR.BLACK_4};
      }
   
      @media (max-width: 900px) {
        .input {
          width: 82% !important;
          font-size: 8px !important;
          padding: 14px 20px !important;
        }
      }
    }
    .button {
      cursor: pointer;
      font-family: unicaOne_regular;
      font-size: ${FONT.SIZE_5};
      color: ${COLOR.YELLOW_2};
      padding: 10px 30px;
      border: 1px solid ${COLOR.GREY_2};
      font-weight: 600;
      border-radius: 5px;
      background: ${COLOR.BLUE_5};
      // background-image: linear-gradient(to right, #7bfffa, #48e1f1, #03c1e6, #00a2d7, #0082c2, #1e78c3, #396dc1, #5160bc, #6f64d1, #9166e3, #b664f1, #dd5ffb);
    }
  }
  .theme_footer {
    width: 100%;
    display: flex;
    .input_layer {
      width: 100%;
      position: relative;
      .input {
        width: 89.5%;
        font-size: ${FONT.SIZE_2};
        font-family: unicaOne_regular;
        padding: 5px 10px;
      }
      .label_on_input {
        position: absolute;
        top: 12px;
        right: 20px;
        color: ${COLOR.BLACK_4};
      }
      .error_wrap {
        color: ${COLOR.RED_1};
        font-size: ${FONT.SIZE_1};
      }
    }
    .button {
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 10px;
        width: 10px;
        // padding: 5px 10px;
      }
    }
  }
  .theme_log_in {
    width: 100%;
    .input_layer {
      width: 100%;
      .input {
        width: 100%;
        font-size: ${FONT.SIZE_2};
        font-family: unicaOne_regular;
        border-bottom: 1px solid ${COLOR.GREY_2};
        padding: 0;
        border: none;
        border-radius: 0;
        line-height: 1.3;
        -webkit-appearance: none;
        box-shadow: inset 0 -1px 0 #ddd;
        font-size: ${FONT.SIZE_7};
        :focus {
          outline: none;
          border-bottom: 2px solid ${COLOR.BLUE_6};
          box-shadow: none;
        }
        ::placeholder {
          color: ${COLOR.GREY_2};
          font-family: unicaOne_regular;
        }
      }
      .error_wrap {
        color: ${COLOR.RED_1};
        font-size: ${FONT.SIZE_1};
      }
    }
  }
`;
