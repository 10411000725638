import styled from 'styled-components';
import {
  COLOR,
  // FONT
} from 'assets';

export const OrderFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .row {
    display: flex;
    margin-bottom: 10px;
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0px 10px 0px;
  }
  .text_1 {
    font-weight: 600;
    font-size: 16px;
    margin: 0px 0px 0px 0px;
  }
  .input_wrap {
    margin-bottom: 10px;
    &.dropdown {
      padding-top: 4px;
    }
    &.check_approve {
      margin-top: 15px;
    }
  }
  .buttun_coupon_wrap {
    width: 30%;
    display: flex;
    align-items: center;
    .button_coupon {
      width: 100%;
      background: ${COLOR.YELLOW_1};
      color: #0059b3;
      height: fit-content;
      padding: 6px 5px;
      border-radius: 51px;
      border: none;
      font-size: 12px;
      font-weight: 900;
    }
  }

  .button_layout {
    .button_wrap {
      cursor: pointer;
      margin: 10px 0px 10px 0px;
      width: 100%;
      height: 40px;
      border-radius: 2px;
      border: none;
      background-color: #0059b3;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      .text_cut {
        text-decoration: line-through;
      }
      &.disabled {
        cursor: not-allowed;
        background-color: ${COLOR.GREY_1};
      }
    }
  }
`;
