import React, { useRef } from 'react';
import { InputTextArea2Styled } from './styled';

export const InputTextArea2 = ({
  input,
  meta: { touched, error },
  placeholder,
}) => {
  const inputEl = useRef(null);

  const auto_grow = (element) => {
    inputEl.current.style.height = '16px';
    inputEl.current.style.height = inputEl.current.scrollHeight + 'px';
  };

  const handlerTextChange = (e) => {
    let val = e.target.value;
    auto_grow();
    input.onChange(val);
  };

  return (
    <InputTextArea2Styled>
      <textarea
        maxLength="250"
        ref={inputEl}
        {...input}
        onChange={handlerTextChange}
        placeholder={placeholder}
      />
      <span className="textarea__count">{input.value.length}/250</span>
      {touched && error && <div className="error_wrap">{error}</div>}
    </InputTextArea2Styled>
  );
};
