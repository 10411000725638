export const ROUTE_PATH = {
  HOME: "/",

  LOGIN: "/page/login",
  SIGN_UP: "/page/signup",
  SIGN_UP_REFERAL: "/page/signup/:ref",
  PRIVACY: "/page/privacy",
  TERMS: "/page/terms",
  // REVIEW_PAGE: '/page/reviewpage',

  REFERAL: "/main/referal",
  HIGH_LIGHT: "/main/highlight",
  NEW_ARRIVAL: "/main/new_arrival",
  EXPLORE: "/main/explore",
  UPCOMING: "/main/upcoming",
  WHEELS: "/main/wheels",
  WHEELS_DETAIL: "/main/wheels/:id",
  CATEGORIES: "/main/categories/:slug",
  FOLLOWING: "/main/following",
  CREATOR: "/main/creator",
  CREATOR_DETAIL: "/main/creator/:id",
  CREATOR_SEARCH_DETAIL: "/main/creatorsearch",
  ORDER_CREATOR: "/main/order/creator",
  ORDER_CREATOR_DETAIL: "/main/order/creator/:id/:service",
  ORDER_SUCCESS: "/main/order/success",
  ORDER_SUCCESS_DETAIL: "/main/order/success/:id",
  PROFILE: "/main/profile",
  BOOKING: "/main/booking",
  BOOKING_DETAIL: "/main/booking/:id",
  PAYMENT_PROCESS_MAIN: "/main/payment",
  PAYMENT_PROCESS: "/main/payment/:id/callback",
  CREATOR_REGISTER: "/main/creator_register",

  //ADMIN
  A_CREATOR: "/admin/creators",
  A_CREATOR_DETAIL: "/admin/creators/:id",
  A_CATEGORY: "/admin/category",
  A_CATEGORY_DETAIL: "/admin/category/:id",
  A_GENRE: "/admin/genres",
  A_GENRE_DETAIL: "/admin/genres/:id",
  A_WHEELS: "/admin/wheels",
  A_WHEELS_DETAIL: "/admin/wheels/:id",
  A_REFUND: "/admin/refund",
  A_REFUND_DETAIL: "/admin/refund/:id",
  A_ACCOUNTING: "/admin/accounting",
  A_ACCOUNTING_DETAIL: "/admin/accounting/:id",
  A_ENROLL: "/admin/enroll",
  A_ORDER: "/admin/order",
  A_COUPON: "/admin/coupon",
  A_VOTE: "/admin/vote",
  A_AGENCY: "/admin/agencys",
  A_AGENCY_DETAIL: "/admin/agencys/:id",
  A_AGENT: "/admin/agents",
  A_AGENT_DETAIL: "/admin/agents/:id",
  WOW_MANAGE: process.env.REACT_APP_MANAGE_ENDPOINT,
};

export const VALIDATE = {
  REQUIRE: (value) =>
    value || typeof value === "number" ? undefined : "Required",
  NUMBER: (value) =>
    value
      ? isNaN(Number(value))
        ? "Must be a number"
        : undefined
      : "Must be a number",
  NUMBER_ONLY: (value) =>
    value && isNaN(Number(value)) ? "Must be a number" : undefined,
  EMAIL: (value) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "email invalid"
      : undefined,
  MIN_LENGTH: (value) =>
    value
      ? value.length < 6
        ? `Must be 6 characters or more`
        : undefined
      : `Must be 6 characters or more`,
  MAX_LENGTH: (value) =>
    value
      ? value.length > 191
        ? `The characters must be less than 191 characters.`
        : undefined
      : `The characters must be less than 191 characters.`,
  MIN_PW_LENGTH: (value) =>
    value
      ? value.length < 8
        ? `Must be 8 characters or more`
        : undefined
      : `Must be 8 characters or more`,
  PASSWORD_MATCH: (value, allValues) =>
    value !== allValues.password ? "Passwords do not match" : undefined,
  PHONE_NUMBER: (value) =>
    value && !/^([0-9][0-9]{9})$/i.test(value)
      ? "Invalid phone number, must be 10 digits"
      : undefined,
};

// export const IMAGE_PATH = "http://localhost:8089/static/";
export const IMAGE_PATH = "https://backend.planprogramrmutl.com/static/";
