import React from 'react';
import { LoadingPageStyled } from './styled';
import { iconImages } from 'assets';

export const LoadingPage = (props) => {
  return (
    <LoadingPageStyled>
      <div className="grounp_img">
        <img src={iconImages['logo_web.png']} alt="wow logo" />
      </div>
    </LoadingPageStyled>
  );
};
