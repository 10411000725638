import React from 'react';
import { connect } from 'react-redux';
import { AgencyProfileContainerStyled } from './styled';
import { adminService } from 'apiService';
import { toast } from 'react-toastify';
import { setReduxUserAuth } from 'actions';
import { AgencyProfileFormSec1 } from 'forms/AdminFormSystem';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ROUTE_PATH } from 'helpers';

class AgencyProfileContainer extends React.Component {
  state = { isAdd: true };

  componentDidMount() {
    const { agencyID } = this.props;
    if (agencyID) {
      this.fetchData();
      this.setState({
        isAdd: agencyID ? false : true
      })
    }
  }

  fetchData = async () => {
    const { agencyID } = this.props;
    let res = await adminService.GET_AGENCY_DETAIL(agencyID);
    if (res && res.success) {
      this.setState({
        data: res.data,
        initialValues: {
          ...res.data,
        },
      });
    }
  };

  handdleSave = async () => {
    // const { data } = this.state;
    const {
      reduxForm: { values, syncErrors },
      agencyID,
      callback,
    } = this.props;
    let params = {
      ...values,
    };

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (!agencyID) {
        let res = await adminService.POST_AGENCY_ADD(params);
        if (res && res.success) {
          this.setState({
            isAdd: false
          })
          toast.success('บันทึกสำเร็จ');
          callback && callback(res.data.id);
        } else {
          toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
        }
      } else {
        let res = await adminService.PATCH_AGENCY_UPDATE(agencyID, params);
        if (res && res.success) {
          toast.success('แก้ไขข้อมูลสำเร็จ');
        } else {
          toast.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล');
        }
      }
    }
  };

  handdleDelete = async () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handdleDeleteAgency(),
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  handdleDeleteAgency = async () => {
    const { agencyID } = this.props;

    let res = await adminService.DELETE_AGENCY_DELETE(agencyID);
    if (res && res.success) {
      toast.success('ลบสำเร็จ');
      this.props.propsHistory.push(ROUTE_PATH.A_AGENCY);
    } else {
      toast.error('เกิดข้อผิดพลาดในการลบข้อมูล');
    }
  };

  render() {
    const { initialValues, isAdd } = this.state;
    return (
      <AgencyProfileContainerStyled>
        <AgencyProfileFormSec1
          initialValues={initialValues}
          handdleSave={this.handdleSave}
          handdleDelete={this.handdleDelete}
          isAdd={isAdd}
        />
      </AgencyProfileContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AgencyProfileFormSec1,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyProfileContainer);
