import styled from 'styled-components';
import { COLOR } from 'assets';

export const ShraeSocialStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .backdrop_container_layout {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    align-items: flex-end;
    .shared_container_layout {
      width: 100%;
      background: ${COLOR.WHITE_1};
      .shared_layout {
        max-width: 700px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
      }
    }
  }
`;
