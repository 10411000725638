import styled from "styled-components"

export const DropdownCustom2Styled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        .select_item {
            width: 100%;
            border: 1.1px solid;
            border-radius: 5px;
            padding: 8px;
        }
    }
`