import styled from 'styled-components';
// import { COLOR, FONT } from 'assets';

export const OrderSuccessFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .title {
    margin: 0;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .descroption_container {
    margin: auto;
    max-width: 650px;
    .text_group {
      display: flex;
      margin-bottom: 10px;
      .text_label {
        width: 50%;
      }
      .text_detail {
        width: 50%;
      }
    }
  }
  .button {
    button {
      cursor: pointer;
      font-size: 16px;
      font-family: unicaOne_regular;
      color: #ffd11a;
      background: #0059b3;
      padding: 10px 120px;
      border: 1px solid #d0d3cd;
      font-weight: 600;
      border-radius: 5px;
      margin: 5px;
    }
    text-align: center;
    padding-top: 20px;
    .cancel_link {
      cursor: pointer;
      padding-top: 5px;
    }
    .warning_text {
      padding-top: 20px;
      font-size: 12px;
    }
  }
`;
