import React, { useState, useEffect } from 'react';
import { FanshareStyled } from './styled';
import ReactGA from 'react-ga';
import { iconImages } from 'assets';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
import {
  BoxVideoImage,
  // ButtonCustom
} from 'components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
};

export const Fanshare = (props) => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let res = await userService.GET_FAN_SHARE_LIST();
      if (res && res.data) {
        setData(res.data);
      }
    };
    fetchData();
  }, []);

  const handleClickCreator = (id) => {
    ReactGA.event({
      category: 'video_public',
      action: 'click_fanshare',
    });
    props.propsHistory.push(`${ROUTE_PATH.CREATOR}/${id}?linkto=video_layout`);

  };

  // const handleClickViewMore = () => {
  //   props.propsHistory.push(`${ROUTE_PATH.EXPLORE}`);
  // };

  return (
    <FanshareStyled>
      <div className="title_container">
        <div className="text_title">Fan Share</div>
        {/* <div className="button_layout">
          <ButtonCustom
            theme_standard
            label="VIEW MORE"
            onClick={handleClickViewMore}
          />
        </div> */}
      </div>
      <div className="hightlight_layer">
        {data && (
          <Carousel infinite responsive={responsive}>
            {data.map((e, i) => (
              <div
                key={i}
                className="box_wrap"
                onClick={() => handleClickCreator(e.creator.id)}
              >
                <BoxVideoImage
                  theme_standard
                  classManual="dynamic"
                  txtlabel={e.category.name_th}
                  src={e.poster_jpg_uri || iconImages['Loading.png']}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <div className="box_booking_container_mobile a">
        {data &&
          data.map((e, i) => (
            <div
              key={i}
              className="box_wrap"
              onClick={() => handleClickCreator(e.creator.id)}
            >
              <BoxVideoImage
                theme_standard
                name={e.displayname}
                detail={e.description}
                txtlabel={e.category.name_th}
                src={e.poster_jpg_uri || iconImages['Loading.png']}
              />
            </div>
          ))}
      </div>
    </FanshareStyled>
  );
};
