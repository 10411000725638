import React from 'react'
import { DropdownCustomStyled } from './styled'
import cx from "classnames";

export const DropdownCustom = ({ theme_standard, dataDropDown, disabled, input }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })

    return (
        <DropdownCustomStyled>
            <div className={customClass}>
                <select {...input} className="select_item" disabled={disabled} >
                    {
                        dataDropDown &&
                        dataDropDown.length > 0 &&
                        (
                            dataDropDown.map((e, i) => (
                                <option key={i} value={e.value}>{e.label}</option>
                            ))
                        )

                    }
                </select>
            </div>
        </DropdownCustomStyled>
    )
}