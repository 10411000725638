import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { RefundFormStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets'
import { VALIDATE } from 'helpers';
import {} from 'components';
import { Input, ButtonCustom, InputDropdownLine } from 'components';

class RefundForm extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchBankData();
  }

  fetchBankData = async () => {
    let res = await userService.GET_BANK_LIST();
    if (res && res.success) {
      this.setState({
        bankList: res.data,
      });
    }
  };

  render() {
    const { bankList } = this.state;
    const {
      handleSubmit,
      handleClickRefund,
      title,
      step,
      channelsRefunds,
    } = this.props;
    return (
      <RefundFormStyled>
        <div className="status_refund">
          {`สถานการณ์คืนเงิน : ${title || ''}`}
        </div>
        {step && step === 2 && (
          <form onSubmit={handleSubmit(handleClickRefund)}>
            <div className="title_form">Refund Information</div>
            <div className="title_form_1">
              โปรดกรอกข้อมูลการเงินสำหรับการขอคืนเงิน
            </div>
            <div className="position_form">
              <div className="pd_input">
                <div className="label">
                  <b>เลือกธนาคาร</b>
                </div>
                <div className="input_text">
                  <Field
                    theme_standard
                    name="bank_swiftcode"
                    component={InputDropdownLine}
                    validate={VALIDATE.REQUIRE}
                  >
                    {bankList &&
                      bankList.map((e, i) => (
                        <option key={i} value={e.swiftcode}>
                          {e.name_th}
                        </option>
                      ))}
                  </Field>
                </div>
              </div>
              <div className="pd_input">
                <div className="label">
                  <b>เลขบัญชี</b> (กรอกตัวเลขเท่านั้น)
                </div>
                <div className="input_text">
                  <Field
                    theme_standard_2
                    type="number"
                    name="account_no"
                    component={Input}
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>
              <div className="pd_input">
                <div className="label">
                  <b>ชื่อบัญชี</b>
                </div>
                <div className="input_text">
                  <Field
                    theme_standard_2
                    name="account_name"
                    component={Input}
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>
              <div className="pd_input">
                <div className="label">
                  <b>เบอร์โทรศัพท์</b>
                </div>
                <div className="input_text">
                  <Field
                    theme_standard_2
                    name="phone_no"
                    component={Input}
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>
              <div className="footer">
                <div>*หมายเหตุ: เราจะรีบดำเนินการคืนเงินท่านภายใน 7-14 วัน</div>
                <div>
                  เราจะพยายามทำให้เร็วที่สุดนะคะ
                  โปรดเช็คข้อมูลให้ถูกต้องก่อนกดขอคืนเงิน*
                </div>
              </div>
              <div className="btn_submit_wrap">
                <ButtonCustom theme_standard label="ขอคืนเงิน" />
              </div>
            </div>
          </form>
        )}
        {step && step === 3 && (
          <>
            <div className="status_refund_1">{`ช่องทางการคืนเงิน : ${channelsRefunds}`}</div>
            <img alt="refund receipt" />
          </>
        )}
      </RefundFormStyled>
    );
  }
}

export default reduxForm({
  form: 'RefundForm', // a unique identifier for this form
  enableReinitialize: true,
})(RefundForm);
