import React from 'react';
import { connect } from 'react-redux';
import { CreatorServicesContainerStyled } from './styled';
import { adminService } from 'apiService';
import { toast } from 'react-toastify';
import { setReduxUserAuth } from 'actions';
import { CreatorServicesForm } from 'forms/AdminFormSystem';
import { ReactTable, ButtonCustom, Modal } from 'components';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class CreatorServicesContainer extends React.Component {
  state = { isModal: false, services_id: '' };

  componentDidMount() {
    this.fetchData();
    this.fetchCategories();
  }

  fetchData = async () => {
    const { creatorID } = this.props;
    let res = await adminService.GET_CREATOR_SERVICES_LIST(creatorID);
    // console.log("resssssssssss ",res)
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  fetchCategories = async () => {
    let res = await adminService.GET_CATEGORIES_LIST();
    if (res && res.success) {
      this.setState({
        catogoriesOptions: res.data,
      });
    }
  };

  handdleClickDetail = async (e) => {
    const { catogoriesOptions } = this.state;
    const { creatorID } = this.props;
    let cateInitial = {};
    let res = await adminService.GET_CATEDGORY_GROUP_LIST_SERVICE(
      creatorID,
      e.id
    );
    if (res && res.success) {
      if (res.data) {
        res.data.categories.forEach((ev) => {
          cateInitial = { ...cateInitial, [ev.id]: true };
        });
        let photo;
        let video;

        photo = res.data.poster_photo_uri ? res.data.poster_photo_uri : undefined;
        video = res.data.poster_video_uri ? res.data.poster_video_uri : undefined;
        let params = {
          ...res.data,
          photo_file: photo,
          video_file: video,
        };
        delete params.categories;
        params.categories = cateInitial;
        // console.log('ressssssssssssss service', params)
        this.setState({
          isModal: true,
          services_id: e.id,
          renderModal: (
            <CreatorServicesForm
              initialValues={{ ...params }}
              handdleSave={this.handdleSave}
              handdleDelete={this.handdleDelete}
              isAdd={false}
              catogoriesOptions={catogoriesOptions}
            />
          ),
        });
      }
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      initialValues: null,
      services_id: '',
      renderModal: false,
    });
  };

  handleClickCreateService = () => {
    const { catogoriesOptions } = this.state;
    this.setState({
      services_id: '',
      isModal: true,
      renderModal: (
        <CreatorServicesForm
          handdleSave={this.handdleSave}
          handdleDelete={this.handdleDelete}
          isAdd={true}
          catogoriesOptions={catogoriesOptions}
        />
      ),
    });
  };

  handdleSave = async () => {
    const {
      reduxForm: { values, syncErrors },
      creatorID,
    } = this.props;

    const { services_id } = this.state;

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let params = {
        ...values,
        categories:
          !values.categories || Object.keys(values.categories).length === 0
            ? []
            : Object.keys(values.categories)
              .filter((key) => values.categories[key])
              .map((e1) => ({ id: e1 })),
      };
      // console.log("creatorID ", creatorID)
      // console.log("services_id ", services_id)
      // console.log("parammmmmmmmmmm ", params)
      if (services_id !== '') {
        let res = await adminService.PATCH_CREATOR_SERVICES_EDIT(
          creatorID,
          services_id,
          params
        );
        if (res && res.success) {
          toast.success('แก้ไขสำเร็จ');
          if (params.photo_file || params.video_file) {
            this.handleUploadCreatorService(creatorID, services_id)
          }
          this.fetchData();
          this.setState({
            isModal: false,
            renderModal: false,
          });

        } else {
          toast.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล');
        }
      } else {
        let res = await adminService.POST_CREATOR_SERVICES_ADD(
          creatorID,
          params
        );
        if (res && res.success) {
          toast.success('บันทึกสำเร็จ');
          if (params.photo_file || params.video_file) {
            this.handleUploadCreatorService(creatorID, res.data.id)
          }
          this.fetchData();
          this.setState({
            isModal: false,
            renderModal: false,
          });

        } else {
          toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
        }
      }
    }
  };

  handleUploadCreatorService = async (creatorID, services_id) => {
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
    };

    let formData = new FormData();
    if (params.photo_file && typeof params.photo_file === 'object')
      formData.append('photo_file', params.photo_file);
    if (params.video_file && typeof params.video_file === 'object')
      formData.append('video_file', params.video_file);

    if ((params.video_file && typeof params.video_file === 'object') || (params.photo_file && typeof params.photo_file === 'object')) {
      let res = await adminService.UPLOAD_CREATOR_SERVICE(creatorID, services_id, formData);
      if (res && res.success) {
        // console.log("upload resss ", res.data)
      } else {
        toast.error('เกิดข้อผิดพลาดในการอัพโหลดไฟล์');
      }
    } 
  };

  handdleDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handdleDeleteCreatorServices(),
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  handdleDeleteCreatorServices = async () => {
    const { creatorID } = this.props;
    const { services_id } = this.state;

    let res = await adminService.DELETE_CREATOR_SERVICES_DELETE(
      creatorID,
      services_id
    );
    if (res && res.success) {
      toast.success('ลบสำเร็จ');
      this.fetchData();
      this.setState({
        isModal: false,
      });
    } else {
      toast.error('เกิดข้อผิดพลาดในการลบข้อมูล');
    }
  };

  render() {
    const { data, isModal, renderModal } = this.state;
    return (
      <CreatorServicesContainerStyled>
        <fieldset>
          <legend>Creator Services</legend>
          {data && (
            <ReactTable
              columns={columns({ handdleClickDetail: this.handdleClickDetail })}
              data={data}
            />
          )}
          <div className="button_layout">
            <ButtonCustom
              theme_standard
              label="CREATE"
              onClick={() => this.handleClickCreateService()}
            />
          </div>
        </fieldset>
        <Modal
          theme_modal_border
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {renderModal}
        </Modal>
      </CreatorServicesContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CreatorServicesForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

const columns = ({ handdleClickDetail }) => [
  {
    Header: 'Services Name',
    accessor: 'name',
    // style: { width: '350px' },
  },
  {
    Header: 'Rate Coins',
    accessor: 'rate_coins',
    Cell: (e) => <div className="div_right">{e.value}</div>,
  },
  {
    Header: 'Detail',
    accessor: 'id',
    style: { width: '10px' },
    Cell: (e) => (
      <button onClick={() => handdleClickDetail(e.row.original)}>detail</button>
    ),
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorServicesContainer);
