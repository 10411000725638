import React from 'react';
import { connect } from 'react-redux';
import { RefundDetailContainerStyled } from './styled';
import { adminService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import {} from 'components';

class RefundDetailContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_CREATOR_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  render() {
    return (
      <RefundDetailContainerStyled>
        RefundDetailContainer
      </RefundDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundDetailContainer);
