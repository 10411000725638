import React, { useState, useEffect } from 'react';
import { InputSuggestUserStyled } from './styled';
import { adminService } from 'apiService';
import Autosuggest from 'react-autosuggest';
// import { Button } from 'components';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

export const InputSuggestUser = ({ input, meta: { touched, error } }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [_valueSelected, _setValueSelected] = useState('');

  useEffect(() => {
    if (input.value && !_valueSelected) {
      setValue(input.value);
    }
  }, [input.value, _valueSelected]);

  useEffect(() => {
    if (_valueSelected) {
      if (_valueSelected === 'reset') {
        input && input.onChange(false);
      } else {
        input && input.onChange(_valueSelected);
      }
    }
  }, [_valueSelected, input]);

  const onChange = (event, { newValue }) => {
    _setValueSelected('reset');
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    let res = await adminService.GET_SEARCH_CREATOR_LIST(
      value === '' ? undefined : value
    );
    if (res && res.success) {
      setSuggestions(res.data);
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'search user',
    value,
    id: 'inputtext',
    onChange: onChange,
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    _setValueSelected(suggestion);
  };

  const shouldRenderSuggestions = () => {
    return true;
  };

  return (
    <InputSuggestUserStyled>
      <div className="input_layout">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={(suggestion) => (
            <div>
              <div>{suggestion.name}</div>
              <div>{suggestion.email}</div>
            </div>
          )}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
        />
        {touched && error && <div  className="error_wrap">{error}</div>}
      </div>
    </InputSuggestUserStyled>
  );
};
