import React from 'react';
import { PromtPayStyled } from './styled';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
import { useHistory } from "react-router-dom";
// import { iconImages } from 'assets';
// import { toast } from 'react-toastify';

export const PromtPay = (props) => {
  const { children, invoiceID, amount, callback, qr_uri } = props;
  let history = useHistory();

  const handleClickPayment = () => {
    const { Omise } = window;
    Omise.setPublicKey(process.env.REACT_APP_OMISE_PUBLIC_KEY);
    Omise.createSource(
      'promptpay',
      {
        amount: Number(amount) * 100,
        currency: 'THB',
        capture: true,
        livemode: true,
      },
      function (statusCode, response) {
        handleSubmit(response);
      }
    );
  };

  const handleSubmit = async (token) => {
    let params = {
      gateway: 'omise',
      type: 'promptpay',
      token: token.id,
    };
    let res = await userService.POST_PAYMENT_DETAIL(invoiceID, params);
    // console.log("ressssssssssss ", res)
    if (res && res.success) {
      callback && callback('promptpay', res.data.qr_uri);
    }
  };

  const handlePyamentSuccess = async () => {
    // console.log("invoiceID ", invoiceID)
    let res = await userService.GET_PAYMENT_DETAIL_BY_INVOICE(invoiceID);
    // console.log("ressssssssssss ", res)
    if (res && res.success) {
      // this.props.history.push(ROUTE_PATH.PROFILE);
      history.push(ROUTE_PATH.PROFILE)
    }
  };

  return (
    <PromtPayStyled>
      {
        children
          ? (
            <div onClick={handleClickPayment}>{children}</div>
          )
          : (
            <div className="show_qr">
              <div className="group_obj">
                {/* <div className="top_qr">
                  <img
                    className="qr_payment_top"
                    src={iconImages['qr2.jpg']}
                    alt="qr"
                  />
                </div>
                <div className="middle_qr">
                  <img
                    className="qr_payment_middle"
                    src={iconImages['qr1.jpg']}
                    alt="qr"
                  />
                </div> */}
                <div className="bottom_qr">
                  <img
                    className="qr_payment"
                    src={qr_uri}
                    alt="qr"
                  />
                </div>
                <div className="btn_payment" onClick={handlePyamentSuccess}>ชำระเงินเสร็จสิ้น</div>
              </div>
            </div>

          )
      }
    </PromtPayStyled>
  );
};
