import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { LandingCreatorContainerStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { Helmet } from 'react-helmet';
import {} from 'components';
import { NavbarWeb, FooterPage } from 'widgets';
import { VideoPlayer } from 'components';
import { iconImages } from 'assets';
// import { img_tw } from "../../assets/img/icon/twitter.png";
import { ROUTE_PATH } from 'helpers';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

class LandingCreatorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();

    // Tracking user event
    ReactGA.event({
      category: 'video_public',
      action: 'view',
      nonInteraction: true,
      value: parseInt(this.props.match.params.id),
    });
  }

  fetchData = async () => {
    let res = await userService.GET_V_DETAIL(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        data: res.data,
        // url: `${window.location.origin}${ROUTE_PATH.CREATOR}/${this.props.match.params.id}`,
        url: `${window.location.origin}/v/${this.props.match.params.id}`,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickAccount = () => {
    this.props.history.push(
      `${ROUTE_PATH.CREATOR}/${this.state.data.order.creator.id}`
    );

    // Tracking user event
    ReactGA.event({
      category: 'video_public',
      action: 'click_creator',
      value: parseInt(this.state.data.order.creator.id),
    });
  };

  render() {
    const { data, url } = this.state;
    return (
      <LandingCreatorContainerStyled>
        {data && (
          <Helmet>
            <title>{data.order.creator.displayname}</title>
            <meta name="og:title" content={data.order.creator.displayname} />
            <meta name="og:url" content={url} />
            <meta name="og:image" content={data.poster_jpg_uri} />
            <meta name="fb:app_id" content={process.env.REACT_APP_FB_APP_ID} />
            <meta
              name="description"
              content="Get personalized messages from your favorite celebrities"
            />
          </Helmet>
        )}
        <NavbarWeb theme_standard propsHistory={this.props.history} />
        <div className="content_mainlayout">
          <div className="landing_layout">
            <div className="left_layout">
              <VideoPlayer
                width="279px"
                height="495px"
                url={data && data.file_original.uri}
                poster={data && data.poster_jpg_uri}
              />
            </div>
            <div className="right_layout">
              <h1>TO {data && data.order.giftee_name}</h1>
              <div className="landing_btn">
                <TwitterShareButton
                  className="btn_twt"
                  style={{ backgroundColor: null, color: null, font: null }}
                  url={url}
                >
                  <div className="btn_lay">
                    <img
                      className="icon_img"
                      src={iconImages['twitter.png']}
                      width="15px"
                      height="15px"
                      alt="."
                    />
                    <div className="text_btn">Share on Twitter</div>
                  </div>
                </TwitterShareButton>
                <FacebookShareButton
                  className="btn_twt face"
                  style={{ backgroundColor: null, color: null, font: null }}
                  appId={process.env.REACT_APP_FB_APP_ID}
                  url={url}
                >
                  <div className="btn_lay face">
                    <img
                      className="icon_img"
                      src={iconImages['facebook.png']}
                      width="15px"
                      height="15px"
                      alt="."
                    />
                    <div className="text_btn">Share on Facebook</div>
                  </div>
                </FacebookShareButton>
                <button className="btn_twt btn_dow">
                  <a
                    mimetype="application/octet-stream"
                    href={data && data.file_original.uri}
                    download={data && data.file_original.uri}
                  >
                    <div className="btn_lay">
                      <img
                        className="icon_img"
                        src={iconImages['download.png']}
                        width="15px"
                        height="15px"
                        alt="."
                      />
                      <div className="text_btn">Download Video</div>
                    </div>
                  </a>
                </button>
              </div>
              <div className="go_booking">
                <div
                  className="img_wrap"
                  onClick={() => this.handleClickAccount()}
                >
                  <img
                    className="member_img"
                    src={
                      data &&
                      data.order &&
                      data.order.creator.posters &&
                      data.order.creator.posters.length > 0
                        ? data.order.creator.posters[0].uri
                        : iconImages['yellowbg_member.png']
                    }
                    width="317px"
                    height="470px"
                    alt="."
                  />
                </div>
                <div
                  className="displayname"
                  onClick={() => this.handleClickAccount()}
                >
                  {data && data.order.creator.displayname}
                </div>
                <div
                  className="description"
                  onClick={() => this.handleClickAccount()}
                >
                  {data && data.order.creator.description}
                </div>
                <button
                  className="btn_book"
                  onClick={() => this.handleClickAccount()}
                >
                  BOOK {data && data.order.creator.displayname}
                </button>
              </div>
            </div>
          </div>
        </div>
        <FooterPage theme_standard propsHistory={this.props.history} />
      </LandingCreatorContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingCreatorContainer);
