import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { AgencyFormAgentStyled } from "./styled";
import { adminService } from "apiService";
import { InputSuggestAgent, ButtonCustom, BoxShow } from "components";
import { connect } from "react-redux";
import { toast } from 'react-toastify';

const selector = formValueSelector("AgencyFormAgent");

class AgencyFormAgent extends React.Component {
  state = { agentID: null };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { agencyID } = this.props
    let res = await adminService.GET_AGENCY_LIST_AGENT_BY_AGENCY_ID(agencyID);
    if (res && res.success) {
      this.setState({
        dataAgent: res.data
      })
    }
  };

  handleSelectedAgent = (e) => {
    this.setState({
      agentID: e ? e.id : null
    })
  }

  handdleSave = async () => {
    const { agentID } = this.state
    const { agencyID } = this.props
    let params = {
      id: agentID
    }

    if (agentID) {
      let res = await adminService.POST_AGENCY_ADD_AGENT(agencyID, params);
      if (res && res.success) {
        toast.success("บันทึกสำเร็จ");
        this.fetchData();
      } else {
        toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
      }
    } else {
      toast.error('กรุณาเลือกข้อมูล Agent ก่อนบันทึก');
    }
  }

  handdleRemove = async (e) => {
    const { agencyID } = this.props

    let res = await adminService.DELETE_AGENCY_DELETE_AGENT(agencyID, e);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.fetchData();
    } else {
      toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
    }
  }

  render() {
    const { dataAgent } = this.state
    return (
      <AgencyFormAgentStyled>
        <fieldset>
          <legend>AR</legend>
          {
            dataAgent &&
            <div className="group_show">
              <BoxShow
                theme_standard
                data={dataAgent}
                handdleClick={this.handdleRemove}
              />
            </div>
          }
          <div className="box_label">
            <Field
              theme_standard
              name="user_id"
              component={InputSuggestAgent}
              onChangeValue={this.handleSelectedAgent}
            />
          </div>
          <div className="button_layer">
            <ButtonCustom theme_standard label="ADD" onClick={this.handdleSave} />
          </div>
        </fieldset>
      </AgencyFormAgentStyled>
    );
  }
}

const mapStateToProps = (state) => {
  const slug = selector(state, "slug");
  return {
    slug,
  };
};

const mapDispatchToProps = {
  change,
};

export default reduxForm({
  form: "AgencyFormAgent", // a unique identifier for this form
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(AgencyFormAgent));
