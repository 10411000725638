import React from 'react';
import { BoxImageStyled } from './styled';
import cx from 'classnames';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export const BoxImage = ({
  theme_standard,
  theme_categories,
  index_key,
  src,
  name,
  detail,
  srctop,
  txttop,
  classManual,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_categories: theme_categories,
  });
  return (
    <LazyLoadComponent>
      <BoxImageStyled src={src} >
        <div key={index_key} className={`${customClass} ${classManual}`}>
          {srctop && (
            <div className="coming_soon">
              <img className="logo_coming" src={srctop} alt="logo" />
            </div>
          )}
          {!txttop && <div className="group_text">
            <div className="name_layer">{name}</div>
            <div className="detail_layer">{detail}</div>
          </div>}
          {txttop && (
            <div className="txt_top">
              {txttop}
            </div>
          )
          }
        </div>
      </BoxImageStyled>
    </LazyLoadComponent>
  );
};
