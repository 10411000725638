import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const InputStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: ${(props) => (props.width ? props.width : '100%')};
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    input {
    }
    .error {
      padding-top: 5px;
      color: ${COLOR.RED_1};
    }
  }
  .theme_standard_2 {
    input {
      width: calc(100% - 16px);
      border: 1.1px solid;
      border-radius: 5px;
      padding: 8px;
    }
    .error {
      padding-top: 5px;
      color: ${COLOR.RED_1};
      font-size: ${FONT.SIZE_1};
    }
  }
`;
