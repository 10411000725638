import styled from "styled-components"
// import { 
//     COLOR,
//     FONT
//  } from 'assets'
 
export const InputCheckBoxMultiStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    height: 300px;
    overflow: auto;
    border-radius: 5px;
    border: 1px solid black;
    .theme_standard {
        background: white;
        padding: 0.5rem;
        .checkbox_wrap {
            padding-left: 1rem;
        }
    }
`