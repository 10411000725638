import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { CouponFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import {
  ButtonCustom,
  Input,
  DropdownCustom,
} from 'components';

class CouponForm extends React.Component {
  state = {};

  render() {
    const { handleSubmit, handleDelete, labelSave, isSave } = this.props;
    return (
      <CouponFormStyled>
        <div className="group_form">
          <div className="line_input">
            <div className="left_layout">
              Code
              </div>
            <div className="right_layout">
              <Field
                theme_standard_2
                name="code"
                placeholder="ANY CHAR"
                component={Input}
                validate={VALIDATE.MAX_LENGTH}
                disabled={!isSave}
              />
            </div>
          </div>
          <div className="line_input">
            <div className="left_layout">
              Quantity
              </div>
            <div className="right_layout">
              <Field
                theme_standard
                dataDropDown={dataDropDown}
                name="type"
                component={DropdownCustom}
                valueField="value"
                disabled={!isSave}
              />
            </div>
          </div>
          <div className="line_input">
            <div className="left_layout">
              Discount
              </div>
            <div className="right_layout">
              <div className="obj_1">
                <Field
                  theme_standard_2
                  name="discount_percents"
                  placeholder="%"
                  component={Input}
                  // validate={VALIDATE.NUMBER}
                />
              </div>
              <div>
                OR
                </div>
              <div className="obj_2">
                <Field
                  theme_standard_2
                  name="discount_coins"
                  placeholder="BATH"
                  component={Input}
                  // validate={VALIDATE.NUMBER}
                />
              </div>
            </div>
          </div>
          <div className="line_input">
            <div className="left_layout">
              Expired
              </div>
            <div className="right_layout">
              <Field
                theme_standard_2
                name="end_date"
                type="date"
                component={Input}
                // validate={VALIDATE.REQUIRE}
              />
            </div>
          </div>
          <div className="line_input no_margin_bottom">
            <div className="left_layout">

            </div>
            <div className="right_layout text_right">
              <ButtonCustom
                theme_standard_2
                label={labelSave}
                onClick={handleSubmit}
              />
              {
                isSave === false &&
                <div className="button_layout">
                  <ButtonCustom
                    theme_standard_2
                    label='DELETE'
                    onClick={handleDelete}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </CouponFormStyled>
    );
  }
}

const dataDropDown = [
  {
    label: 'SINGLE',
    value: 'SINGLE',
  },
  {
    label: 'MULTIPLE',
    value: 'MULTIPLE',
  },
]
export default reduxForm({
  form: 'CouponForm', // a unique identifier for this form
  enableReinitialize: true,
})(CouponForm);
