import React, { useState, useEffect, useRef } from 'react';
import { ShraeSocialStyled } from './styled';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LineShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
} from 'react-share';

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export const ShraeSocial = ({ children, url }) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState();

  useOutsideAlerter(wrapperRef, () => {
    handleClickCloseShare();
  });

  const handleClickShare = () => {
    setIsOpen(true);
  };

  const handleClickCloseShare = () => {
    setIsOpen(false);
  };

  return (
    <ShraeSocialStyled isOpen={isOpen}>
      <div onClick={handleClickShare}>{children}</div>
      <div className="backdrop_container_layout">
        <div className="shared_container_layout">
          <div className="shared_layout" ref={wrapperRef}>
            <div className="item_layout" onClick={handleClickCloseShare}>
              <EmailShareButton url={url}>
                <EmailIcon round />
              </EmailShareButton>
            </div>
            <div className="item_layout" og="www.google.com" onClick={handleClickCloseShare} >
              <FacebookShareButton appId={process.env.REACT_APP_FB_APP_ID} url={url}>
                <FacebookIcon round />
              </FacebookShareButton>
            </div>
            <div className="item_layout" onClick={handleClickCloseShare}>
              <FacebookMessengerShareButton appId={process.env.REACT_APP_FB_APP_ID} url={url}>
                <FacebookMessengerIcon round />
              </FacebookMessengerShareButton>
            </div>
            <div className="item_layout" onClick={handleClickCloseShare}>
              <LineShareButton url={url}>
                <LineIcon round />
              </LineShareButton>
            </div>
          </div>
        </div>
      </div>
    </ShraeSocialStyled>
  );
};
