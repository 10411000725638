import styled from 'styled-components';
import // COLOR,
// FONT
'assets';

export const PrivacyPolicyContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  max-width: 1100px;
  margin: auto;
  padding: 0 15px;
  .title {
    text-align: center;
  }
  .h2_title {

  }
  .text_highlight {
    font-family: prompt_medium;
  }
  .group_text {
    list-style-type: lower-roman;
  }
  .group_number_text {
    list-style-type: decimal;
  }
`;
