import styled from 'styled-components';
import {
  COLOR,
  //  FONT 
} from 'assets';

export const PromtPayStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .show_qr {
    background: ${COLOR.WHITE_1};
    width: 300px;
    padding: 15px;
    border: 0.5px solid #d0d6e2;
    border-radius: 10px;
    .group_obj {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top_qr {
        background: #203369;
        display: flex;
        align-items: center;
        justify-content: center;
        .qr_payment_top {
          width: 40%;
        }
      }
      .middle_qr {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        .qr_payment_middle {
          width: 30%;
        }
      }
      .bottom_qr {
        width: 100%;
        .qr_payment {
          width: 100%;
          height: auto;
        }
      }
      .btn_payment {
        margin-top: 10px;
        cursor: pointer;
        font-size: 16px;
        color: #ffd11a;
        background: #0059b3;
        padding: 5px 10px;
        border: 1px solid #d0d3cd;
        font-weight: 600;
        border-radius: 5px;
        width: 93%;
        text-align: center;
      }
    }
  }
`;
