import styled from "styled-components"
import { 
    // COLOR,
    // FONT
 } from 'assets'
 
export const AgentContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
  
`