import React from 'react';
import { ButtonCustomStyled } from './styled';
import cx from 'classnames';

export const ButtonCustom = ({ theme_standard, theme_standard_2, theme_haveFile, theme_yellow, label, onClick, disabled, type, uploadFile }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_haveFile: theme_haveFile,
    theme_yellow: theme_yellow,
  });
  return (
    <ButtonCustomStyled uploadFile={uploadFile}>
      <div className={customClass}>
        <button type={type} className="button" onClick={onClick} disabled={disabled}>
          {label}
        </button>
      </div>
    </ButtonCustomStyled>
  );
};
