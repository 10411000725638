import styled from "styled-components"
import {
  // COLOR, FONT 
} from 'assets';

export const AgentContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .main_layout {
      .tab_layout {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .title_layout {
          font-weight: bold;
        }
        .button_layer {

        }
      }
    }
`