import React from 'react';
import { PopupRegisterStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';

export const PopupRegister = ({
  theme_modal_standard,
  handleClickCloseModal,
}) => {
  const customClass = cx({
    theme_modal_standard: theme_modal_standard,
  });

  return (
    <PopupRegisterStyled className={customClass}>
      <div className="backdrop" onClick={handleClickCloseModal} />
      <div className="box" >
        <div className="header_layer_right">
            <img 
              src={iconImages['close.png']} 
              alt="close"  
              onClick={handleClickCloseModal}
            />
        </div>
        <div className="txt_title">
            Congratulations !
        </div>
        <div className="txt_detail1">
            คุณได้ทำการลงทะเบียนเรียบร้อยแล้ว 
        </div>
        <div className="txt_detail2">
            “น้องจะรีบติดต่อกลับโดยเร็วที่สุดนะคะ”
        </div>
        <div className="img_box">
            <img className="img_footer" src={iconImages['logo_web.png']} alt="" />
        </div>
      </div>
    </PopupRegisterStyled>
  );
};
