import styled from 'styled-components';
import { COLOR } from 'assets';
import { FONT, SCREEN } from '../../assets/styledComponent/constant';

export const BoxImage2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    padding: 30px;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    .group_action {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .round_layer {
        border-radius: 50%;
        background: red;
        height: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${COLOR.BLUE_5};
        font-weight: 600;
        font-size: ${FONT.SIZE_9};
        margin-bottom: 50px;
        background: ${COLOR.YELLOW_2};
      }
      img {
        height: 40px;
        margin-bottom: 50px;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard {
      .group_action {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .round_layer {
          margin-bottom: 0;
          margin-right: 10px;
        }
        img {
          width: 25px;
          height: auto;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      padding: 10px;
      text-align: center;
    }
  }
`;
