import React from 'react';
import { connect } from 'react-redux';
import { CmsMainlayoutStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { Route, Switch } from 'react-router-dom';
import cmsRoutes from 'routes/cmsRoutes';
import { Navbar, Sidebar } from 'widgets/AdminSystem';

class CmsMainlayout extends React.Component {
  state = {};

  componentDidMount() {
    this.checkPermission();
  }

  checkPermission = () => {
    const { authRedux } = this.props;
    if (!authRedux || !authRedux.admin) {
      this.props.history.push('/');
    }
  };

  render() {
    return (
      <CmsMainlayoutStyled>
        <Sidebar cmsRoutes={cmsRoutes} />
        <div className="right_mainlayout_layout">
          <div className="navbar_layout">
            <Navbar />
          </div>
          <div className="body_layout">
            <Switch >
              {cmsRoutes &&
                cmsRoutes.map((e, i) => {
                  return !e.sub ? (
                    <Route
                      key={i}
                      exact={e.exact}
                      path={e.path}
                      component={e.component}
                    />
                  ) : (
                    e.subComponent.map((e2, i2) => (
                    <Route
                      key={i}
                      exact={e.exact}
                      path={e.path}
                      component={e.component}
                    />
                  )
                  ));
                })}
            </Switch>
          </div>
        </div>
      </CmsMainlayoutStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CmsMainlayout);
