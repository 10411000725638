import React, { useState, useEffect } from 'react';
import { InputRadioStyled } from './styled';
import cx from 'classnames';

export const InputRadio = (props) => {
  const { theme_standard, options, input, radioType } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    const initial = () => {
      _setValue(input.value);
    };
    if (!_value) {
      initial();
    }
  }, [input.value, _value]);

  const handleChangeRadio = (ev) => {
    _setValue(ev);
    input.onChange(ev);
  };

  return (
    <InputRadioStyled>
      <div className={customClass}>
        {options &&
          options.map((e, i) => (
            <div key={i}>
              <input
                type="radio"
                id={e.value}
                value={e.value}
                checked={e.value === radioType}
                onChange={() => handleChangeRadio(e.value)}
              />
              <label htmlFor={e.value}>{e.label}</label>
            </div>
          ))}
      </div>
    </InputRadioStyled>
  );
};
