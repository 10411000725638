import React from "react";
import { connect } from "react-redux";
import { AgentContainerStyled } from "./styled";
// import { adminService } from "apiService";
// import { toast } from "react-toastify";
import { setReduxUserAuth } from "actions";
import { AgencyFormAgent } from "forms/AdminFormSystem";

class AgentContainer extends React.Component {
  state = {};

  componentDidMount() {

  }

  render() {
    const { initialValues } = this.state;
    const { agencyID } = this.props;

    return (
      <AgentContainerStyled>
        <AgencyFormAgent
          agencyID={agencyID}
          initialValues={initialValues}
          handleRemoveGenre={this.handleRemoveGenre}
          handdleSave={this.handdleSave}
        />
      </AgentContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AgencyFormAgent,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentContainer);
