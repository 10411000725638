import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SignUpFormStyled } from './styled';
// import { iconImages } from 'assets'
import { VALIDATE } from 'helpers';
import {} from 'components';
// import { findByLabelText } from "@testing-library/react";
import {
  ButtonCustom,
  Input,
  InputTextareaNormal,
  InputUploadFIleBase64,
} from 'components';

class SignUpForm extends React.Component {
  state = {};

  render() {
    const { handleSubmit, handleSubmitWithEmail } = this.props;
    return (
      <SignUpFormStyled>
        <form onSubmit={handleSubmit(handleSubmitWithEmail)}>
          <h2>Create Your Account</h2>
          <div className="position_form">
            <div className="pd_input">
              <div className="label">
                <b>Name</b>
              </div>
              <div>
                <Field
                  theme_standard_2
                  name="gifter.displayname"
                  placeholder="Enter Name"
                  component={Input}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
            <div className="pd_input">
              <div className="label">
                <b>Email</b>
              </div>
              <div>
                <Field
                  theme_standard_2
                  name="email"
                  placeholder="Enter Email"
                  component={Input}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
            <div className="pd_input">
              <div className="label">
                <b>Password</b>
              </div>
              <div>
                <Field
                  theme_standard_2
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  component={Input}
                  validate={[VALIDATE.REQUIRE, VALIDATE.MIN_PW_LENGTH]}
                />
              </div>
            </div>
            <div className="pd_input">
              <div className="label">
                <b>Re-enter Password</b>
              </div>
              <div>
                <Field
                  theme_standard_2
                  name="recheck-password"
                  type="password"
                  placeholder="Enter Password"
                  component={Input}
                  validate={VALIDATE.PASSWORD_MATCH}
                />
              </div>
            </div>
            <div className="pd_input">
              <div className="label">
                <b>Birthday</b>
              </div>
              <div>
                <Field
                  theme_standard_2
                  name="birthday"
                  type="date"
                  placeholder="Enter Birthday"
                  component={Input}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
            <div className="pd_input">
              <div className="label">
                <b>About you</b>
              </div>
              <div>
                <Field
                  theme_standard
                  name="gifter.about"
                  component={InputTextareaNormal}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
            <div className="pd_input">
              <div className="label">
                <b>Profile Picture</b>
              </div>
              <div>
                <Field
                  theme_standard
                  name="avatar"
                  component={InputUploadFIleBase64}
                />
              </div>
            </div>
            <div className="btn_submit_wrap">
              <ButtonCustom
                theme_standard
                label="SIGN UP"
                // onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </SignUpFormStyled>
    );
  }
}

export default reduxForm({
  form: 'SignUpForm', // a unique identifier for this form
  enableReinitialize: true,
})(SignUpForm);
