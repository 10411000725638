import React, { useState, useEffect } from 'react';
import { UpcomingStyled } from './styled';
import { iconImages } from 'assets';
import { userService } from 'apiService';
import { BoxImage, ButtonCustom, BoxImage3 } from 'components';
import { ROUTE_PATH } from 'helpers';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
};

export const Upcoming = (props) => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let res = await userService.GET_UPCOMING_LIST();
      if (res && res.data) {
        setData(res.data);
      }
    };
    fetchData();
  }, []);

  const handleClickCreator = (id) => {
    props.propsHistory.push(`${ROUTE_PATH.CREATOR}/${id}`);
  };

  const handleClickViewMore = () => {
    props.propsHistory.push(`${ROUTE_PATH.UPCOMING}`);
  };

  var bg = require('../../assets/img/icon/Loading.png');

  return (
    <UpcomingStyled>
      {data && data.length > 0 && (
        <>
          <div className="title_container">
            <div className="text_title">Upcoming</div>
            {
              data && data.length >= 10 &&
              <div className="button_layout">
                <ButtonCustom
                  theme_standard
                  label="VIEW MORE"
                  onClick={handleClickViewMore}
                />
              </div>
            }
          </div>
          <div className="hightlight_layer">
            {data && (
              <>
                <div className="carousel_wrap">
                  <Carousel
                    // additionalTransfrom={0}
                    // ssr={true}
                    // arrows
                    // autoPlaySpeed={3000}
                    // centerMode={false}
                    // className="custom"
                    // containerClass="carousel-container"
                    // dotListClass=""
                    // draggable
                    // focusOnSelect={false}
                    // infinite
                    // itemClass=""
                    // keyBoardControl
                    // minimumTouchDrag={80}
                    // renderButtonGroupOutside={false}
                    // renderDotsOutside={false}
                    responsive={responsive}
                  // showDots={false}
                  // sliderClass=""
                  // slidesToSlide={1}
                  // swipeable
                  >
                    {
                      data.map((e, i) => (
                        <div
                          key={i}
                          className="box_wrap"
                          onClick={() => handleClickCreator(e.id)}
                        >
                          {
                            <div
                              className="background-image"
                              style={{ backgroundImage: 'url(' + bg + ')' }}
                            >
                              <BoxImage
                                theme_standard
                                classManual="dynamic"
                                name={e.displayname}
                                detail={e.description}
                                src={
                                  (e.posters &&
                                    e.posters.length > 0 &&
                                    e.posters[0].uri) ||
                                  iconImages['Loading.png']
                                }
                                srctop={iconImages['coming_soon.png']}
                              />
                            </div>
                          }
                        </div>
                      ))
                    }
                    {
                      data && data.length >= 10 &&
                      <div
                        className="box_wrap"
                        onClick={() => handleClickViewMore()}
                      >
                        <BoxImage3
                          theme_standard_BoxImage3
                          classManual="dynamic"
                          src={
                            iconImages['logo2_web.png']
                          }
                        />
                      </div>
                    }
                  </Carousel>
                </div>
              </>
            )}
          </div>
          <div className="box_booking_container_mobile">
            {
              data &&
              data.map((e, i) => (
                <div
                  key={i}
                  className="box_wrap"
                  onClick={() => handleClickCreator(e.id)}
                >
                  {
                    <div
                      className="background-image"
                      style={{ backgroundImage: 'url(' + bg + ')' }}
                    >
                      <BoxImage
                        theme_standard
                        name={e.displayname}
                        detail={e.description}
                        src={
                          (e.posters && e.posters.length > 0 && e.posters[0].uri) ||
                          iconImages['Loading.png']
                        }
                        srctop={iconImages['coming_soon.png']}
                      />
                    </div>
                  }
                </div>
              ))
            }
            {
              data && data.length >= 10 &&
              <div
                className="box_wrap"
                onClick={() => handleClickViewMore()}
              >
                <BoxImage3
                  theme_standard_BoxImage3
                  classManual="dynamic"
                  src={
                    iconImages['logo2_web.png']
                  }
                />
              </div>
            }
          </div>
        </>
      )}
    </UpcomingStyled>
  );
};
