import React from 'react';
import { connect } from 'react-redux';
import { VoteContainerStyled } from './styled';
import { adminService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ReactTable } from 'components';
import { Helmet } from "react-helmet";

class VoteContainer extends React.Component {
  state = { data: [] };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_VOTE_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <VoteContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="main_layout">
          <div className="tab_layout">
            <div className="title_layout">
              Vote System
            </div>
          </div>
          <ReactTable
            columns={columns}
            data={data}
            notUsePagi
          />
        </div>
      </VoteContainerStyled>
    );
  }
}

const columns = [
  {
    Header: 'Name',
    accessor: 'displayname',
    // style: { width: '350px' },
  },
  {
    Header: 'Vote Bonus',
    accessor: 'extra_votes',
    Cell: (e) => (
      <div className="div_right">
        {e.value}
      </div>
    )
  },
  {
    Header: 'Vote Real Point',
    accessor: 'description',
    Cell: (e) => (
      <div className="div_right">
        {Number(e.row.original.total_votes) - Number(e.row.original.extra_votes)}
      </div>
    )
  },
  {
    Header: 'Total Vote',
    accessor: 'total_votes',
    Cell: (e) => (
      <div className="div_right">
        {e.value}
      </div>
    )
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoteContainer);
