import React from 'react';
import { InputCheckboxStyled } from './styled';
import cx from 'classnames';

export const InputCheckbox = ({ theme_standard, input, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <InputCheckboxStyled>
      <div className={customClass}>
        <label className="container">
          <input
            type="checkbox"
            name={input.name}
            checked={input.value}
            onChange={input.onChange}
          />
          <span className="checkmark"></span>
          {label}
        </label>
      </div>
    </InputCheckboxStyled>
  );
};
