import React from "react";
import { connect } from "react-redux";
import { CreatorGenreContainerStyled } from "./styled";
import { adminService } from "apiService";
import { toast } from "react-toastify";
import { setReduxUserAuth } from "actions";
import { CreatorGenreFormSec3 } from "forms/AdminFormSystem";

class CreatorGenreContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { creatorID } = this.props;
    let res = await adminService.GET_CATEDGORY_CREATOR_LIST(creatorID);
    if (res && res.success) {
      this.setState({
        data: res.data,
        initialValues: {
          ...res.data,
        },
      });
    }
  };

  handleRemoveGenre = async (slug) => {
    const { creatorID } = this.props;
    let res = await adminService.DELETE_CREATOR_GENRES(slug, creatorID);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.fetchData();
    }
  };

  handdleSave = async (slug) => {
    const {
      creatorID,
    } = this.props;
    let params = {
      id: creatorID,
    };
    if (!slug) {
      toast.error("กรุณากรอกข้อมูลให้ครบถ้วน", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let res = await adminService.POST_CREATOR_GENRES_ADD(slug, params);
      if (res && res.success) {
        toast.success("บันทึกสำเร็จ");
        this.fetchData();
      } else {
        toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
      }
    }
  };
  render() {
    const { initialValues, data } = this.state;
    return (
      <CreatorGenreContainerStyled>
        <CreatorGenreFormSec3
          data={data}
          initialValues={initialValues}
          handleRemoveGenre={this.handleRemoveGenre}
          handdleSave={this.handdleSave}
        />
      </CreatorGenreContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CreatorGenreFormSec3,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorGenreContainer);
