import styled from "styled-components"
import { 
    // COLOR, FONT 
  } from 'assets';
 
export const CreatorContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .main_layout {
      .button_layer {
        text-align: right;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
`