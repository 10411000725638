import styled from 'styled-components';
import // COLOR,
// FONT
'assets';

export const VideoPlayerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    object-fit: cover;
    border-radius: 0.6rem;
    &:focus {
      outline: unset;
    }
    :not(:root):fullscreen {
      object-fit: contain;
    }
  } 
  .txt_label {
    padding: 0 4px;
    position: absolute;
    bottom: 14px;
    right: 10px;
    background: #00000080;
    color: white;
    font-size: 14px;
  }
  .icon_play {
    cursor: pointer;
    position: absolute;
    img {
      width: 30px;
      height: auto;
    }
  }
`;
