import React, { useState, useEffect } from 'react';
import { WheelsStyled } from './styled';
import { iconImages } from 'assets';
import { userService } from 'apiService';
import { BoxImage, ButtonCustom, BoxImage3 } from 'components';
import { ROUTE_PATH } from 'helpers';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
};

export const Wheels = (props) => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let res = await userService.GET_WHEELS_LIST_RANDOM_CREATOR();
      // console.log("ressssssss ", res)
      if (res && res.data) {
        setData(res.data);
      }
    };
    fetchData();
  }, []);

  const handleClickCreator = (id) => {
    props.propsHistory.push(`${ROUTE_PATH.CREATOR}/${id}`);
  };

  const handleClickViewMore = (e) => {
    props.propsHistory.push(`${ROUTE_PATH.WHEELS}/${e}`);
  };

  var bg = require('../../assets/img/icon/Loading.png');

  return (
    <WheelsStyled>
      {data &&
        data.length > 0 &&
        data.map(
          (f, j) =>
            f.creators.length > 0 && (
              <>
                <div key={j + 1} className="title_container">
                  <div className="text_title">{f.title}</div>
                  {f.creators.length >= 10 && (
                    <div className="button_layout">
                      <ButtonCustom
                        theme_standard
                        label="VIEW MORE"
                        onClick={() => handleClickViewMore(f.slug)}
                      />
                    </div>
                  )}
                </div>
                <div className="hightlight_layer">
                  <div className="carousel_wrap">
                    <Carousel responsive={responsive}>
                      {f.creators.map((e, i) => (
                        <div
                          key={i + 1}
                          className="box_wrap"
                          onClick={() => handleClickCreator(e.id)}
                        >
                          {
                            <div
                              className="background-image"
                              style={{ backgroundImage: 'url(' + bg + ')' }}
                            >
                              <BoxImage
                                theme_standard
                                classManual="dynamic"
                                name={e.displayname}
                                detail={e.description}
                                src={
                                  (e.posters &&
                                    e.posters.length > 0 &&
                                    e.posters[0].uri) ||
                                  iconImages['Loading.png']
                                }
                              />
                            </div>
                          }
                        </div>
                      ))}
                      {f.creators.length >= 10 && (
                        <div
                          className="box_wrap"
                          onClick={() => handleClickViewMore(f.slug)}
                        >
                          <BoxImage3
                            theme_standard_BoxImage3
                            classManual="dynamic"
                            src={iconImages['logo2_web.png']}
                          />
                        </div>
                      )}
                    </Carousel>
                    {/* {f.creators.length < 10 ? (
                      <Carousel responsive={responsive}>
                        {f.creators.map((e, i) => (
                          <div
                            key={i + 1}
                            className="box_wrap"
                            onClick={() => handleClickCreator(e.id)}
                          >
                            {
                              <div
                                className="background-image"
                                style={{ backgroundImage: 'url(' + bg + ')' }}
                              >
                                <BoxImage
                                  theme_standard
                                  classManual="dynamic"
                                  name={e.displayname}
                                  detail={e.description}
                                  src={
                                    (e.posters &&
                                      e.posters.length > 0 &&
                                      e.posters[0].uri) ||
                                    iconImages['Loading.png']
                                  }
                                />
                              </div>
                            }
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                        <Carousel responsive={responsive}>
                          {f.creators.map((e, i) => (
                            <div
                              key={i + 1}
                              className="box_wrap"
                              onClick={() => handleClickCreator(e.id)}
                            >
                              {
                                <div
                                  className="background-image"
                                  style={{ backgroundImage: 'url(' + bg + ')' }}
                                >
                                  <BoxImage
                                    theme_standard
                                    classManual="dynamic"
                                    name={e.displayname}
                                    detail={e.description}
                                    src={
                                      (e.posters &&
                                        e.posters.length > 0 &&
                                        e.posters[0].uri) ||
                                      iconImages['Loading.png']
                                    }
                                  />
                                </div>
                              }
                            </div>
                          ))}
                          {f.creators.length >= 10 && (
                            <div
                              className="box_wrap"
                              onClick={() => handleClickViewMore(f.slug)}
                            >
                              <BoxImage3
                                theme_standard_BoxImage3
                                classManual="dynamic"
                                src={iconImages['logo2_web.png']}
                              />
                            </div>
                          )}
                        </Carousel>
                      )} */}
                  </div>
                </div>
                <div className="box_booking_container_mobile">
                  {f.creators.map((e, i) => (
                    <div
                      key={i}
                      className="box_wrap"
                      onClick={() => handleClickCreator(e.id)}
                    >
                      {
                        <div
                          className="background-image"
                          style={{ backgroundImage: 'url(' + bg + ')' }}
                        >
                          <BoxImage
                            theme_standard
                            name={e.displayname}
                            detail={e.description}
                            src={
                              (e.posters &&
                                e.posters.length > 0 &&
                                e.posters[0].uri) ||
                              iconImages['Loading.png']
                            }
                          />
                        </div>
                      }
                    </div>
                  ))}
                  {f.creators.length >= 10 && (
                    <div
                      className="box_wrap"
                      onClick={() => handleClickViewMore(f.slug)}
                    >
                      <BoxImage3
                        theme_standard_BoxImage3
                        classManual="dynamic"
                        src={iconImages['logo2_web.png']}
                      />
                    </div>
                  )}
                </div>
              </>
            )
        )}
    </WheelsStyled>
  );
};
