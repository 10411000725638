import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const PopupCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    font-size: ${FONT.SIZE_5};
    width: 300px;
    border: 1px solid ${COLOR.YELLOW_2};
    border-radius: 4px;
    background: ${COLOR.YELLOW_2};
    padding: 20px 15px 0px 15px;
    font-weight: bold;
    color: ${COLOR.WHITE_1};
    letter-spacing: 0.5px;
    position: relative;
    .close_layer {
      position: absolute;
      top: -12px;
      right: 2px;
      border: 3px solid ${COLOR.WHITE_1};
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      background: ${COLOR.GREY_1};
      font-size: ${FONT.SIZE_4};
      font-weight: 900;
      cursor: pointer;
    }
    .top_box {
      text-align: center;
      .label_1 {
        font-size: ${FONT.SIZE_1};
        color: ${COLOR.BLUE_5};
      }
      .label_2 {
        color: ${COLOR.BLUE_5};
      }
      .text_1 {
        font-size: ${FONT.SIZE_14};
        padding-left: 5px;
        .label_3 {
          font-size: ${FONT.SIZE_8};
          color: ${COLOR.BLUE_5};
        }
        .label_4 {
          color: ${COLOR.BLUE_5};
        }
      }
      .text_2 {
        font-size: ${FONT.SIZE_8};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
        .label_5 {
          padding: 6px 20px;
          background: ${COLOR.WHITE_1};
          color: ${COLOR.BLUE_5};
          margin: 0px 5px;
          outline: none;
          border: none;
          font-size: ${FONT.SIZE_8};
          width: 80px;
          font-weight: bold;
        }
        .label_6 {
          padding: 9px;
          background: ${COLOR.BLUE_5};
          font-size: ${FONT.SIZE_5};
          cursor: pointer;
        }
      }
    }
    .img_layer {
      display: flex;
      position: relative;
      .figture {
        max-width: 60px;
        width: 100%;
        .img_footer {
          width: 100%;
          height: auto;
        }
      }
      .text_3 {
        position: absolute;
        left: 72px;
        top: 13px;
        .label_7 {
          font-size: ${FONT.SIZE_1};
        }
        .label_8 {
          text-shadow: 2px 1px ${COLOR.BLUE_5};
        }
      }
    }
  }
`;
