import React, { useState, useEffect } from 'react';
import { InputCheckBoxMultiStyled } from './styled';
import cx from 'classnames';

export const InputCheckBoxMulti = ({ theme_standard, options, input }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    if (input.value !== checkedItems) {
      setCheckedItems(input.value);
    }
  }, [input.value,checkedItems]);

  const handleChange = (event) => {
    if (event.target.checked === false) {
      let temp = checkedItems;
      delete temp[event.target.name];
      setCheckedItems({
        ...temp,
      });
      input.onChange({
        ...temp,
      });
    } else {
      setCheckedItems({
        ...checkedItems,
        [event.target.name]: event.target.checked,
      });
      input.onChange({
        ...checkedItems,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleChangeReset = () => {
    setCheckedItems({});
    input.onChange({});
  };

  return (
    <InputCheckBoxMultiStyled>
      <div className={customClass}>
        <div className="checkbox_wrap">
          <label>
            <input
              type="checkbox"
              name="reset"
              value={' '}
              checked={Object.keys(checkedItems).length === 0}
              onChange={handleChangeReset}
            />
            ทั้งหมด
          </label>
        </div>
        {options &&
          options.map((e, i) => (
            <div key={i}>
              <div>{e.name_th}</div>
              {e.categories.map((f, j) => (
                <div key={j} className="checkbox_wrap">
                  <label>
                    <input
                      type="checkbox"
                      name={f.id}
                      value={f.name_th}
                      checked={checkedItems[f.id]}
                      onChange={handleChange}
                    />
                    {f.name_th}
                  </label>
                </div>
              ))}
            </div>
          ))}
      </div>
    </InputCheckBoxMultiStyled>
  );
};
