import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const BookingNoStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .title_container {
    width: 100%;
    display: flex;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    background-size: contain;
    .text_title {
      font-size: ${FONT.SIZE_20};
      color: ${COLOR.YELLOW_4};
      text-shadow: 2px 2px 2px ${COLOR.BLUE_6};
      text-align: center;
      padding: 20px;
      background: ${COLOR.WHITE_1};
    }
  }
`;
