import styled from 'styled-components';
import {
  SCREEN,
  // COLOR,
  // FONT
} from 'assets';

export const ReviewPageContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .content_mainlayout {
    padding-top: 131px;
  }
  span.upload_text {
    font-size: 12px;
    padding-left: 5px;
  }

  .rating {
    border: 3px solid;
    width: 500px;
    padding: 41px;
    border-radius: 13px;
    border-color: rgb(36, 71, 143);
  }

  .pasotion_rating {
    display: flex;
    justify-content: center;
    margin: 5%;
  }

  button.btn_upload {
    cursor: pointer;
    font-size: 11px;
    font-family: unicaOne_regular;
    color: rgb(0, 89, 179);
    font-weight: 600;
    background: rgb(255, 209, 26);
    padding: 3px 13px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(208, 211, 205);
    border-radius: 5px;
  }

  button.btn_submit {
    font-size: 16px;
    color: rgb(255, 209, 26);
    font-weight: 600;
    background: rgb(0, 89, 179);
    padding: 10px 30px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(208, 211, 205);
    border-radius: 5px;
    margin-bottom: 16%;
  }

  textarea {
    width: 100%;
    height: 30%;
  }

  .btn_submit_layout {
    display: flex;
    justify-content: center;
    margin: 5%;
  }

  .text_wdut {
    font-size: 20px;
    color: rgb(251, 194, 16);
    text-shadow: rgb(36, 71, 143) 1px 1px 1px;
    margin-bottom: 10px;
  }

  h1 {
    margin: unset !important;
    font-size: 40px;
    color: rgb(251, 194, 16);
    text-shadow: rgb(36, 71, 143) 2px 2px 2px;
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .content_mainlayout {
      padding-top: 80px;
    }
  }
`;
