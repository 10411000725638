import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const InputLineStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: ${(props) => (props.width ? props.width : '100%')};
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    input {
      width: ${(props) => (props.inputWidth ? props.inputWidth : '80%')};
      border: none;
      border-bottom: 1px solid rgb(131, 139, 139, 0.3);
      color: ${(props) =>
        props.color ? props.color : 'rgb(131, 139, 139,0.9)'};
      font-weight: 500;
      font-size: 14px;
    }
    .error_wrap {
      color: ${COLOR.RED_1};
      font-size: ${FONT.SIZE_1};
    }
  }
`;
