import styled from 'styled-components';
import { SCREEN, COLOR } from 'assets';

export const HomeContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .line_hr {
    margin-bottom: 0px;
  }

  .box_booking_container {
    margin: 0px 0px;
  }

  .bar_layout{
    margin: 0px 0px;
    /* width: 50%; */
  }

  img.gift_icon {
    max-width: 50px;
  }

  .banner_layout {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .line_hr {
    margin-bottom: 40px;
  }
  .booking_title_layout {
  }
  .booking_no_layout {
    // min-height: 635px;
  }
  .how_it_work_layout {
  }
  .vote_layout {
  }

  .content_web_layout {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .group_channel {
      padding: 15px;
      background: ${COLOR.GREY_4};
      width: 90%;
      margin-bottom: 40px;
      .group_1 {
        display: flex;
        .group_1_detail {
          width: 33.5%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 40px;
          .seeAll {
            color: ${COLOR.BLACK_4};
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-top: 10px;
            .icon {
              margin-left: 5px;
              width: 20px;
            }
          }
        }
      }
      .group_2 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .group_2_sub {
          display: flex;
          .group_2_detail {
            width: 11.06%;
          }
        }
      }
    }
    .line_1 {
      border-bottom: 1px solid ${COLOR.GREY_2};
      width: 95%;
      margin-bottom: 40px;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .group_text {
      font-size: 15px;
    }

    .bar_layout{
      overflow-x: scroll;
    }

    img.gift_icon {
      max-width: 11px;
    }
  }
`;
