import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { HomeContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import {
  Banner,
  BookingNo,
  Highlight,
  Explore,
  Upcoming,
  Wheels,
  Vote,
  HowItwork,
  LoadingPage,
  Categories,
  CategoriesBar,
  Fanshare,
  NewArrival,
} from 'widgets';
import { PopupCustom } from 'components';
import { Element } from 'react-scroll';

class HomeContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();

    // Tracking user event
    ReactGA.event({
      category: 'home',
      action: 'view',
      nonInteraction: true,
    });
    this.handleLoading();
  }

  handleLoading = () => {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 700);
  };

  render() {
    const { isLoading } = this.state;
    return (
      <HomeContainerStyled>
        {isLoading ? (
          <LoadingPage />
        ) : (
            <>
              <Element name="home" className="element">
                <div className="banner_layout">
                  <Banner />
                </div>
              </Element>
              <div className="line_hr" />
              <div className="bar_layout">
                <CategoriesBar propsHistory={this.props.history} />
              </div>
              <div className="booking_title_layout">
                <BookingNo propsHistory={this.props.history} />
              </div>
              <div className="booking_no_layout">
                <Highlight propsHistory={this.props.history} />
              </div>
              <div className="booking_no_layout">
                <NewArrival propsHistory={this.props.history} />
              </div>
              <div className="booking_no_layout">
                <Explore propsHistory={this.props.history} />
              </div>
              <div className="booking_no_layout">
                <Categories propsHistory={this.props.history} />
              </div>
              <div className="booking_no_layout">
                <Fanshare propsHistory={this.props.history} />
              </div>
              <div className="booking_no_layout">
                <Wheels propsHistory={this.props.history} />
              </div>
              <div className="booking_no_layout">
                <Upcoming propsHistory={this.props.history} />
              </div>
              <div className="line_1" />
              <Element name="how_it_work" className="element">
                <div className="how_it_work_layout">
                  <HowItwork />
                </div>
              </Element>
              <div className="line_1" />
              <Element name="vote" className="element">
                <div className="vote_layout">
                  <Vote />
                </div>
              </Element>
              <PopupCustom theme_standard />
            </>
          )}
      </HomeContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
