import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxProfileStyled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        .top_layer {
            background-image: ${({ src }) => `url(${src})`};
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 8px;
            padding: 5px;
            width: 100%;
            height: 250px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: ${FONT.SIZE_2};
            color: ${COLOR.WHITE_1};
            font-weight: 600;
        }
        .bottom_layer {
            .text1 {
                color: ${COLOR.BLACK_3};
                font-weight: 600;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis; 
            }
            .text2 {
                font-size: ${FONT.SIZE_2};
                font-weight: 600;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis; 
            }
        }
    }
`