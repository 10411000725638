import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { AdminOrderFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import {
  ButtonCustom,
  Input,
} from 'components';

class AdminOrderForm extends React.Component {
  state = {};

  render() {
    const { handleSubmit, handleClickCloseModal } = this.props;
    return (
      <AdminOrderFormStyled>
        <div className="group_form">
          <div className="line_input">
            <div className="left_layout">
              Brief
            </div>
            <div className="right_layout">
              <Field
                theme_standard_2
                name="brief"
                // placeholder="ANY CHAR"
                component={Input}
                validate={VALIDATE.REQUIRE}
              />
            </div>
          </div>
          <div className="line_input no_margin_bottom">
            <div className="left_layout">

            </div>
            <div className="right_layout text_right">
              <ButtonCustom
                theme_standard_2
                label='EDIT'
                onClick={handleSubmit}
              />
              <div className='button_layout'>
                <ButtonCustom
                  theme_standard_2
                  label='CLOSE'
                  onClick={handleClickCloseModal}
                />
              </div>
            </div>
          </div>
        </div>
      </AdminOrderFormStyled>
    );
  }
}

export default reduxForm({
  form: 'AdminOrderForm', // a unique identifier for this form
  enableReinitialize: true,
})(AdminOrderForm);
