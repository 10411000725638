import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { CreatorFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import { InputCustom, InputDropdownLine } from 'components';

class CreatorForm extends React.Component {
  state = {};

  render() {
    const { handleClickRegister, handleSubmit } = this.props;
    return (
      <CreatorFormStyled>
        <form onSubmit={handleSubmit(handleClickRegister)}>
          <div className="title">เข้าร่วม meWOW Creator</div>
          <div className="title1">
            โปรดกรอกข้อมูลของคุณแล้วเราจะติดต่อให้เร็วที่สุดค่า
          </div>
          <div className="title1">
            (ข้อมูลที่กรอกจะถูกเก็บเป็นความลับ
            และจะไม่มีการเผยแพร่ให้แก่บุคคลอื่นทั้งสิ้น)
          </div>
          <div className="form_layout">
            <div className="group_input">
              <div className="label_name">Your name</div>
              <Field
                theme_log_in
                name="name"
                placeHolder=""
                component={InputCustom}
                validate={VALIDATE.REQUIRE}
              />
            </div>
            <div className="group_input">
              <div className="label_name">Email</div>
              <Field
                theme_log_in
                name="email"
                placeHolder="you@example.com"
                component={InputCustom}
                validate={[VALIDATE.REQUIRE, VALIDATE.EMAIL]}
              />
            </div>
            <div className="title3">ข้อมูลในการติดต่อกลับ</div>
            <div className="flex_input">
              <div className="group_input">
                <div className="label_name">Phone number (เก็บเป็นความลับ)</div>
                <Field
                  theme_log_in
                  name="phone_no"
                  placeHolder="555-555-5555"
                  component={InputCustom}
                  validate={[VALIDATE.REQUIRE, VALIDATE.PHONE_NUMBER]}
                />
              </div>
              <div className="group_input distance">
                <div className="label_name">Line ID (เก็บเป็นความลับ)</div>
                <Field
                  theme_log_in
                  name="line_id"
                  placeHolder=""
                  component={InputCustom}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
            <div className="group_input">
              <div className="label_name">Social Media (ที่คุณป๊อปสุด)</div>
              <Field
                theme_standard
                name="socialmedia"
                component={InputDropdownLine}
                validate={VALIDATE.REQUIRE}
              >
                {socialOptions &&
                  socialOptions.map((e, i) => (
                    <option key={i} value={e.value}>
                      {e.label}
                    </option>
                  ))}
              </Field>
            </div>
            <div className="group_input">
              <div className="label_name">Social Media Account Id</div>
              <Field
                theme_log_in
                name="socialmedia_id"
                placeHolder="myid"
                component={InputCustom}
              />
            </div>
            <div className="group_input">
              <div className="label_name">
                Your Followers number (จำนวนคนติดตาม)
              </div>
              <Field
                theme_log_in
                name="follower_no"
                placeHolder="1M"
                component={InputCustom}
              />
            </div>
            <div className="group_emailbtn">
              <button className="button">SUBMIT</button>
            </div>
          </div>
        </form>
      </CreatorFormStyled>
    );
  }
}

const socialOptions = [
  {
    label: 'facebook',
    value: 'facebook',
  },
  {
    label: 'youtube',
    value: 'youtube',
  },
  {
    label: 'instagram',
    value: 'instagram',
  },
  {
    label: 'tiktok',
    value: 'tiktok',
  },
  {
    label: 'twitter',
    value: 'twitter',
  },
];

export default reduxForm({
  form: 'CreatorForm', // a unique identifier for this form
  enableReinitialize: true,
})(CreatorForm);
