import styled from 'styled-components';
import { SCREEN } from 'assets';
import // COLOR,
// FONT
'assets';

export const ProfileContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  max-width: 1100px;
  margin: auto;
  padding-top: 30px;
  .myorder_layout {
    border-radius: 5px;
    padding: 20px 0;
    .referral_count {
      font-size: 23px;
      color: #fbc210;
      text-shadow: 1px 1px 1px #24478f;
      text-align: center;
      padding: 20px;
    }
    .label {
      width: 104px;
    }
    .booking_container_layout {
      display: flex;
      overflow: auto;
      ::-webkit-scrollbar {
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #dcdcdc;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #708090;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: grey;
      }
    }
    .label_warning {
      padding-left: 10px;
    }
    .pic {
      height: 157px;
      border-radius: 8px;
      width: 100%;
      object-fit: cover;
    }
    .booking_item {
      flex-shrink: 0;
      width: 140px;
      height: 195px;
      border-radius: 4px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      background: #ffd11a;
      color: #24478f;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-0.0825rem);
      }
      .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .booking_video_item {
      flex-shrink: 0;
      margin-right: 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-0.0825rem);
      }
    }
    .booking_title {
      text-align: left;
      padding: 10px 0;
      color: #24478f;
      font-weight: 900;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .myorder_layout {
      .myorder {
        border-radius: 5px;
        border: 2px solid #24478f;
        padding: 20px;
        margin-top: 20px;
        max-width: 87%;
      }
    }
  }
`;
