import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const InputDropdownLineStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        select{
            border: none;
            border-bottom: 1px solid rgb(131, 139, 139,0.3);
            color: rgb(131, 139, 139,0.6)
            font-weight: 500;
            font-size: 14px;
            height: 32px;
        }
        .error_wrap {
            color: ${COLOR.RED_1};
            font-size: ${FONT.SIZE_1};
        }
    }
`;
