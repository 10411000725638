import React from 'react';
import { ReactTablePagiDBStyled } from './styled';
import { useTable, usePagination } from 'react-table';
import { PaginationCustom } from 'components';

export const ReactTablePagiDB = (props) => {
  const { columns, data = [], currentPageData, _handlePageClick, totalPages } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <ReactTablePagiDBStyled>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps({ style: { ...column.style } })}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      {data && (currentPageData || currentPageData === 0) && (
        <div style={{ marginTop: '10px' }}>
          <PaginationCustom
            theme_standard_pagination
            data={data}
            totalPages={totalPages}
            currentPageData={currentPageData}
            _handlePageClick={_handlePageClick}
          />
        </div>
      )}
    </ReactTablePagiDBStyled>
  );
};
