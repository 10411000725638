import React from 'react';
import { connect } from 'react-redux';
import { OrderContainerStyled } from './styled';
import { adminService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { ReactTablePagiDB } from 'components';
// import { ROUTE_PATH } from 'helpers';
import { Helmet } from 'react-helmet';
import { AdminOrderForm } from 'forms';
import { toast } from 'react-toastify';
import { Modal } from 'components';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class OrderContainer extends React.Component {
  state = { data: [], activeTab: 'PENDING', isFirstLoad: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (offset = 0) => {
    let res = await adminService.GET_ORDER_LIST('PENDING', offset);
    if (res && res.success) {
      const { current_page } = res.page;
      this.setState({
        data: res.data,
        currentPageData: current_page - 1,
        pageList: res.page,
        totalPages: res.page.last_page
      });
    }
  };

  handdleClickTab = async (e, offset = 0) => {
    let res = await adminService.GET_ORDER_LIST(e, offset);
    if (res && res.success) {
      const { current_page } = res.page;
      this.setState({
        data: res.data,
        currentPageData: current_page - 1,
        pageList: res.page,
        totalPages: res.page.last_page
      });
    }
    this.setState({
      activeTab: e,
      isFirstLoad: false,
    });
  };

  handdleClickDetail = (e) => {
    this.setState({
      isModal: true,
      initialValues: {
        ...e,
        user_age: this.calculateAge(e.gifter.user.birthday),
      },
    });
  };

  handdleClickCancle = (e) => {
    confirmAlert({
      title: 'Confirm to cancle',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.cancleOrder(e.id)
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  cancleOrder = async (e) => {
    const { activeTab } = this.state;
    let params = {
      status: "REJECTED",
    };

    let res = await adminService.PATCH_ORDER_UPDATE(params, e);
    if (res && res.success) {
      toast.success('ยกเลิกสำเร็จ');
      this.handdleClickTab(activeTab);
      this.setState({
        isModal: false,
      });
    } else {
      toast.error('ยกเลิกไม่สำเร็จ');
    }
  }

  handdleSummit = async () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;

    const { activeTab } = this.state;

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let params = {
        brief: values.brief,
      };

      let res = await adminService.PATCH_ORDER_UPDATE(params, values.id);
      if (res && res.success) {
        toast.success('บันทึกสำเร็จ');
        this.handdleClickTab(activeTab);
        this.setState({
          isModal: false,
        });
      } else {
        toast.error('บันทึกไม่สำเร็จ');
      }
    }
  };

  handdChangeCheckBox = async (e, id) => {
    let { activeTab } = this.state;
    let params = {
      is_private: e,
    };

    let res = await adminService.PATCH_ORDER_UPDATE(params, id);
    if (res && res.success) {
      toast.success('บันทึกสำเร็จ');
      this.handdleClickTab(activeTab);
    } else {
      toast.error('บันทึกไม่สำเร็จ');
    }
  };

  calculateAge = (dateString) => {
    if (dateString) {
      let today = new Date();
      let birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
    });
  };

  handlePageClick = (e) => {
    const { pageList, isFirstLoad, activeTab } = this.state;

    if (isFirstLoad) {
      this.fetchData(e.selected * pageList.per_page);
    } else {
      this.handdleClickTab(activeTab, e.selected * pageList.per_page);
    }
  };

  render() {
    const {
      data,
      activeTab,
      initialValues,
      isModal,
      currentPageData,
      totalPages,
    } = this.state;
    return (
      <OrderContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="main_layout">
          <div className="tab_layout">
            <div className="title_layout">Order System</div>
            <div className="group_tab">
              <button
                type="button"
                className={`button_tab_1 ${activeTab === 'ALL' && 'active'}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handdleClickTab('ALL');
                }}
              >
                ทั้งหมด
              </button>
              <button
                type="button"
                className={`button_tab_2 ${activeTab === 'PENDING' && 'active'
                  }`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handdleClickTab('PENDING');
                }}
              >
                ดำเนินการ
              </button>
              <button
                type="button"
                className={`button_tab_3 ${activeTab === 'COMPLETED' && 'active'
                  }`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handdleClickTab('COMPLETED');
                }}
              >
                เสร็จสิ้นแล้ว
              </button>
              <button
                type="button"
                className={`button_tab_4 ${activeTab === 'NEW' && 'active'}`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handdleClickTab('NEW');
                }}
              >
                ยังไม่ชำระ
              </button>
            </div>
          </div>
          <ReactTablePagiDB
            columns={columns({
              handdleClickDetail: this.handdleClickDetail,
              calculateAge: this.calculateAge,
              handdChangeCheckBox: this.handdChangeCheckBox,
              handdleClickCancle: this.handdleClickCancle,
            })}
            data={data}
            currentPageData={currentPageData}
            totalPages={totalPages}
            _handlePageClick={this.handlePageClick}
          />
          <Modal
            theme_modal_border
            isShow={isModal}
            handleClickCloseModal={this.handleClickCloseModal}
          >
            <AdminOrderForm
              handleSubmit={this.handdleSummit}
              initialValues={initialValues}
              handleClickCloseModal={this.handleClickCloseModal}
            />
          </Modal>
        </div>
      </OrderContainerStyled>
    );
  }
}

const columns = ({ handdleClickDetail, calculateAge, handdChangeCheckBox, handdleClickCancle }) => [
  {
    Header: 'Order#',
    accessor: 'id',
    // style: { width: '350px' },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (e) =>
      <div className="div_center">
        {
          e.value
        }
        {
          (e.value === 'REQUESTED' || e.value === 'PENDING') &&
          <button onClick={() => handdleClickCancle(e.row.original)}>
            ยกเลิก
          </button>
        }
      </div >
    ,
  },
  {
    Header: 'Creator',
    accessor: 'creator.displayname',
  },
  {
    Header: 'Deadline',
    accessor: 'deadline_date',
  },
  {
    Header: 'Service',
    accessor: '',
  },
  {
    Header: 'Price',
    accessor: 'invoice.rate_coins',
    Cell: (e) => <div className="div_right">{e.value}</div>,
  },
  {
    Header: 'Objective',
    accessor: 'category.name_th',
  },
  {
    Header: 'Payment',
    accessor: 'invoice.receipt.method',
  },
  {
    Header: 'Gifter',
    accessor: 'gifter.displayname',
    Cell: (e) =>
      <div className="show_gifter">
        <div>{e.value}</div>
        <div>
          {`${e.row.original.gifter.user.birthday ? calculateAge(e.row.original.gifter.user.birthday) : ''} / ${e.row.original.contact_phone ? e.row.original.contact_phone : ''}`}
        </div>
      </div>,
  },
  // {
  //   Header: 'User Age',
  //   accessor: 'gifter.user.birthday',
  //   Cell: (e) => <div className="div_right">{calculateAge(e.value)}</div>,
  // },
  // {
  //   Header: 'User No.',
  //   accessor: 'order.contact_phone',
  // },
  {
    Header: 'Brief',
    accessor: 'brief',
    Cell: (e) => (
      <div
        style={{ cursor: 'pointer', fontWeight: 'bold', color: '#0059b3' }}
        onClick={() => handdleClickDetail(e.row.original)}
      >
        {e.value}
      </div>
    ),
  },
  {
    Header: 'Private',
    // accessor: 'is_private',
    accessor: 'is_private',
    style: { width: '10px' },
    Cell: (e) => (
      <div className="div_center">
        <input type="checkbox" onChange={(b) => handdChangeCheckBox(b.target.checked, e.row.original.id)} defaultChecked={e.row.original.is_private ? true : false} />
        {/* <button
          className="button_edit"
          onClick={() => handdChangeCheckBox(true, e.row.original.id)}
        >
          Private
        </button> */}
      </div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AdminOrderForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
