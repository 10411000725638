import React from 'react';
import { connect } from 'react-redux';
import { WheelsContainerStyled } from './styled';
import { adminService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ReactTableExpand, ReactTable, ButtonCustom } from 'components'
import { ROUTE_PATH } from 'helpers';
import { Helmet } from "react-helmet";

class WheelsContainer extends React.Component {
  state = { dataWheels: [] };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_WHEELS_LIST();
    // console.log("ressssssssssssss ", res.data)
    if (res && res.success) {
      this.setState({
        dataWheels: res.data,
      });
    }
  };

  handdleClickAddGenre = () => {
    this.props.history.push(ROUTE_PATH.A_WHEELS + '/create');
  };

  handdleClickEditWheels = (e) => {
    this.props.history.push(ROUTE_PATH.A_WHEELS + '/' + e);
  };

  handdleClickCreatorDetail = (e) => {
    this.props.history.push(ROUTE_PATH.A_CREATOR + '/' + e);
  };

  handleClickWheels = async (e) => {
    let resWheels = await adminService.GET_CREATOR_BY_WHEELS(e);
    // console.log("ressssssssssssss  geee", resWheels.data)
    if (resWheels && resWheels.success) {
      this.setState({
        dataListWheels: resWheels.data,
      });
    }
  };

  render() {
    const { dataWheels, dataListWheels } = this.state;
    return (
      <WheelsContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="main_layout">
          <div className="tab_layout">
            <div className="title_layout">
              Wheels System
            </div>
            <div className="button_layer" >
              <ButtonCustom
                theme_standard
                label="New Wheels"
                onClick={this.handdleClickAddGenre}
              />
            </div>
          </div>
          <ReactTableExpand
            columns={columns({ handleClickWheels: this.handleClickWheels, handdleClickEditWheels: this.handdleClickEditWheels })}
            data={dataWheels}
            renderRowSubComponent={({ row }) => renderRowSubComponent({ row, handdleClickCreatorDetail: this.handdleClickCreatorDetail, dataListWheels })}
          />
        </div>
      </WheelsContainerStyled>
    );
  }
}

const columns = ({ handleClickWheels, handdleClickEditWheels }) => [
  {
    // Make an expander cell
    Header: () => null, // No header
    id: 'expander', // It needs an ID
    Cell: ({ row, rows, toggleRowExpanded }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <div onClick={() => handleClickWheels(row.original.slug)}>
        <span
          {...row.getToggleRowExpandedProps({
            onClick: () => {
              const expandedRow = rows.find(row => row.isExpanded);
              
              if (expandedRow) {
                const isSubItemOfRow = Boolean(
                  expandedRow && row.id.split(".")[0] === expandedRow.id
                );

                if (isSubItemOfRow) {
                  const expandedSubItem = expandedRow.subRows.find(
                    subRow => subRow.isExpanded
                  );

                  if (expandedSubItem) {
                    const isClickedOnExpandedSubItem =
                      expandedSubItem.id === row.id;
                    if (!isClickedOnExpandedSubItem) {
                      toggleRowExpanded(expandedSubItem.id, false);
                    }
                  }
                } else {
                  toggleRowExpanded(expandedRow.id, false);
                }
              }
              row.toggleRowExpanded();
            }
          })}
        >
          {row.isExpanded ? '👇' : '👉'}
        </span>
      </div>
    ),
  },
  {
    Header: 'Order No',
    accessor: 'order',
    style: { width: '100px' },
  },
  {
    Header: 'Wheels Name',
    accessor: 'title',
  },
  {
    Header: 'No. of Creators',
    accessor: 'creators_count',
    style: { width: '150px' },
  },
  {
    Header: 'Detail',
    accessor: 'slug',
    Cell: (e) => (
      <div style={{ textAlign: 'center' }}>
        <button onClick={() => handdleClickEditWheels(e.value)}>detail</button>
      </div>
    ),
  },
];

const columns2 = ({ handdleClickCreatorDetail }) => [
  {
    Header: 'Creator Id',
    accessor: 'id',
  },
  {
    Header: 'Display Name',
    accessor: 'displayname',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Detail',
    Cell: ({ row: { index, original } }) => (
      <div style={{ textAlign: 'center' }}>
        <button onClick={() => handdleClickCreatorDetail(original.id)}>detail</button>
      </div>
    ),
  },
];

const renderRowSubComponent = ({ row, handdleClickCreatorDetail, dataListWheels }) => (
  <pre
    style={{
      fontSize: '10px',
    }}
  >
    <ReactTable
      notUsePagi
      columns={columns2({ handdleClickCreatorDetail: handdleClickCreatorDetail })}
      // columns={columns2({})}
      data={dataListWheels}
    />
  </pre>
);

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WheelsContainer);
