import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const ChartTableStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    border-top: 1px solid ${COLOR.GREY_2};
    border-left: 1px solid ${COLOR.GREY_2};
    border-right: 1px solid ${COLOR.GREY_2};
    width: 100%;
    .body_layout {
      display: flex;
      border-bottom: 1px solid ${COLOR.GREY_2};
      .left_layout {
        display: flex;
        padding: 20px 10px;
        width: 100%;
        flex-wrap: wrap;
        position: relative;
        .no_layout {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          width: 50px;
          justify-content: center;
          align-items: center;
          font-size: ${FONT.SIZE_10};
          font-weight: 600;
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
            margin-top: 5px;
          }
        }
        .name_layout {
          padding: 5px 5px;
          width: calc(100% - 280px);
          flex-shrink: 0;
          .name_layout_1 {
            font-weight: 600;
            color: ${COLOR.BLACK_3};
          }
          .name_layout_2 {
            margin-bottom: 5px;
            font-weight: 600;
          }
          .name_layout_3 {
            font-size: ${FONT.SIZE_2};
            font-weight: 600;
          }
        }
        .vote_layout {
          padding: 0px 5px;
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          width: 200px;
          justify-content: center;
          align-items: center;
          .vote_value {
            color: ${COLOR.BLUE_2};
            font-weight: 600;
            margin-bottom: 5px;
          }
          .button {
            cursor: pointer;
            font-size: ${FONT.SIZE_5};
            font-family: unicaOne_regular;
            width: 70%;
            border-radius: 20px;
            color: ${COLOR.YELLOW_2};
            padding: 5px 10px;
            border: 1px solid ${COLOR.GREY_5};
            font-weight: 600;
            background: ${COLOR.BLUE_5};
          }
        }
      }
      .right_layout {
        display: flex;
        flex-shrink: 0;
        width: 100px;
        border-left: 1px solid ${COLOR.GREY_2};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard {
      .body_layout {
        .left_layout {
          .name_layout {
            width: calc(100% - 75px);
          }
          .vote_layout {
            width: 100%;
          }
        }
        .right_layout {
          width: 170px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      .body_layout {
        .left_layout {
          padding-bottom: 10px;
          .no_layout {
            flex-direction: row;
            align-items: center;
            width: fit-content;
            position: absolute;
            top: 0;
            right: 0;
            img {
              margin-top: 0;
              margin-left: 5px;
            }
          }
          .name_layout {
            width: 100%;
          }
          .vote_layout {
            .button {
              width: 90%;
            }
          }
        }
        .right_layout {
          width: 140px;
        }
      }
    }
  }
`;
