import React, { useState, useEffect, useCallback, useRef } from 'react';
import { NavbarWebStyled } from './styled';
import { useSelector, useDispatch } from 'react-redux';
import { iconImages } from 'assets';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { InputSearchName } from 'components';

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

function usePageViews(callback) {
  let location = useLocation();
  useEffect(() => {
    callback && callback(location.pathname);
  }, [location, callback]);
}

export const NavbarWeb = ({ theme_standard, ...props }) => {
  const wrapperRef = useRef(null);
  const auth = useSelector((state) => state.auth);
  const [isMbMenuOpen, setIsMbMenuOpen] = useState();
  const [isWebMenuOpen, setIsWebMenuOpen] = useState();
  const [isHomePage, setIsHomePage] = useState();

  const dispatch = useDispatch();

  useOutsideAlerter(wrapperRef, () => {
    handleSetActive();
  });

  usePageViews((e) => {
    setMenuByRoutePath(e);
  });

  const setMenuByRoutePath = (e) => {
    switch (e) {
      case '/':
        setIsHomePage(true);
        break;
      default:
        setIsHomePage(false);
        break;
    }
  };

  const handleClickLogout = useCallback(async () => {
    let res = await userService.GET_LOGOUT();
    if (res && res.success) {
      localStorage.removeItem('wowToken');
      sessionStorage.removeItem('wowLogin');
      dispatch(setReduxUserAuth(false));
    }
    props.propsHistory.push('/');
    dispatch(setReduxUserAuth(false));
  }, [dispatch, props.propsHistory]);

  useEffect(() => {
    const checkTokenExpire = async () => {
      let wowToken = localStorage.getItem('wowToken');
      let wowLogin = sessionStorage.getItem('wowLogin');
      if (wowToken && !wowLogin) {
        let params = {
          access_token: localStorage.getItem('wowToken'),
        };
        const res = await userService.POST_CHECK_TOKEN_EXPIRE(params);
        if (res && res.success) {
          sessionStorage.setItem('wowLogin', true);
        } else {
          handleClickLogout();
        }
      } else {
        // if (!auth) {
        //   handleClickLogout();
        // }
      }
    };
    checkTokenExpire();
  }, [dispatch, handleClickLogout, auth]);

  const handleClickNavbar = (key, data) => {
    switch (key) {
      case 'login':
        props.propsHistory.push(ROUTE_PATH.LOGIN);
        break;
      case 'logout':
        handleClickLogout();
        break;
      case 'home':
        props.propsHistory.push('/');
        // window.location.reload();
        break;
      case 'profile':
        props.propsHistory.push(ROUTE_PATH.PROFILE);
        break;
      case 'signup':
        props.propsHistory.push(ROUTE_PATH.SIGN_UP);
        break;

      case 'invite':
        props.propsHistory.push(ROUTE_PATH.REFERAL);
        break;

      case 'following':
        props.propsHistory.push(ROUTE_PATH.FOLLOWING);
        break;
      case 'cms':
        props.propsHistory.push(ROUTE_PATH.WOW_MANAGE);
        break;
      default:
        break;
    }
    handleSetActive();
  };

  const handleClickHamburger = () => {
    setIsMbMenuOpen(!isMbMenuOpen);
  };

  const handleSetActive = () => {
    setIsMbMenuOpen(false);
    setIsWebMenuOpen(false);
  };

  const handleClickHamburgerWeb = () => {
    setIsWebMenuOpen(!isWebMenuOpen);
  };

  const handleSetActiveWeb = () => {
    setIsWebMenuOpen(false);
  };

  const handleEnter = (e) => {
    props.propsHistory.push(`${ROUTE_PATH.CREATOR_SEARCH_DETAIL}?keyword=${e}`);
  };

  const handleClickSearchMobil = () => {
    props.propsHistory.push(`${ROUTE_PATH.CREATOR_SEARCH_DETAIL}`);
  };

  return (
    <NavbarWebStyled>
      <div className="web_menu_layout">
        <Link
          className="header_item"
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-131}
          duration={500}
          onClick={() => handleClickNavbar('home')}
        >
          HOME
        </Link>
        {isHomePage ? (
          <>
            <Link
              className="header_item"
              activeClass="active"
              to="how_it_work"
              spy={true}
              smooth={true}
              offset={-131}
              duration={500}
              onClick={() => handleClickNavbar('home')}
            >
              HOW IT WORK
            </Link>
            <Link
              className="header_item"
              activeClass="active"
              to="vote"
              spy={true}
              smooth={true}
              offset={-131}
              duration={500}
            >
              VOTE
            </Link>
          </>
        ) : (
            <></>
          )}
        <Link
          className="header_item"
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-131}
          duration={500}
          onClick={() => handleClickNavbar('home')}
        >
          <div className="logo_wrap">
            <img className="img_header" src={iconImages['logo.png']} alt="" />
          </div>
        </Link>
        <div className="header_item"></div>
        {!auth ? (
          <>
            <div
              className="header_item"
              onClick={() => handleClickNavbar('signup')}
            >
              SIGNUP
            </div>
            <div className="header_item">
              <div onClick={() => handleClickNavbar('login')}>LOGIN</div>
              <div className="search_layout_2">
                <InputSearchName
                  theme_standard
                  placeHolder="Search"
                  handleEnter={handleEnter}
                />
              </div>
            </div>
          </>
        ) : (
            <div className="group_avatar">
              <div className="search_layout">
                <InputSearchName
                  theme_standard
                  placeHolder="Search"
                  handleEnter={handleEnter}
                />
              </div>
              <div className="avatar" onClick={() => handleClickHamburgerWeb()}>
                <img
                  className="person"
                  src={auth.avatar ? auth.avatar : iconImages['person.png']}
                  alt=""
                />
              </div>
            </div>
          )}
      </div>
      <div className="mobile_menu_layout">
        <div className="logo_wrap" onClick={() => handleClickNavbar('home')}>
          <img className="img_header" src={iconImages['logo.png']} alt="" />
        </div>
        <div className="mb_right_layout">
          {!auth ? (
            <>
              <div className="group_avatar">
                <div className="avatar_search" onClick={handleClickSearchMobil}>
                  <img
                    className="search_icon"
                    src={iconImages['search_icon.png']}
                    alt=""
                  />
                </div>
                <div className="item" onClick={() => handleClickNavbar('signup')}>
                  SIGNUP
              </div>
                <div> | </div>
                <div className="item" onClick={() => handleClickNavbar('login')}>
                  LOGIN
              </div>
              </div>

            </>
          ) : (
              <div className="group_avatar">
                <div className="avatar_search" onClick={handleClickSearchMobil}>
                  <img
                    className="search_icon"
                    src={iconImages['search_icon.png']}
                    alt=""
                  />
                </div>
                <div className="avatar" onClick={() => handleClickHamburger()}>
                  <img
                    className="person"
                    src={auth.avatar ? auth.avatar : iconImages['person.png']}
                    alt=""
                  />
                </div>
              </div>
            )}
        </div>
      </div>
      {isMbMenuOpen && (
        <div className="sideber_mobile" ref={wrapperRef}>
          <Link
            className="header_mb_item"
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-131}
            duration={500}
            onSetInactive={handleSetActive}
            onClick={() => handleClickNavbar('home')}
          >
            HOME
          </Link>
          {isHomePage && (
            <>
              <Link
                className="header_mb_item"
                activeClass="active"
                to="how_it_work"
                spy={true}
                smooth={true}
                offset={-131}
                duration={500}
                onSetInactive={handleSetActive}
              >
                HOW IT WORK
              </Link>
              <Link
                className="header_mb_item"
                activeClass="active"
                to="vote"
                spy={true}
                smooth={true}
                offset={-131}
                duration={500}
                // onClick={() => handleClickNavbar('home')}
                onSetInactive={handleSetActive}
              >
                VOTE
              </Link>
            </>
          )}
          <Link
            className="header_mb_item"
            activeClass="active"
            to="invite_friends"
            spy={true}
            smooth={true}
            offset={-131}
            duration={500}
            onClick={() => handleClickNavbar('invite')}
            onSetInactive={handleSetActive}
          >
            <div className="invite_friends">ชวนเพื่อนรับส่วนลด 5%</div>
          </Link>
          <Link
            className="header_mb_item"
            activeClass="active"
            to="following_list"
            spy={true}
            smooth={true}
            offset={-131}
            duration={500}
            onClick={() => handleClickNavbar('following')}
            onSetInactive={handleSetActive}
          >
            FOLLOWING
          </Link>
          {auth && (
            <>
              <div
                className="header_mb_item"
                onClick={() => handleClickNavbar('profile')}
              >
                Your Video
              </div>
              {auth.admin && (
                  <div
                  className="header_mb_item"
                  onClick={() => window.location.href = process.env.REACT_APP_MANAGE_ENDPOINT}
                >
                  Manage meWOW
                </div>
              )}
              <div
                className="header_mb_item"
                onClick={() => handleClickNavbar('logout')}
              >
                LOGOUT
              </div>
            </>
          )}
        </div>
      )}
      {isWebMenuOpen && (
        <div className="sideber_web" ref={wrapperRef}>
          <Link
            className="header_mb_item"
            activeClass="active"
            to="invite_friends"
            spy={true}
            smooth={true}
            offset={-131}
            duration={500}
            onClick={() => handleClickNavbar('invite')}
            onSetInactive={handleSetActiveWeb}
          >
            <div className="invite_friends">ชวนเพื่อนรับส่วนลด 5%</div>
          </Link>
          <Link
            className="header_mb_item"
            activeClass="active"
            to="following_list"
            spy={true}
            smooth={true}
            offset={-131}
            duration={500}
            onClick={() => handleClickNavbar('following')}
            onSetInactive={handleSetActiveWeb}
          >
            FOLLOWING
          </Link>
          {auth && (
            <>
              <div
                className="header_mb_item"
                onClick={() => handleClickNavbar('profile')}
              >
                Your Video
              </div>
              {auth.admin && (
                  <div
                    className="header_mb_item"
                    onClick={() => window.location.href = process.env.REACT_APP_MANAGE_ENDPOINT}
                  >
                    Manage meWOW
                  </div>
              )}
              <div
                className="header_mb_item"
                onClick={() => handleClickNavbar('logout')}
              >
                LOGOUT
              </div>
            </>
          )}
        </div>
      )}
    </NavbarWebStyled>
  );
};
