import styled from 'styled-components';

export const ModalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_modal_standard {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.5);
    display: ${({ _isShow }) => (_isShow ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 91;
    }
  }
  
  .theme_modal_border {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.5);
    display: ${({ _isShow }) => (_isShow ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 91;
      background: #f3f3f3;
      padding: 39px;
      border: 1px solid;
      border-radius: 10px;
    }
  }
`;
