import React from 'react';
import cx from 'classnames';
import { InputTextareaNormalStyled } from './styled';

export const InputTextareaNormal = ({
  theme_standard,
  input,
  meta: { touched, error },
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <InputTextareaNormalStyled>
      <div className={customClass}>
        <textarea
          {...input}
          onChange={(e) => {
            input.onChange(e);
          }}
        />
        {touched && error && <div className="error">{error}</div>}
      </div>
    </InputTextareaNormalStyled>
  );
};
