import React, { useState, useEffect, useRef } from 'react';
import { PopupCustomStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';
import { toast } from 'react-toastify';

export const PopupCustom = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const textArea = useRef(null);
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    const isShowPromosion = sessionStorage.getItem('promotion');
    if (!isShowPromosion) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, []);

  const handleClickCopyCode = () => {
    const el = textArea;
    el.current.select();
    document.execCommand('copy');
    toast.success('copy เรียบร้อย');
    el.current.blur();
  };

  const handleClose = () => {
    sessionStorage.setItem('promotion', false);
    setIsShow(false);
  };

  return (
    <PopupCustomStyled isShow={isShow}>
      <div className={customClass}>
        <div className="close_layer" onClick={handleClose}>
          X
        </div>
        <div className="top_box">
          <div>
            แจกส่วนลด สำหรับสมาชิกใหม่
            {/* <span className="label_1">฿</span>
            <span className="label_2">500</span> */}
          </div>
          <div className="text_1">
            ลดทันที <span className="label_4">10%</span>
            {/* <span className="label_4">300</span> ทันที */}
          </div>
          <div className="text_2">
            ใช้โค้ด :
            <input
              ref={textArea}
              name="referral"
              className="label_5"
              defaultValue="FIRSTWOW"
            />
            <div className="label_6" onClick={handleClickCopyCode}>
              copy
            </div>
          </div>
        </div>
        <div className="img_layer">
          <div className="figture">
            <img
              className="img_footer"
              src={iconImages['logo_web.png']}
              alt=""
            />
          </div>
          <div className="text_3">
            <span className="label_7">สั่งซื้อที่นี้</span>
            <span className="label_8"> WWW.meWOW.app</span>
            <div>
              <div className="label_7"> *จำกัด 100 สิทธิ์โค๊ดส่วนลด</div>
              {/* <div className="label_7"> *หมดเขต 8 ม.ค. 2021</div> */}
            </div>
          </div>
        </div>
      </div>
    </PopupCustomStyled>
  );
};
