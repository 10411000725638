import React, { useState } from 'react';
import { InputSearchNameStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';

export const InputSearchName = ({
  theme_standard,
  theme_mobile_standard,
  placeHolder,
  handleEnter,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_mobile_standard: theme_mobile_standard,
  });

  const [value, setValue] = useState('');

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleEnter(value);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <InputSearchNameStyled>
      <div className={customClass}>
        <input
          className="input"
          value={value}
          type="text"
          placeholder={placeHolder}
          onChange={handleChange}
          onKeyDown={_handleKeyDown}
        />
        <img className="icon" src={iconImages['search_icon.png']} alt="" />
      </div>
    </InputSearchNameStyled>
  );
};
