import styled from 'styled-components';
import { COLOR } from 'assets';

export const RecommendHappyNextStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    display: inline-block;
    border-radius: 16px;
    padding: 15px;
    border: 2px solid #0059b3;
    background-color: rgb(240, 248, 255, 0.1);
    .row {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        flex-shrink: 0;
        width: 30px;
        height: auto;
        margin-right: 15px;
        margin-top: 5px;
      }
      .text {
        text-align: left;
        .text_hightlight {
          color: ${COLOR.GREEN_6};
        }
      }
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .text_1 {
      font-weight: 600;
      font-size: 16px;
      margin: 0px 5px 10px 5px;
    }
  }
`;
