import styled from 'styled-components';
import // COLOR,
// FONT
'assets';

export const RefundFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .status_refund {
    font-weight: 400;
    font-size: 16px;
    margin: 5px 0px 20px 0px;
  }
  .status_refund_1 {
    font-weight: 400;
    font-size: 16px;
    margin: -20px 0px 20px 0px;
  }
  .title_form {
    margin: 5px 0;
    font-weight: 600;
    font-size: 24px;
    color: #fbc210;
  }
  .title_form_1 {
    margin: 5px 0;
    font-weight: 400;
    font-size: 16px;
  }
  .position_form {
    .pd_input {
      padding: 7px 0;
      display: flex;
      justify-content: space-between;
      .label {
        margin-bottom: 5px;
      }
      .input_text {
        width: 70%;
      }
    }
    .btn_submit_wrap {
      padding: 10px 0;
    }
  }
  .footer {
    margin-top: 20px;
  }
`;
