import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { AgencyFormCreatorStyled } from "./styled";
import { adminService } from "apiService";
import { InputSuggestCreator, ButtonCustom, BoxShow } from "components";
import { connect } from "react-redux";
import { toast } from 'react-toastify';

const selector = formValueSelector("AgencyFormCreator");

class AgencyFormCreator extends React.Component {
  state = { creatorID: null };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { agencyID } = this.props
    let res = await adminService.GET_AGENCY_LIST_AGENT_BY_CREATOR_ID(agencyID);

    if (res && res.success) {
      this.setState({
        dataCreator: res.data
      })
    }
  };

  handleSelectedCreator = (e) => {
    this.setState({
      creatorID: e ? e.id : null
    })
  }

  handdleSave = async () => {
    const { creatorID } = this.state
    const { agencyID } = this.props
    let params = {
      id: creatorID
    }

    if (creatorID) {
      let res = await adminService.POST_AGENCY_ADD_CREATOR(agencyID, params);
      if (res && res.success) {
        toast.success("บันทึกสำเร็จ");
        this.fetchData();
      } else {
        toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
      }
    } else {
      toast.error('กรุณาเลือกข้อมูล Creator ก่อนบันทึก');
    }
  }

  handdleRemove = async (e) => {
    const { agencyID } = this.props

    let res = await adminService.DELETE_AGENCY_DELETE_CREATOR(agencyID, e);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.fetchData();
    } else {
      toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
    }
  }

  render() {
    const { dataCreator } = this.state
    return (
      <AgencyFormCreatorStyled>
        <fieldset>
          <legend>Creator</legend>
          {
            dataCreator &&
            <div className="group_show">
              <BoxShow
                theme_standard
                data={dataCreator}
                handdleClick={this.handdleRemove}
              />
            </div>
          }
          <div className="box_label">
            <Field
              theme_standard
              name="user_id"
              component={InputSuggestCreator}
              onChangeValue={this.handleSelectedCreator}
            />
          </div>
          <div className="button_layer">
            <ButtonCustom theme_standard label="ADD" onClick={this.handdleSave} />
          </div>
        </fieldset>
      </AgencyFormCreatorStyled>
    );
  }
}

const mapStateToProps = (state) => {
  const slug = selector(state, "slug");
  return {
    slug,
  };
};

const mapDispatchToProps = {
  change,
};

export default reduxForm({
  form: "AgencyFormCreator", // a unique identifier for this form
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(AgencyFormCreator));
