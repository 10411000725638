import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { PaymentProcessContainerStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { WaitingPayment } from 'widgets';

class PaymentProcessContainer extends React.Component {
  state = {
    textStatus: 'กรุณารอซักครู่',
    textDetail: '... ระบบกำลังตรวจสอบสถานะการจ่ายเงิน',
  };

  componentDidMount() {
    this.fetchData();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  fetchData = async () => {
    let res = await userService.GET_PAYMENT_DETAIL_BY_INVOICE(
      this.props.match.params.id
    );
    if (res && res.success) {
      if (res.data && res.data === 'PAID') {
        this.setState({
          textStatus: 'จ่ายเงินสำเร็จ',
          textDetail:
            'ขอบคุณที่ใช้บริการค่ะ ระบบจะทำการส่งต่อไปขั้นตอนต่อไปค่ะ',
        });

        // Tracking user event
        ReactGA.event({
          category: 'payment',
          action: 'payment_success',
        });
      } else {
        this.setState({
          textStatus: 'จ่ายเงินไม่สำเร็จ',
          textDetail: 'กรุณาชำระเงินใหม่ หรือติดต่อเจ้าหน้าที่ค่ะ',
        });

        // Tracking user event
        ReactGA.event({
          category: 'payment',
          action: 'payment_fail',
        });
      }
    }
    setTimeout(() => {
      this.props.history.push(ROUTE_PATH.PROFILE);
    }, 5000);
  };

  render() {
    const { textStatus, textDetail } = this.state;
    return (
      <PaymentProcessContainerStyled>
        <WaitingPayment textStatus={textStatus} textDetail={textDetail} />
      </PaymentProcessContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentProcessContainer);
