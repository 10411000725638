import styled from 'styled-components';
import { COLOR, SCREEN } from 'assets';

export const PaymentPopupStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .container_select_payment {
    background: ${COLOR.WHITE_1};
    border: 0.5px solid #d0d6e2;
    border-radius: 15px;
    padding: 3%;
    width: 94%;
    .h1_text {
      text-align: center;
    }
    .btn_layout {
      display: flex;
      .payment_item_wrap1 {
        text-align: center;
        border: 1px solid;
        padding: 10px;
        margin: 2px;
        cursor: pointer;
        border-radius: 13px;
        width: 180px;
        box-shadow: 2px 1px 3px 0px rgba(191, 191, 191, 1);
      }
      .payment_item_wrap2 {
        text-align: center;
        border: 1px solid;
        padding: 10px;
        margin: 2px;
        cursor: pointer;
        border-radius: 13px;
        width: 180px;
        box-shadow: 2px 1px 3px 0px rgba(191, 191, 191, 1);
        .img_show {
          margin-bottom: 57px;
        }
      }
      .payment_item_wrap3 {
        text-align: center;
        border: 1px solid;
        padding: 10px;
        margin: 2px;
        cursor: pointer;
        border-radius: 13px;
        width: 180px;
        box-shadow: 2px 1px 3px 0px rgba(191, 191, 191, 1);
      }
      .payment_item_wrap4 {
        text-align: center;
        border: 1px solid;
        padding: 10px;
        margin: 2px;
        cursor: pointer;
        border-radius: 13px;
        width: 180px;
        box-shadow: 2px 1px 3px 0px rgba(191, 191, 191, 1);
      }

      img.icon_img {
        margin-bottom: 10px;
      }
    }
  }

  .pay_btn {
    cursor: pointer;
    font-size: 16px;
    background: #ffd11a;
    color: #0059b3;
    padding: 10px 15px;
    width: 100%;
    border: 1px solid #d0d3cd;
    font-weight: 600;
    border-radius: 5px;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .container_select_payment {
      width: 100%;
      height: 90vh;
      overflow: auto;
      padding: 6%;
      .h1_text {
        font-size: 17px;
      }
      .btn_layout {
        flex-direction: column;
        .payment_item_wrap1 {
          margin: auto;
        }
        .payment_item_wrap2 {
          margin: auto;
          margin-bottom: 10px;
        }
        .payment_item_wrap3 {
          margin: auto;
        }
      }
    }
  }
`;
