import styled from "styled-components";
import { FONT, SCREEN } from "assets";

export const SidebarStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  height: 100vh;
  width: ${({ isShow }) => (isShow ? "100px" : "230px")};
  background-color: #DCDCDC;
  box-shadow: 4px 0px 34px -10px rgba(0,0,0,0.47);
  position: fixed;
  z-index: 10;
  .header{
    height: 150px;
    background-color: #000066;
    border-bottom: 2px solid #FFCC00;
    display:flex;
    .menu_icon{
      width: 60px;
      height: 50px;
      background-color: #FFCC00;
      margin-left: 10%;
      margin-top: 8px;
      display:flex;
      justify-content:center;
      align-items:center;
      border-radius:10px;
      img{
        width: 50px;
        &.rotate {
          transform: rotate(180deg);
        }
        :hover{
          background-color: white;
          border: 3px solid  #FFCC00;
          border-radius:10px;
        }
      }
    }
    .img_header{
      width: 180px;
      height: 110px;
      margin-left: 10%;
      margin-top: 20px;
    }
    .img_header2{
      width: 80px;
      height: 110px;
      margin-left: 10%;
      margin-top: 20px;
    }
  }
  .body_side{
    /* height: 100%; */
    /* width:  230px; */
    color: #DCDCDC;

    height: 444px;
    overflow: auto;

    // scroll bar stytle
    ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #ada4a4;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #000000;
    }
    ::-webkit-scrollbar-track {
      background: #bab5b5;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    ::-webkit-scrollbar-track:active {
      background: #333333;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    .pro-sidebar {
      background: none;
      color: #DCDCDC;
      margin-left:-20px;
      /* max-width: ${({ isShow }) => (isShow ? "10px" : "20px")}; */
      margin-right: 0px;
      width: ${({ isShow }) => (isShow ? "120px" : "230px")};
      min-width: ${({ isShow }) => (isShow ? "120px" : "230px")};
      .pro-inner-item{
        width:${({ isShow }) => (isShow ? "80px" : "200px")};
        padding: 8px 10px 8px 28px ;
      }
    }
    .txt_body{
      display:flex;
      flex-direction: row;
      // height: 50px;
      height: 70px;
      font-size: ${FONT.SIZE_8};
      background-color: #2562af;
      margin-left: 5%;
      :hover{
        background-color: #FFCC00;
        color: #696969;
        font-size: ${FONT.SIZE_10};
      }
      border-radius: 20px;
      border: 3px solid white;
      .flex_1{
        width: 30%;
        // margin: 2px 0 0 10px;
        margin: 8px 0 0 10px;
        .icon{
          width: 50px;
          height: 50px;
          // width: 45px;
          // height: 45px;
          background-color: white ;
          border-radius: 50%;
          border: 2px solid white;
        }
      }
      .flex_2{
        margin: 20px 0 0 10px;
      }
    }
  }
  .hr_layout{
    margin: 20px 0;
  }
  .footer_layout{
    .flex_exit{
      display:flex;
      flex-direction: row;
      width:${({ isShow }) => (isShow ? "76px" : "200px")};
      height: 70px;
      // height: 56px;
      font-size: ${FONT.SIZE_8};
      color: white;
      background-color: #CD5C5C;
      margin-left: 10px;
      border-radius: 20px;
      border: 3px solid white;
      :hover{
        background-color: #E9967A;
      }
      .icon_exit{
        width: 50px;
        height: 50px;
        background-color: white ;
        border-radius: 50%;
        border: 2px solid white;
        margin:10px 0 0 10px;
        // margin: 2px 0px 0 12px;
        img{
          width:30px;
        }
      }
      .txt_exit{
        margin: 20px 0 0 25px;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    width: ${({ isShow }) => (isShow ? "50px" : "110px")}; 
    .header{
      height: 15%;
      .menu_icon{
        width: 30px;
        height: 40px;
        margin-top: 5px;
        img{
          width: 30px;
          :hover{
            background-color: #FFCC00;
            border: 0px solid  #FFCC00;
          }
        }
      }
      .img_header{
        width: 90px;
        height: 60px;
        margin-left: 10%;
        margin-top: 20px;
      }
      .img_header2{
        width: 40px;
        height: 60px;
        margin-left: 10%;
        margin-top: 50%;
      }
    } 
    .body_side{
      .pro-sidebar {
        background: none;
        color: #DCDCDC;
        margin-left:-20px;
        max-width:  ${({ isShow }) => (isShow ? "50px" : "110px")}; 
        margin-right: 0px;
        .pro-inner-item{
          width:${({ isShow }) => (isShow ? "50px" : "85px")};
          padding:${({ isShow }) => (isShow ? "8px 0px 8px 20px" : "8px 0px 8px 30px")};  ;
        }
      }
      .txt_body{
        flex-direction: column;
        height:  ${({ isShow }) => (isShow ? "50px" : "70px")};
        line-height:0;
        font-size: ${FONT.SIZE_2};
        background-color: #2562af;
        margin-left:${({ isShow }) => (isShow ? "0px" : " 5% ")};
        border-radius: ${({ isShow }) => (isShow ? "10px" : " 20px ")};
        border: ${({ isShow }) => (isShow ? "white" : "")};
        .flex_1{
          width: 30%;
          margin: ${({ isShow }) => (isShow ? "3px 0 0 2px" : "8px 0 0 17px")};
          .icon{
            width: 40px;
            height: 40px;
          }
        }
        .flex_2{
          margin: 10px 0 0 -2px;
          text-align:center;
          :hover{
            font-size: ${FONT.SIZE_2};
          }
        }
      }
    }
    .footer_layout{
      .flex_exit{
        flex-direction: column;
        width:${({ isShow }) => (isShow ? "50px" : "80px")};
        height:  ${({ isShow }) => (isShow ? "50px" : "70px")};
        // height:  ${({ isShow }) => (isShow ? "50px" : "56px")};
        border-radius: ${({ isShow }) => (isShow ? "10px" : " 20px ")};
        font-size: ${FONT.SIZE_2};
        margin-left:  ${({ isShow }) => (isShow ? 0 : '10px')};
        border: ${({ isShow }) => (isShow ? "white" : "")};
        .icon_exit{
          width: 40px;
          height: 40px;
          background-color: white ;
          border-radius: 50%;
          border: 2px solid white;
          margin: ${({ isShow }) => (isShow ? "3px 0 0 2px" : "8px 0 0 17px")};
          img{
            width:20px;
          }
        }
        .txt_exit{
          margin: 0 0 0 13px;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    width: ${({ isShow }) => (isShow ? "0px" : "110px")}; 
    margin-left:  ${({ isShow }) => (isShow ? "-50px" : "0px")}; 
    position: ${({ isShow }) => (isShow ? "absolute" : "")}; 
    .header{
      height: 15%;
      .menu_icon{
        width: 40px;
        height: 40px;
        margin-left:  ${({ isShow }) => (isShow ? "20px" : "5px")}; 
        padding:0 10px;
        img{
          width: 30px;
        }
        .menu_icon_phone{
          width: 25px;
        }
      }
      .img_header{
        width: 90px;
        margin-left: 10%;
        margin-top: 20px;
      }
      .img_header2{
        width: 40px;
        height: 30px;
        margin-left: 10%;
        margin-top: 50%;
      }
    } 
    .body_side{
      .pro-sidebar {
        position: static;
        background: none;
        color: #DCDCDC;
        margin-left:-20px;
        max-width: 50px;
        margin-right: 0px;
      }
      .overlay{
        background-color: rgba(0, 0, 0, 0);
        z-index: -10;
      }
      .txt_body{
        flex-direction: column;
        height:  ${({ isShow }) => (isShow ? "50px" : "70px")};
        line-height:0;
        font-size: ${FONT.SIZE_2};
        background-color: #2562af;
        margin-left:${({ isShow }) => (isShow ? "0px" : " 5% ")};
        border-radius: ${({ isShow }) => (isShow ? "10px" : " 20px ")};
        border: ${({ isShow }) => (isShow ? "white" : "")};
        .flex_1{
          width: 30%;
          margin: ${({ isShow }) => (isShow ? "3px 0 0 2px" : "8px 0 0 17px")};
          .icon{
            width: 40px;
            height: 40px;
          }
        }
        .flex_2{
          margin: 10px 0 0 -2px;
          text-align:center;
        }
      }
    }
  }

`;
