import React, { useState, useEffect } from 'react';
import { InputAutoSuggestDoctorIDStyled } from './styled';
import { userService } from 'apiService';
import Autosuggest from 'react-autosuggest';
// import { Button } from 'components';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.displayname;

export const InputSuggestVote = ({ onChangeValue, handleClickVote }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [_valueSelected, _setValueSelected] = useState('');

  useEffect(() => {
    if (_valueSelected) {
      if (_valueSelected === 'reset') {
        onChangeValue && onChangeValue(false);
      } else {
        onChangeValue && onChangeValue(_valueSelected);
      }
    }
  }, [_valueSelected, onChangeValue]);

  const onChange = (event, { newValue }) => {
    _setValueSelected('reset');
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    let res = await userService.GET_VOTE_LIST_BY_SEARCH(value);
    if (res && res.success) {
      setSuggestions(res.data);
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'Vote your favorite name here : 1 vote/day',
    value,
    id: 'inputtext',
    onChange: onChange,
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    _setValueSelected(suggestion);
  };

  const _handleClickVote = () => {
    _setValueSelected('reset');
    setValue('');
    handleClickVote(_valueSelected.id);
  };

  return (
    <InputAutoSuggestDoctorIDStyled>
      <div className="input_layout">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={(suggestion) => suggestion.displayname}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
        />
      </div>
      <button
        className={`button ${
          !_valueSelected || _valueSelected === 'reset' ? 'disabled' : ''
        }`}
        disabled={!_valueSelected || _valueSelected === 'reset'}
        onClick={_handleClickVote}
      >
        VOTE
      </button>
    </InputAutoSuggestDoctorIDStyled>
  );
};
