import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const FooterPageStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    padding: 10px;
    display: flex;
    background: ${COLOR.GREY_7};
    border-top: 1px solid ${COLOR.YELLOW_3};
    .footer_left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .img_footer {
        width: 98px;
        height: auto;
        margin-right: 50px;
      }
      .text1 {
        font-weight: 600;
      }
      .text2 {
        font-size: ${FONT.SIZE_2};
        margin-bottom: 5px;
      }
      .input_wrap {
        cursor: pointer;
        margin-bottom: 3px;
        img {
          width: 250px;
          margin-bottom: 2px;
        }
        &:hover {
          opacity: 0.9;
        }
      }
      .group_form {
      }
    }
    .footer_right {
      width: 50%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .right_1 {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .text_follow {
          margin-right: 5%;
          font-size: ${FONT.SIZE_5};
          font-weight: 600;
          color: #3333cc;
        }
        /* .img_right_footer {
          width: 13px;
          margin-right: 8px;
        } */
        .text_right {
          display: flex;
          font-size: ${FONT.SIZE_5};
          font-weight: 500;
          .text_1 {
            margin-left: 20px;
            cursor: pointer;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
      .right_2 {
        display: flex;
        margin-bottom: 5px;
        .button_layout {
          font-size: ${FONT.SIZE_3};
          display: flex;
          justify-content: center;
          align-items: center;
          width: 150px;
          padding: 8px 5px;
          border: 1px solid ${COLOR.GREY_1};
          border-radius: 3px;
          margin-right: 5px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
      .right_3 {
        display: flex;
        flex-direction: column;
        line-height: 1.5;
        margin-bottom: 10px;
        .text_show {
          font-size: ${FONT.SIZE_2};
          margin-right: 10px;
          cursor: pointer;
        }
        .text_show_2 {
          font-size: ${FONT.SIZE_2};
          margin-right: 10px;
        }
        .text_show_3 {
          font-size: ${FONT.SIZE_2};
          margin-right: 10px;
          cursor: pointer;
          margin-top: 2px;
        }
        .text_show_4 {
          font-size: ${FONT.SIZE_2};
        }
      }
      .right_4 {
        font-size: ${FONT.SIZE_0_9};
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard {
      flex-direction: column;
      .footer_left {
        width: 100%;
        .input_wrap {
          margin-bottom: 3px;
          img {
            width: 200px;
            margin-bottom: 2px;
          }
        }
        .img_footer {
          width: 87px;
          height: 122px;
          margin-right: 11px;
          margin-left: 15px;
        }
        .group_form {
          width: 244px;
        }
      }
      .footer_right {
        align-items: center;
        padding-left: 0px;
        width: 100%;
        .right_1 {
          width: 100%;
          display: flex;
          flex-direction: column;
          .text_follow {
            margin-left: 4%;
            font-size: ${FONT.SIZE_5};
          }
          .text_right {
            display: flex;
            font-size: ${FONT.SIZE_5};
            font-weight: 500;
            margin-right: 30px;
            .text_1 {
            }
          }
        }
        .right_2 {
          .button_layout {
            width: 120px;
          }
        }
        .right_3 {
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      .footer_left {
        .input_wrap {
          margin-bottom: 3px;
          img {
            width: 180px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
`;
