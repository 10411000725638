import React from 'react';
import { NavbarStyled } from './styled';
import { iconImages } from 'assets';

export const Navbar = (props) => {
  return (
  <NavbarStyled>
    <div className="navbar_layout">
      <div className="txt_title">
        ADMINISTRATOR
      </div>
      <div className="avatar">
        <img src={iconImages['person.png']} alt="" />
      </div>
    </div>
  </NavbarStyled>
  )};
