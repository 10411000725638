import styled from 'styled-components';
// import { SCREEN } from "assets";

export const VideoContainerStyled = styled.div`
  /*===============================================
 Container
===============================================*/

  /*===============================================
Theme
===============================================*/
  position: relative;
  .icon_play_wrap {
    position: absolute;
    top: 200px;
    left: 110px;
    width: 100px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .icon_share_wrap {
    position: absolute;
    top: 5px;
    right: 35px;
    width: 25px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .icon_download_wrap {
    position: absolute;
    top: 5px;
    right: 7px;
    width: 24px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;
