import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { RefundContainerStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { RefundForm } from 'forms';
import { toast } from 'react-toastify';

class RefundContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();

    // Tracking user event
    ReactGA.event({
      category: 'booking',
      action: 'refund_view',
    });
    ReactPixel.pageView();
  }

  fetchData = async () => {
    const { orderID } = this.props;
    let res = await userService.GET_BOOKING_DETAIL(orderID);
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
      this.fetchDataRender(res.data);
    }
  };

  fetchDataRender = (data) => {
    if (data) {
      switch (data.invoice.status) {
        case 'PAID':
          if (
            data.invoice.refunds.length > 0 &&
            data.invoice.refunds[0].status === 'REQUESTED'
          ) {
            this.setState({
              step: 1,
              title: 'กำลังดำเนินการ',
            });
          } else {
            this.setState({
              step: 2,
              title: 'รอการขอคืนเงิน',
            });
          }
          break;
        case 'REFUNDED':
          this.setState({
            step: 3,
            title: 'เสร็จสิ้น',
            channelsRefunds:
              data.invoice.refunds[0].status === 'SUCCESS'
                ? data.invoice.refunds[0].method
                : 'ดำเนินการ',
          });
          break;
        default:
          break;
      }
    }
  };

  handleClickRefund = async () => {
    const { data } = this.state;
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
    };
    let res = await userService.POST_REFUND_DETAIL(data.invoice.id, params);
    if (res && res.success) {
      this.fetchData();
      toast.success('คำขอคืนเงินสำเร็จ');
      // Tracking user event
      ReactGA.event({
        category: 'booking',
        action: 'refund_success',
        value: parseInt(data.invoice.id),
      });
    } else {
      // Tracking user event
      ReactGA.event({
        category: 'booking',
        action: 'refund_fail',
        value: parseInt(data.invoice.id),
      });
    }

  };

  render() {
    const { title, step, channelsRefunds } = this.state;
    return (
      <RefundContainerStyled>
        <div className="refund_detail_layout">
          <div className="title_refund"> Refund Status </div>
          <RefundForm
            title={title}
            step={step}
            channelsRefunds={channelsRefunds}
            handleClickRefund={this.handleClickRefund}
          />
        </div>
      </RefundContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.RefundForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundContainer);
