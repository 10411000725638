import React from 'react';
import { connect } from 'react-redux';
import { CreatorRegisterContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { PopupRegister } from 'components';
import { ROUTE_PATH } from 'helpers';
import { CreatorForm } from 'forms';
import { Helmet } from 'react-helmet';
import { userService } from 'apiService';
class CreatorRegisterContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickRegister = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
    };
    let res = await userService.POST_ENROLL_CREATOR(params);
    if (res && res.success) {
      this.setState({
        popup: true,
      });
    }
  };

  handleClickCloseModal = () => {
    this.props.history.push(`${ROUTE_PATH.HOME}`);
  };

  render() {
    const { popup } = this.state;
    return (
      <CreatorRegisterContainerStyled>
        <Helmet>
          <title>creator register</title>
        </Helmet>
        <CreatorForm handleClickRegister={this.handleClickRegister} />
        {popup && (
          <PopupRegister handleClickCloseModal={this.handleClickCloseModal} />
        )}
      </CreatorRegisterContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CreatorForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorRegisterContainer);
