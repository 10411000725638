import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxVoteStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    width: 264px;
    height: 264px;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.6rem;
    .top_vote_layout {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      color: ${COLOR.BLACK_4};
      font-weight: 600;
      text-shadow: 0 0 0.3em #fff;
    }
    .bottom_vote_layout {
      height: 80px;
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255),
        rgba(232, 232, 232, 0.5)
      );
      opacity: 1;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .group_text {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .text_layer {
          margin-right: 5px;
          color: ${COLOR.BLACK_4};
          font-size: ${FONT.SIZE_8};
          font-weight: 600;
          &.fix_width {
            max-width: 212px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
      .button {
        cursor: pointer;
        font-size: ${FONT.SIZE_5};
        font-family: unicaOne_regular;
        width: 80%;
        border-radius: 20px;
        color: ${COLOR.YELLOW_2};
        padding: 10px 30px;
        border: 1px solid ${COLOR.GREY_5};
        font-weight: 600;
        background: ${COLOR.BLUE_5};
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      width: 185px;
      height: 185px;
      .bottom_vote_layout {
        height: 60px;
        background-image: linear-gradient(
          to top,
          rgba(255, 255, 255),
          rgba(232, 232, 232, 0.5)
        );
        opacity: 1;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .group_text {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          .text_layer {
            margin-right: 5px;
            color: ${COLOR.BLACK_4};
            font-size: ${FONT.SIZE_6};
            font-weight: 600;
            &.fix_width {
              max-width: 142px;
            }
          }
          img {
            width: 20px;
            height: 25px;
          }
        }
        .button {
          cursor: pointer;
          font-size: ${FONT.SIZE_5};
          font-family: unicaOne_regular;
          width: 80%;
          border-radius: 20px;
          color: ${COLOR.YELLOW_2};
          padding: 10px 30px;
          border: 1px solid ${COLOR.GREY_5};
          font-weight: 600;
          background: ${COLOR.BLUE_5};
        }
      }
    }
  }
`;
