import styled from 'styled-components';
import { SCREEN } from 'assets';

export const ReferalContainerStyled = styled.div`
  /*===============================================
 Container
===============================================*/

  /*===============================================
Theme
===============================================*/
  .Referal_display {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    .Referal_layout {
      background-color: #ffd11a;
      padding: 5%;
      border: 3px solid#0059b3;
      border-radius: 34px;
      box-shadow: 5px 10px #dedede;
      text-align: 'center';
      .title {
        color: black;
        font-family: prompt_medium;
      }
      .pp {
        padding: 10;
        padding-bottom: 2%;
        color: black;
        text-align: center;
        .span_wow {
          color: #ffd11a;
          text-shadow: 2px 2px 0px #24478f;
          font-size: 27px;
          padding-right: 5px;
        }
      }
      .copy {
        display: flex;
        justify-content: center;
        input {
          font-size: 16px;
          color: #0059b3;
          padding: 13px 30px;
          border: 1px solid #d0d3cd;
          font-weight: 600;
          border-radius: 5px;
        }
      }
      .btn_getcode {
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-family: 'unicaOne_regular', 'prompt_light';
        color: #ffd11a;
        background: #0059b3;
        padding: 10px 30px;
        border: 1px solid #d0d3cd;
        font-weight: 600;
        border-radius: 5px;
        &.share {
          background: #0059b3;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .Referal_display {
      width: 100%;
      .Referal_layout {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        .title {
          font-size: 25px;
          text-align: center;
        }
        .copy {
          flex-direction: column;
          input {
            margin-bottom: 5px;
          }
          .btn_getcode {
            &.share {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .Referal_display {
      .Referal_layout {
        .title {
          font-size: 18px;
        }
      }
    }
  }
`;
