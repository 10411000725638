import styled from 'styled-components';
import { COLOR, SCREEN } from 'assets';

export const BannerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  height: 350px;
  background: ${COLOR.YELLOW_4};
  text-align: center;
  line-height: 1.2;
  border-radius: 6px;
  color: black;
  font-size: 60px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  overflow: hidden;
  &:after {
    content: ' ',
    background-color: red;
  } 
  video {
    object-fit: cover;
    width: 100%;
  }
  .group_text {
    align-self: center;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    .gift_icon{
      margin-left: 20px;
    }
    .text_banner{

    }
    .text_mid_banner{
      font-weight: 700;
    }
  }
  .pic_layer {
    width: 20%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
  }
  .pic_layer_1 {
    width: 20%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
  }
  .pic_layer_2 {
    width: 20%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
  }

  @media(max-width: ${SCREEN.TABLET} ){
    height: 190px;
    text-align: center;
    line-height: 1.2;
    border-radius: 6px;
    color: black;
    margin-bottom: 0px;
    background: #fbc210;
    .group_text {
      align-self: center;
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      .gift_icon{
        margin-left: 20px;
        height: 23px;
        width: 20px;
      }
      .text_banner{
        font-weight: 400;
        font-size: 33px;
      }
      .text_mid_banner{
        font-size: 33px;
        font-weight: 700;
      }
    }
  }
  @media(max-width: ${SCREEN.PHONE}){
    height: 190px;
    text-align: center;
    line-height: 1.2;
    border-radius: 6px;
    color: black;
    margin-bottom: 0px;
    background: #fbc210;
    .group_text {
      align-self: center;
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      .gift_icon{
        margin-left: 15px;
        height: 15px;
        width: 23px;
      }
      .text_banner{
        font-weight: 400;
        font-size: 20px;
      }
      .text_mid_banner{
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
`;
