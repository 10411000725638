import styled from 'styled-components';
import { FONT, SCREEN } from 'assets';

export const CreatorContainerStyled = styled.div`
  /*===============================================
 Container
===============================================*/

  /*===============================================
Theme
===============================================*/
  display: flex;
  padding: 1rem;
  margin: auto;
  max-width: 1100px;
  .left_layout {
    width: 100%;
    margin-right: 20px;
    padding-right: 10px;
    .warning_text {
      color: red;
      font-size: 13px;
      padding-left: 4%;
    }
    .display {
      display: flex;
      justify-content: space-between;
      img.member_img {
        object-fit: cover;
        border-radius: 11px;
      }
      .creator_item {
        position: relative;
        .poster_video_figture {
          position: relative;
          display: ${({ typeShow }) =>
            typeShow === 'video' ? 'block' : 'none'};
        }
        .poster_img_figture {
          display: ${({ typeShow }) =>
            typeShow === 'video' ? 'none' : 'block'};
        }
        .coming_soon {
          position: absolute;
          top: 158px;
          left: 42px;
        }
        .icon_share_wrap {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 25px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
        .icon_volume_wrap {
          position: absolute;
          bottom: 10px;
          right: 5px;
          width: 22px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
        .icon_play_wrap {
          position: absolute;
          top: 200px;
          left: 110px;
          width: 100px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .but_box {
        padding-left: 1rem;
        .hide {
          display: none;
        }
        .show {
          color: red;
          font-size: 15px;
          text-align: right;
          max-width: 310px;
        }
        .group_label {
          display: flex;
          .left_label {
            .displayname {
              font-size: ${FONT.SIZE_12};
              font-weight: bold;
            }
          }
          .right_label {
            padding-left: 10px;
          }
        }
        .displayname_2 {
          font-size: ${FONT.SIZE_12};
          font-weight: bold;
        }
        .description {
          font-weight: bold;
        }
        .about {
          max-width: 317px;
          white-space: pre-wrap;
        }
        .ration {
          display: flex;
          margin-top: 10px;
          border: 2px solid #0059b3;
          border-radius: 6px;
          padding: 5px;
          width: 296px;
          justify-content: center;
          .timer_icon {
            margin-right: 5px;
          }
        }
        .button_rq {
          cursor: pointer;
          font-size: 16px;
          font-family: unicaOne_regular;
          color: #ffd11a;
          background: #0059b3;
          margin-top: 5px;
          padding: 10px 10px;
          border: 1px solid #d0d3cd;
          font-weight: 600;
          border-radius: 5px;
          max-width: 310px;
          width: 100%;
        }
        .button_rq_2 {
          cursor: pointer;
          font-size: 16px;
          font-family: unicaOne_regular;
          color: #ffffff;
          background: #bebebe;
          margin-top: 5px;
          padding: 10px 10px;
          border: 1px solid #d0d3cd;
          font-weight: 600;
          border-radius: 5px;
          max-width: 310px;
          width: 100%;
        }
        .button_wl {
          cursor: pointer;
          font-size: 16px;
          font-family: unicaOne_regular;
          color: #0059b3;
          background: #ffd11a;
          padding: 10px 130px;
          margin-top: 5px;
          border: 1px solid #d0d3cd;
          font-weight: 600;
          border-radius: 5px;
          max-width: 310px;
          width: 100%;
        }
        .button_wl2 {
          cursor: pointer;
          font-size: 16px;
          font-family: unicaOne_regular;
          color: #0059b3;
          background: #bebebe;
          padding: 10px 130px;
          margin-top: 5px;
          border: 1px solid #d0d3cd;
          font-weight: 600;
          border-radius: 5px;
          max-width: 310px;
          width: 100%;
        }
      }
    }
    .video_layout {
      padding-top: 20px;
      max-width: 699px;
      width: 100%;
      margin: auto -5px auto -5px;
      .video_item_wrap {
        padding: 0 5px;
      }
    }
  }
  .right_layout {
    width: 580px;
    padding-left: 10px;
    .what_is {
      text-align: center;
      .what_is_title {
        font-weight: bold;
        font-size: 28px;
      }
      .what_is_display {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        .what_is_1 {
          padding: 10px;
          margin-right: 12px;
        }
        .what_is_2 {
          padding: 10px;
        }
        .what_is_3 {
          padding: 10px;
          max-width: 102px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    flex-direction: column;
    .left_layout {
      width: 100%;
      padding: 0;
      .display {
        flex-direction: column;
        align-items: center;
        .but_box {
          padding: 0;
          width: 100%;
          max-width: 317px;
          .group_label {
            display: flex;
            .left_label {
              .displayname {
                margin: 10px 0;
                font-size: ${FONT.SIZE_8};
                text-align: center;
              }
            }
          }
          .displayname_2 {
            margin: 10px 0;
            font-size: ${FONT.SIZE_8};
            text-align: center;
            font-weight: bold;
          }
          .show {
            max-width: unset;
          }
          .button_rq {
            max-width: 100%;
            margin-bottom: 5px;
          }
          .button_wl {
            max-width: 100%;
          }
          .button_wl2 {
            max-width: 100%;
          }
          .button_rq_2 {
            max-width: 100%;
            margin-bottom: 5px;
          }
          .ration {
            width: 98%;
          }
        }
      }
      .video_layout {
        margin: auto;
      }
    }
    .right_layout {
      width: 100%;
      padding: 0;
      .what_is {
        .what_is_title {
          padding-top: 25px;
        }
      }
      .recom_layout {
        text-align: center;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .show {
      max-width: unset;
    }
    input {
      max-width: 70%;
    }
    .right_col {
      font-size: 12px;
    }
    .ration {
      width: 96% !important;
    }
    .web_menu_layout {
      background: #ffd11a !important;
    }
    .but_box {
      .group_label {
        padding-top: 10px;
        .right_label {
          padding-left: 10px;
        }
      }
    }
  }
`;
