import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { CreatorContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
import { iconImages } from 'assets';
import { RecommendHappyNext, VideoPlayer } from 'components';
import { ShraeSocial } from 'widgets';
import 'moment/locale/th';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { DropdownCustom2 } from 'components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

class CreatorContainer extends React.Component {
  state = { current_rate_coins: 0, isShowDropDown: false };

  componentDidMount() {
    this.scrollToTop();
    this.fetchDataCreator();
    this.fetchFollowList();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();

    // Tracking user event
    ReactGA.event({
      category: 'creator',
      action: 'view',
      nonInteraction: true,
      value: parseInt(this.props.match.params.id),
    });
  }

  componentWillUnmount() {
    if (this.videoRef) this.videoRef.removeEventListener('ended', () => {});
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  onRefVideo = (e) => {
    this.videoRef = e;
  };

  onRefLink = (e) => {
    this.inputLink = e;
  };

  fetchData = async () => {
    let res = await userService.GET_CREATOR_DETAIL(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        data: res.data,
        dataRef: `${window.location.origin}${ROUTE_PATH.CREATOR}/${this.props.match.params.id}`,
      });
      return res.data;
    }
  };

  fetchCreatorServiceList = async () => {
    let res = await userService.GET_CREATOR_SERVICES_LIST(
      this.props.match.params.id
    );
    if (res && res.success) {
      if (res.data) {
        this.setState({
          current_rate_coins: res.data[0].rate_coins,
          isShowDropDown: res.data.length > 1 ? true : false,
          serviceList: res.data,
          dataDropDown: res.data.map((e, i) => ({
            label: e.name,
            value: e.id,
          })),
          serviceSelected: res.data[0],
        });
        return res.data;
      } else {
        this.setState({
          current_rate_coins: 0,
          isShowDropDown: false,
        });
        return false;
      }
    }
  };

  fetchFollowList = async () => {
    let res = await userService.GET_ME_FOLLOW_LIST();
    if (res && res.success) {
      let findFollow = res.data.find(
        (e) => e.id === Number(this.props.match.params.id)
      );
      this.setState({
        dataFollowed: res.data,
        followCurrent: findFollow,
        isFollowed: findFollow ? true : false,
      });
    }
  };

  fetchDataCreator = async () => {
    let resArr = await Promise.all([
      this.fetchData(),
      this.fetchCreatorServiceList(),
    ]);
    // console.log('resArr', resArr);
    this.handleShowPosterPriority(resArr[0], resArr[1]);
  };

  handleShowPosterPriority = async (dataNormal, dataService) => {
    if (dataService && dataService.length > 0) {
      this.handlePosterServiceDetail(dataNormal, dataService[0]);
    } else {
      this.handlePosterNormalDetail(dataNormal);
    }
  };

  handlePosterNormalDetail = (dataNormal) => {
    // console.log('data normal', dataNormal);
    const findVideo = dataNormal.posters.find((e) => e.type === 'video');
    this.setState(
      {
        poster: findVideo
          ? { url: findVideo.uri, type: 'video' }
          : {
              url:
                (dataNormal &&
                  dataNormal.posters &&
                  dataNormal.posters.length > 0 &&
                  dataNormal.posters[0].uri) ||
                iconImages['yellowbg_member.png'],
              type: 'img',
            },
      },
      async () => {
        if (findVideo) {
          try {
            await this.videoRef.play();
          } catch (err) {
            this.handleEndVideo();
          }
          this.videoRef.addEventListener('ended', this.handleEndVideo);
        }
      }
    );
  };

  handlePosterServiceDetail = (dataNormal, dataServiceSelected) => {
    const findPoster = dataServiceSelected?.poster_video_uri
      ? { url: dataServiceSelected?.poster_video_uri, type: 'video' }
      : dataServiceSelected?.poster_photo_uri
      ? { url: dataServiceSelected?.poster_photo_uri, type: 'img' }
      : false;
    if (findPoster) {
      this.setState(
        {
          poster: findPoster,
        },
        async () => {
          if (findPoster?.type === 'video') {
            try {
              await this.videoRef.play();
            } catch (err) {
              // console.log('eerr', err);
              this.handleEndVideo();
            }
            this.videoRef.addEventListener('ended', this.handleEndVideo);
          }
        }
      );
    } else {
      this.handlePosterNormalDetail(dataNormal);
    }
  };

  handleClickFollow = async () => {
    let action = 'click_follow';
    const { isFollowed } = this.state;
    if (!isFollowed) {
      let params = {
        creator_id: this.props.match.params.id,
      };
      let res = await userService.POST_ME_FOLLOW_DETAIL(params);
      if (res && res.success) {
        action = 'click_follow';
        toast.success('ติดตามสำเร็จ');
        this.setState({
          isFollowed: true,
        });
      }
    } else {
      let res = await userService.DELETE_ME_FOLLOW_DETAIL(
        this.props.match.params.id
      );
      if (res && res.success) {
        action = 'click_unfollow';
        toast.error('ยกเลิกติดตามสำเร็จ');
        this.setState({
          isFollowed: false,
        });
      }
    }

    // Tracking user event
    ReactGA.event({
      category: 'creator',
      action: action,
      value: parseInt(this.props.match.params.id),
    });
  };

  handleClickRequestCreator = () => {
    const { serviceSelected } = this.state;
    // Tracking user event
    ReactGA.event({
      category: 'creator',
      action: 'click_booking',
      value: parseInt(this.props.match.params.id),
    });
    const { authRedux } = this.props;
    this.props.history.push(
      authRedux
        ? `${ROUTE_PATH.ORDER_CREATOR}/${this.props.match.params.id}/${serviceSelected.id}`
        : ROUTE_PATH.LOGIN
    );
  };

  handleClickShare = () => {
    const { dataRef } = this.state;
    let textArea = document.createElement('textarea');
    textArea.value = dataRef;
    textArea.background = 'transparents';
    textArea.height = '0px';
    textArea.width = '0px';
    document.body.append(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  handleClickVolume = () => {
    const ref = this.videoRef;
    this.setState(
      (state) => ({
        isMute: !state.isMute,
      }),
      () => {
        ref.muted = !this.state.isMute;
      }
    );
  };

  handleEndVideo = () => {
    this.setState({
      isShowPlay: true,
    });
  };

  handleClickPlay = () => {
    this.videoRef.play();
    this.setState({
      isShowPlay: false,
    });
  };

  handleClickService = (e) => {
    const { data, serviceList } = this.state;
    const findServiceDataByID = serviceList.find(
      (ev) => String(ev.id) === String(e.target.value)
    );
    this.setState({
      serviceSelected: findServiceDataByID,
      current_rate_coins: findServiceDataByID.rate_coins,
    });
    this.handlePosterServiceDetail(data, findServiceDataByID);
  };

  render() {
    const {
      data,
      isFollowed,
      poster,
      isMute,
      dataRef,
      isShowPlay,
      current_rate_coins,
      isShowDropDown,
      dataDropDown,
    } = this.state;
    if (
      this.props.location.search !== '' &&
      document.getElementsByClassName('video_layout').length !== 0
    ) {
      window.scroll(
        0,
        document.getElementsByClassName('video_layout')[0].scrollHeight
      );
    }
    return (
      <CreatorContainerStyled
        typeShow={poster?.type === 'video' ? 'video' : 'img'}
      >
        {data && (
          <Helmet>
            <title>creator</title>
            <meta
              name="og:image"
              content={
                data.posters && data.posters.length > 0
                  ? data.posters[0].uri
                  : null
              }
            />
            <meta name="og:title" content={data.displayname} />
          </Helmet>
        )}
        <div className="left_layout">
          <div className="display">
            <div className="creator_item">
              <div className="poster_video_figture">
                <VideoPlayer
                  useCustomRef
                  onRef={this.onRefVideo}
                  url={poster?.url}
                  width="320px"
                  height="560px"
                  muted
                  controls={false}
                  autoplay
                  ended={this.handleEndVideo}
                />
                {!isMute ? (
                  <img
                    className="icon_volume_wrap"
                    src={iconImages['icon_no_volume.png']}
                    alt="icon share"
                    onClick={this.handleClickVolume}
                  />
                ) : (
                  <img
                    className="icon_volume_wrap"
                    src={iconImages['icon_volume.png']}
                    alt="icon share"
                    onClick={this.handleClickVolume}
                  />
                )}
                {isShowPlay && (
                  <img
                    className="icon_play_wrap"
                    src={iconImages['play_btn.png']}
                    alt="icon share"
                    onClick={this.handleClickPlay}
                  />
                )}
              </div>
              <div className="poster_img_figture">
                <img
                  className="member_img"
                  src={poster?.url}
                  width="317px"
                  height="470px"
                  alt="logo icon"
                />
              </div>
              {data && data.is_upcoming === 1 && (
                <div className="coming_soon">
                  <img
                    className="logo_coming"
                    src={iconImages['coming_soon.png']}
                    alt="logo"
                  />
                </div>
              )}
              <ShraeSocial url={dataRef}>
                <img
                  className="icon_share_wrap"
                  src={iconImages['icon_share.svg']}
                  alt="icon share"
                  onClick={this.handleClickShare}
                />
              </ShraeSocial>
            </div>
            <div className="but_box">
              {/* <div className="group_label">
                <div className="left_label">
                  <div className="displayname">{data && data.displayname}</div>
                </div>
                {
                  isShowDropDown &&
                  <div className="right_label">
                    <DropdownCustom2
                      theme_standard
                      dataDropDown={dataDropDown}
                      onChange={(e) => this.handleClickService(e)}
                    />
                  </div>
                }
              </div> */}
              {isShowDropDown ? (
                <div className="group_label">
                  <div className="left_label">
                    <div className="displayname">
                      {data && data.displayname}
                    </div>
                  </div>
                  <div className="right_label">
                    <DropdownCustom2
                      theme_standard
                      dataDropDown={dataDropDown}
                      onChange={(e) => this.handleClickService(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="displayname_2">{data && data.displayname}</div>
              )}
              <div className="description">{data && data.description}</div>
              <div className="about">{data && data.about}</div>
              {data &&
                // data.is_highlight === 1 &&
                data.activity_count !== 0 && (
                  <div className="show">
                    มีคนกำลังซื้อ {data && data.activity_count} คน
                  </div>
                )}
              {current_rate_coins > 0 && (
                <button
                  className={
                    data && data.is_upcoming === 0 ? 'button_rq' : 'button_rq_2'
                  }
                  onClick={() => {
                    if (data && data.is_upcoming === 0) {
                      this.handleClickRequestCreator();
                    }
                  }}
                >
                  {`สั่งซื้อ ฿${
                    data && parseInt(current_rate_coins).toLocaleString()
                  }`}
                </button>
              )}
              <button
                className={
                  isFollowed && isFollowed === true ? 'button_wl2' : 'button_wl'
                }
                onClick={this.handleClickFollow}
              >
                {isFollowed ? 'UnFollow' : `Follow`}
              </button>
              <div className="ration">
                <img
                  className="timer_icon"
                  src={iconImages['timer.png']}
                  width="20"
                  height="20"
                  alt="like icon"
                />
                ตอบกลับภายใน : 48 ชั่วโมง
                {/* {moment
                  .duration(data && data.avg_rating, 'minute')
                  .locale('th')
                  .humanize()} */}
              </div>
              <div className="warning_text">
                ***ราคาขณะนี้ราคาเป็นพิเศษสำหรับช่วงเปิดตัวเท่านั้น
              </div>
            </div>
          </div>
          {data && data.videos.length > 0 && (
            <div className="video_layout">
              <Carousel responsive={responsive}>
                {data.videos.map((e, i) => (
                  <div key={i} className="video_item_wrap">
                    <VideoPlayer
                      controls={false}
                      txtEvent={e.category.name_th}
                      url={e.file_preview.uri}
                      poster={e.poster_jpg_uri}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </div>
        <div className="right_layout">
          <div className="what_is">
            <div className="what_is_title">HOW IT WORKS ?</div>
            <div className="howtobuy_title">
              วิธีการสั่งซื้อวิดีโอเซอร์ไพรส์ จากศิลปินดารา
            </div>
            <div className="what_is_display">
              <div className="what_is_1">
                <img
                  src={iconImages['sendfile.png']}
                  width="30"
                  height="30"
                  alt="send file icon"
                />
                <p>ส่งคำขอ</p>
              </div>
              <div className="what_is_2">
                <img
                  src={iconImages['moblie.png']}
                  width="20"
                  height="30"
                  alt="mobile icon"
                />
                <p>รอการตอบรับ</p>
              </div>
              <div className="what_is_3">
                <img
                  src={iconImages['like.png']}
                  width="30"
                  height="30"
                  alt="like icon"
                />
                <p>รับวิดีโอ</p>
              </div>
            </div>
          </div>
          <div className="recom_layout">
            <RecommendHappyNext
              theme_standard
              creatorName={data && data.displayname}
            />
          </div>
        </div>
      </CreatorContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorContainer);
