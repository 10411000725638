import styled from "styled-components"
import {
    COLOR,
    // FONT
} from 'assets'
import { SCREEN } from 'assets';

export const LoginContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .login_container {
        padding: 30px;
        display: flex;
        justify-content: center;
        .layer_one {
            border: 1px solid ${COLOR.GREY_2};
            padding: 90px;
            border-radius: 5px;
            text-align: center;
            line-height: 1.5;
            max-width: 700px;
        }
        button.btnFacebook.metro {
            max-width: 300px;
        }
        button.button {
            max-width: 300px;
            margin: unset !important;
        }
        .group_emailbtn {
            display: flex;
            justify-content: center;
        }
    }

    @media (max-width: ${SCREEN.PHONE}) {
        .login_container .layer_one {
            border: 1px solid #d0d3cd;
            padding: 21px;
            border-radius: 5px;
            text-align: center;
            line-height: 1.5;
            max-width: 700px;
        }
      }

      
`