import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { AgencyProfileFormSec1Styled } from './styled';
// import { iconImages } from 'assets'
import { VALIDATE } from 'helpers';
import {
  Input,
  ButtonCustom,
} from 'components';

class AgencyProfileFormSec1 extends React.Component {
  state = {};

  render() {
    const { handdleSave, handdleDelete, isAdd } = this.props;
    return (
      <AgencyProfileFormSec1Styled>
        {/* <form > */}
          <fieldset>
            <legend>Company Profile</legend>
            <div className="grop_form">
              <div className="box_label">
                <div className="label">Name</div>
                <Field
                  theme_standard_2
                  name="displayname"
                  placeholder="Enter Name"
                  component={Input}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="box_label">
                <div className="label">Description</div>
                <Field
                  theme_standard_2
                  name="description"
                  placeholder="Enter Description"
                  component={Input}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="button_layer">
                <ButtonCustom theme_standard label="SAVE" onClick={handdleSave} />
                {
                  !isAdd &&
                  <ButtonCustom theme_standard label="DELETE" onClick={handdleDelete} />
                }
              </div>
            </div>
          </fieldset>
        {/* </form> */}
      </AgencyProfileFormSec1Styled>
    );
  }
}

export default reduxForm({
  form: 'AgencyProfileFormSec1', // a unique identifier for this form
  enableReinitialize: true,
})(AgencyProfileFormSec1);
