import React from 'react';
import { InputLineStyled } from './styled';
import cx from 'classnames';

export const InputLine = ({
  theme_standard,
  label,
  input,
  type,
  disabled,
  notShowData,
  color,
  width,
  showOnly,
  meta: { touched, error },
  inputWidth,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <InputLineStyled
      isInvalid={touched && error}
      width={width}
      color={color}
      inputWidth={inputWidth}
    >
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {showOnly ? (
          input.value
        ) : (
          <>
            {notShowData ? (
              <input type={type || 'text'} disabled={disabled} />
            ) : (
              <input type={type || 'text'} {...input} disabled={disabled} />
            )}
            {touched && error && <div className="error_wrap">{error}</div>}
          </>
        )}
      </div>
    </InputLineStyled>
  );
};
