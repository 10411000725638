import React, { useState } from 'react';
import { InputTextareaLabelStyled } from './styled';
import cx from 'classnames';

export const InputTextareaLabel = ({
  theme_standard,
  label,
  input,
  disabled,
  width,
  meta: { touched, error },
  handleClickSuccess,
  hideIcon,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [isEdit, setIsEdit] = useState();

  const handleClickEdit = (e) => {
    setIsEdit(e);
  };

  return (
    <InputTextareaLabelStyled isInvalid={touched && error} width={width}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {!isEdit ? (
          <div className="group_label">
            <div>{input.value}</div>
            {!hideIcon ? (
              <i
                className="material-icons"
                onClick={() => handleClickEdit(true)}
              >
                &#xe254;
              </i>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            <textarea {...input} disabled={disabled} />
            {touched && error && <div className="error">{error}</div>}
            <div>
              <button
                className="button_profile"
                onClick={() => {
                  handleClickEdit(false);
                  handleClickSuccess();
                }}
              >
                Subbmit
              </button>
              <button
                className="button_profile2"
                onClick={() => handleClickEdit(false)}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </InputTextareaLabelStyled>
  );
};
