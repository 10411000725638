import styled from 'styled-components';
import // COLOR,
// FONT
'assets';
import { SCREEN } from 'assets';

export const OrderSuccessContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  max-width: 1100px;
  margin: auto;
  .center_content {
    text-align: center;
  }
  .container {
    display: flex;
    justify-content: center;
    justify-content: space-around;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    .left_layout {
      display: inline-block;
      border-radius: 16px;
      padding: 15px;
      border: 2px solid #0059b3;
      background-color: rgb(240, 248, 255, 0.1);
      margin-bottom: 30px;
      width: calc(100% - 350px);
      margin-right: 5px;
    }
    .right_layout {
      width: 350px;
      margin-left: 5px;
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .container {
      flex-direction: column;
      .left_layout {
        width: calc(100% - 37px);
        margin-right: 0;
        .button {
          button {
            width: 100%;
          }
        }
      }
      .right_layout {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .container {
      padding-top: 20px;
      .left_layout {
      }
    }
  }
`;
