import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const CategoriesContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  max-width: 1400px;
  margin: auto;
  .text_title {
    font-size: ${FONT.SIZE_14};
    color: ${COLOR.YELLOW_4};
    text-shadow: 2px 2px 2px ${COLOR.BLUE_6};
    padding: 20px;
    background: ${COLOR.WHITE_1};
  }
  .banner_layout {
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 16px;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    .item_wrap {
      width: calc(20% - 10px);
      margin: 5px;
      border-radius: 16px;
    }
  }
  .text_no_follow {
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    margin-top: 15%;
    .text_1 {
      font-size: ${FONT.SIZE_10};
      color: #696969;
      font-weight: 500;
    }
    .text_2 {
      font-size: ${FONT.SIZE_13};
      color: #ff1493;
      font-weight: 600;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .container {
      .item_wrap {
        width: calc(33% - 10px);
      }
    }
    .text_no_follow {
      display: flex;
      flex-direction: column;
      align-self: center;
      text-align: center;
      margin-top: 15%;
      .text_1 {
        font-size: ${FONT.SIZE_10};
        color: #696969;
        font-weight: 500;
      }
      .text_2 {
        font-size: ${FONT.SIZE_12};
        color: #ff1493;
        font-weight: 600;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .text_title {
      font-size: ${FONT.SIZE_10};
      padding: 10px;
    }
    .banner_layout {
      margin-bottom: 10px;
    }
    .text_left {
      font-size: ${FONT.SIZE_6};
      color: ${COLOR.GREY_8};
      font-weight: 600;
    }
    .container {
      .item_wrap {
        width: calc(50% - 10px);
      }
    }
    .text_no_follow {
      display: flex;
      flex-direction: column;
      align-self: center;
      text-align: center;
      margin-top: 40%;
      .text_1 {
        font-size: ${FONT.SIZE_4};
        color: #696969;
        font-weight: 500;
      }
      .text_2 {
        font-size: ${FONT.SIZE_8};
        color: #ff1493;
        font-weight: 600;
      }
    }
  }
`;
