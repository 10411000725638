import styled from 'styled-components';
import // COLOR,
// FONT
'assets';

export const RefundContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .refund_detail_layout {
    max-width: 900px;
    margin: 20px auto;
    .title_refund {
      font-weight: 600;
      font-size: 24px;
      color: #fbc210;
    }
  }
`;
