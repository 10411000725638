import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { OrderSuccessContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { RecommendHappyNext } from 'components';
import { ROUTE_PATH } from 'helpers';
import { OrderSuccessForm } from 'forms';

class OrderSuccessContainer extends React.Component {
  state = {
    isModal: true,
  };

  componentDidMount() {
    this.fetchData();
    this.fetchCoupon();
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await userService.GET_BOOKING_DETAIL(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        data: res.data,
        initialValues: {
          brief: res.data.brief,
        },
      });

      // Tracking user event
      ReactGA.event({
        category: 'booking',
        action: 'order_success',
        value: parseInt(res.data.invoice.rate_coins),
      });
    }
  };

  fetchCoupon = async () => {
    let res = await userService.GET_COUPON_DETAIL();
    if (res && res.success) {
      this.setState({
        dataCoupon: res.data,
      });
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
    });
  };

  handleClickCancelOrder = async () => {
    let res = await userService.DELETE_BOOKING_DETAIL(
      this.props.match.params.id
    );
    if (res & res.success) {
      this.props.history.push('/');
    }
  };

  handleEditOrder = () => {};

  handleClickPaymentSuccess = () => {
    const { data } = this.state;
    this.props.history.push(
      `${ROUTE_PATH.PAYMENT_PROCESS_MAIN}/${data.invoice.id}/callback`
    );
  };

  handleSubmit = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    // console.log('value', values);
    let params = { ...values };
    let res = await userService.PATCH_BRIEF_DETAIL(
      this.props.match.params.id,
      params
    );
    if (res && res.success) {
    }
  };

  render() {
    const { data, initialValues } = this.state;
    return (
      <OrderSuccessContainerStyled>
        <div className="center_content">
          <h2>ได้รับคำสั่งจองของคุณแล้ว</h2>
          <div>กรุณาดำเนินการชำระเงิน เพื่อยืนยันคำสั่งจองของคุณ</div>
        </div>
        <div className="container">
          <div className="left_layout">
            {data && (
              <OrderSuccessForm
                data={data}
                initialValues={initialValues}
                handleClickPaymentSuccess={this.handleClickPaymentSuccess}
                handleSubmit={this.handleSubmit}
              />
            )}
          </div>
          <div className="right_layout">
            <RecommendHappyNext theme_standard />
          </div>
        </div>
      </OrderSuccessContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.OrderSuccessForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSuccessContainer);
