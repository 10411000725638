import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Notification } from 'components';
import {
  PageMainLayout,
  PageNormalMainLayout,
  CmsMainLayout,
} from 'mainLayouts';
import LandingCreatorContainer from 'containers/LandingCreatorContainer';
import ReviewPageContainer from 'containers/ReviewPageContainer';

const indexRoutes = [
  {
    path: '/',
    exact: true,
    name: 'page',
    component: PageMainLayout,
  },
  {
    path: '/page',
    exact: false,
    name: 'pageNormal',
    component: PageNormalMainLayout,
  },
  {
    path: '/main',
    exact: false,
    name: 'page',
    component: PageMainLayout,
  },
  {
    path: '/v/:id',
    exact: true,
    name: 'v',
    component: LandingCreatorContainer,
  },
  {
    path: '/v/:id/review',
    exact: false,
    name: 'review',
    component: ReviewPageContainer,
  },
  // {
  //   path: '/s/:id',
  //   exact: true,
  //   name: 's',
  //   component: LandingCreatorContainer,
  // },
  {
    path: '/admin',
    exact: false,
    name: 'review',
    component: CmsMainLayout,
  },
];

class Routes extends React.Component {
  render() {
    return (
      <>
        <Notification />
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route
                exact={prop.exact}
                path={prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
