import React from 'react';
import { RecommendHappyNextStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';

export const RecommendHappyNext = ({ theme_standard, creatorName }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <RecommendHappyNextStyled>
      <div className={customClass}>
        <div className="title">แนะนำขั้นตอน</div>
        <div className="row detail">
          <div>
            <img src={iconImages['icon1.png']} alt="icon1" />
          </div>
          <div className="text">
            <b>{`${creatorName ? creatorName : ''} `}</b>
            จะใช้เวลาโดยประมาณ <span className="text_hightlight">
              7 วัน{' '}
            </span>{' '}
            ในการตอบรับหรือปฏิเสธ และทำวิดีโอของคุณ
          </div>
        </div>
        <div className="row detail">
          <div>
            <img src={iconImages['icon3.png']} alt="icon3" />
          </div>
          <div className="text">
            คุณสามารถดูการ{' '}
            <span className="text_hightlight">
              อัพเดทต่างๆผ่านหน้า Profile{' '}
            </span>
            และอีเมลล์ได้ที่ลงทะเบียนไว้
          </div>
        </div>
        <div className="row detail">
          <div>
            <img src={iconImages['icon2.png']} alt="icon4" />
          </div>
          <div className="text">
            หากคำขอของคุณถูกปฏิเสธ คุณสามารถทำการขอคืนเงินได้ในหน้า Profile
          </div>
        </div>
      </div>
    </RecommendHappyNextStyled>
  );
};
