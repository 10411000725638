import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const InputTextArea2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  textarea {
    outline: none;
    border: none;
    border-bottom: 1px solid rgb(131, 139, 139, 0.6);
    width: 100%;
    resize: none;
    overflow: hidden;
    margin-bottom: 10px;
    font-size: 16px;
    font-family: prompt_light;
  }
  .error_wrap {
    color: ${COLOR.RED_1};
    font-size: ${FONT.SIZE_1};
  }
  @media (max-width: ${SCREEN.TABLET}) {
    textarea {
      min-height: 125px;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    textarea {
      min-height: 160px;
    }
  }
`;
