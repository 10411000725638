import React from 'react';
import { CreditCardStyled } from './styled';
import { userService } from 'apiService';
import { toast } from 'react-toastify';

export const CreditCard = (props) => {
  const { children, invoiceID, amount, callback } = props;

  const handleClickPayment = () => {
    const { OmiseCard } = window;
    OmiseCard.configure({
      publicKey: process.env.REACT_APP_OMISE_PUBLIC_KEY,
    });
    OmiseCard.open({
      amount: Number(amount) * 100,
      defaultPaymentMethod: 'credit_card',
      submitFormTarget: '#checkout-form',
      onCreateTokenSuccess: (nonce) => {
        /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
        if (nonce) {
          handleSubmit(nonce);
        } else {
          toast.error('ชำระเงินไม่สำเร็จ กรุณาชำระภายหลัง');
        }
      },
      onFormClosed: () => {
        /* Handler on form closure. */
      },
    });
  };

  const handleSubmit = async (token) => {
    let params = {
      gateway: 'omise',
      type: 'credit_card',
      token: token,
    };
    let res = await userService.POST_PAYMENT_DETAIL(invoiceID, params);
    if (res && res.success) {
      toast.success('ชำระสำเร็จ');
      window.location.replace(res.data.authorize_uri);
      callback && callback();
    }
  };

  return (
    <CreditCardStyled>
      <div onClick={handleClickPayment}>{children}</div>
    </CreditCardStyled>
  );
};
