import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { ProfileContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { ProfileForm } from 'forms';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'helpers';
import { Modal } from 'components'; 
import { VideoContainer } from './Modal';
import { iconImages } from 'assets';
import moment from 'moment';
import { Helmet } from "react-helmet";

class ProfileContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
    this.fetchBooking();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();

    // Tracking user event
    ReactGA.event({
      category: 'myprofile',
      action: 'view',
      nonInteraction: true,
    });
  }

  fetchData = async () => {
    const res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: {
          ...res.data,
        },
      });
    }
  };

  fetchBooking = async () => {
    let res = await userService.GET_BOOKING_LIST();
    if (res && res.success) {
      this.setState({
        dataBookingList: res.data,
        dataBookingwatingList: res.data.filter((e) => e.status === 'NEW'),
        dataBookingRequireList: res.data.filter(
          (e) => e.status === 'REQUESTED'
        ),
        dataBookingProcessing: res.data.filter(
          (e) => e.status === 'PROCESSING' || e.status === 'PENDING'
        ),
        dataBookingAlreadyList: res.data.filter(
          (e) => e.status === 'COMPLETED'
        ),
        dataBookingRejectList: res.data.filter((e) => e.status === 'REJECTED'),
      });
    }
  };

  handleSubmit = async () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let params = {
        gifter: {
          displayname: values.gifter.displayname,
          about: values.gifter.about,
        },
        email: values.email,
      };
      let res = await userService.PATCH_PROFILE_UDATE_DETAIL(params);
      if (res && res.success) {
        this.fetchData();
        toast.success('แก้ไขสำเร็จ');
      } else {
        toast.error('แก้ไขไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่');
      }
    }
  };

  handleClickOrder = (id) => {
    this.props.history.push(`${ROUTE_PATH.BOOKING}/${id}`);
  };

  handleClickPreviewVideo = (e) => {
    this.setState({
      isModal: true,
      renderModal: <VideoContainer dataVideo={e} />,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  render() {
    const {
      initialValues,
      dataBookingList,
      dataBookingRequireList,
      dataBookingProcessing,
      dataBookingAlreadyList,
      dataBookingRejectList,
      dataBookingwatingList,
      isModal,
      renderModal,
    } = this.state;
    return (
      <ProfileContainerStyled>
        <Helmet>
          <title>profile</title>
        </Helmet>
        <ProfileForm
          initialValues={initialValues}
          handleSubmit={this.handleSubmit}
        />
        {dataBookingList && (
          <div className="myorder_layout">
            <div className="referral_count">
              คุณมีส่วนลดจากการแนะนำเพื่อนจำนวน{' '}
              {initialValues &&
                `${initialValues.gifter.referral_coupons.count} `}{' '}
              ครั้ง
            </div>
            <div className="booking_title">รอชำระเงิน</div>
            {dataBookingwatingList && dataBookingwatingList.length > 0 && (
              <div className="booking_container_layout">
                {dataBookingwatingList.map((e, i) => (
                  <div
                    key={i}
                    className="booking_item"
                    onClick={() => this.handleClickOrder(e.id)}
                  >
                    <div className="pic_layout">
                      <div>
                        <img
                          className="pic"
                          src={
                            e.creator.posters && e.creator.posters.length
                              ? e.creator.posters[0].uri
                              : iconImages['Loading.png']
                          }
                          alt="creator_pic"
                        />
                        {/* <div className="label">{e.creator.displayname}</div> */}
                        <div className="label">{e.category.name_th}</div>
                        <div className="label">
                          {moment(e.created_at).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="booking_title">รอการตอบรับ</div>
            {dataBookingRequireList && dataBookingRequireList.length > 0 && (
              <div className="booking_container_layout">
                {dataBookingRequireList.map((e, i) => (
                  <div
                    key={i}
                    className="booking_item"
                    onClick={() => this.handleClickOrder(e.id)}
                  >
                    <div className="pic_layout">
                      <div>
                        <img
                          className="pic"
                          src={
                            e.creator.posters && e.creator.posters.length
                              ? e.creator.posters[0].uri
                              : iconImages['Loading.png']
                          }
                          alt="creator_pic"
                        />
                        {/* <div className="label">{e.creator.displayname}</div> */}
                        <div className="label">{e.category.name_th}</div>
                        <div className="label">
                          {moment(e.created_at).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="booking_title">กำลังดำเนินการ</div>
            {dataBookingProcessing && dataBookingProcessing.length > 0 && (
              <div className="booking_container_layout">
                {dataBookingProcessing.map((e, i) => (
                  <div
                    key={i}
                    className="booking_item"
                    onClick={() => this.handleClickOrder(e.id)}
                  >
                    <div className="pic_layout">
                      <div>
                        <img
                          className="pic"
                          src={
                            e.creator.posters && e.creator.posters.length
                              ? e.creator.posters[0].uri
                              : iconImages['Loading.png']
                          }
                          alt="creator_pic"
                        />
                        {/* <div className="label">{e.creator.displayname}</div> */}
                        <div className="label">{e.category.name_th}</div>
                        <div className="label">
                          {moment(e.created_at).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="booking_title">คำขอที่ทำเสร็จแล้ว</div>
            {dataBookingAlreadyList && dataBookingAlreadyList.length > 0 ? (
              <div className="booking_container_layout">
                {dataBookingAlreadyList.map((e, i) => (
                  <div
                    key={i}
                    className="booking_item"
                    onClick={() => this.handleClickPreviewVideo(e)}
                  >
                    <div className="pic_layout">
                      <div>
                        {e.videos && e.videos.length && (
                          <img
                            className="pic"
                            src={e.videos[0].poster_jpg_uri}
                            alt="creator_pic"
                          />
                        )}
                        {/* <div className="label">{e.creator.displayname}</div> */}
                        <div className="label">{e.category.name_th}</div>
                        <div className="label">
                          {moment(e.created_at).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="label_warning">ไม่พบข้อมูล</div>
            )}
            <div className="booking_title">คำขอที่ถูกปฎิเสธ</div>
            {dataBookingRejectList && dataBookingRejectList.length > 0 && (
              <div className="booking_container_layout">
                {dataBookingRejectList.map((e, i) => (
                  <div
                    key={i}
                    className="booking_item"
                    onClick={() => this.handleClickOrder(e.id)}
                  >
                    <div className="pic_layout">
                      <div>
                        <img
                          className="pic"
                          src={
                            e.creator.posters && e.creator.posters.length
                              ? e.creator.posters[0].uri
                              : iconImages['Loading.png']
                          }
                          alt="creator_pic"
                        />
                        {/* <div className="label">{e.creator.displayname}</div> */}
                        <div className="label">{e.category.name_th}</div>
                        <div className="label">
                          {moment(e.created_at).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {renderModal}
        </Modal>
      </ProfileContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.ProfileForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
