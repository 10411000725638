import React, { useState, useEffect } from 'react';
import { CategoriesBarStyled } from './styled';
import { userService } from 'apiService';
export const CategoriesBar = (props) => {
  const [data, setData] = useState();

  const handleClickCategories = (slug) => {
    props.propsHistory.push(`/main/categories/${slug}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      let res = await userService.GET_CATEGORIES_LIST();
      if (res && res.data) {
        setData(res.data);
      }
    };
    fetchData();
  }, []);

  return (
    <CategoriesBarStyled>
      <div className="box_center">
        <div className="box_wrap">
          {data &&
            data.map((e, i) => (
              <div key={i} onClick={() => handleClickCategories(e.slug)}>
                <div className="button_menu">{e.label}</div>
              </div>
            ))}
        </div>
      </div>
    </CategoriesBarStyled>
  );
};
