import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { OrderSuccessFormStyled } from './styled';
// import { iconImages } from 'assets';
// import { VALIDATE } from 'helpers';
import { PaymentPopup } from 'widgets/PaymentsSystem';
import moment from 'moment';
import { InputTextareaLabel } from 'components';

class OrderSuccessForm extends React.Component {
  state = {};

  render() {
    const { data, handleClickPaymentSuccess, handleSubmit } = this.props;
    return (
      <OrderSuccessFormStyled>
        <h2 className="title">รายละเอียดการสั่งจอง</h2>
        <div className="descroption_container">
          <div className="text_group">
            <div className="text_label">ครีเอเตอร์</div>
            <div className="text_detail">{data.creator.displayname}</div>
          </div>
          <div className="text_group">
            <div className="text_label">ชื่อผู้รับ</div>
            <div className="text_detail">{data.giftee_name}</div>
          </div>
          <div className="text_group">
            <div className="text_label">ชื่อผู้สั่งซื้อ</div>
            <div className="text_detail">{data.gifter_name}</div>
          </div>
          <div className="text_group">
            <div className="text_label">รายละเอียดงาน</div>
            <div className="text_detail">
              <Field
                theme_standard
                name="brief"
                component={InputTextareaLabel}
                handleClickSuccess={handleSubmit}
              />
            </div>
          </div>
          <div className="text_group">
            <div className="text_label">ราคา</div>
            <div className="text_detail">
              {data.invoice.amount_coins.toLocaleString()}
            </div>
          </div>
          <div className="text_group">
            <div className="text_label">คำสั่งซื้อหมดอายุ</div>
            <div className="text_detail">
              {moment(data.created_at).add(7, 'days').format('DD/MM/YYYY')}
            </div>
          </div>
          <div className="text_group">
            <div className="text_label">จัดส่งไปยัง</div>
            <div className="text_detail">{data.contact_email}</div>
          </div>
        </div>
        <div className="button">
          {data.invoice.amount_coins !== '0.00' ? (
            (data.status === 'NEW' || data.status === 'REQUESTED') && (
              <div>
                <PaymentPopup
                  invoiceID={data && data.invoice.id}
                  amount={data && data.invoice.amount_coins}
                />
              </div>
            )
          ) : (
            <button onClick={handleClickPaymentSuccess}>ดำเนินการต่อ</button>
          )}

          <div className="warning_text">
            <div>
              *คำเตือน กรุณางดใช้คำหยาบและสิ่งที่ไม่เหมาะสม
              เพื่อหลีกเลี่ยงโอกาสการถูกปฏิเสธ
            </div>
            <div>
              คุณสามารถยกเลิกได้ก็ต่อเมื่อศิลปินดารายังไม่ได้ตอบรับงานเท่านั้น
            </div>
          </div>
        </div>
      </OrderSuccessFormStyled>
    );
  }
}

export default reduxForm({
  form: 'OrderSuccessForm', // a unique identifier for this form
  enableReinitialize: true,
})(OrderSuccessForm);
