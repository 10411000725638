import React from 'react';
import { BoxVideoImageStyled } from './styled';
import cx from 'classnames';

export const BoxVideoImage = ({
  theme_standard,
  theme_categories,
  index_key,
  src,
  txttop,
  txtlabel,
  classManual,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BoxVideoImageStyled src={src}>
      <div key={index_key} className={`${customClass} ${classManual}`}>
        {/* {!txttop && (
          <div className="group_text">
            <div className="name_layer">{name}</div>
            <div className="detail_layer">{detail}</div>
          </div>
        )} */}
        <img
          className="logo_play "
          src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSIjZmZmIj48cGF0aCBkPSJNMjQgNEMxMi45NSA0IDQgMTIuOTUgNCAyNHM4Ljk1IDIwIDIwIDIwIDIwLTguOTUgMjAtMjBTMzUuMDUgNCAyNCA0em0tNCAyOVYxNWwxMiA5LTEyIDl6Ii8+PC9zdmc+'
          alt="logo"
        />
        <div className=" back_logo"></div>
        {txttop && <div className="txt_top">{txttop}</div>}
        {txtlabel && <div className="txt_label">{txtlabel}</div>}
      </div>
    </BoxVideoImageStyled>
  );
};
