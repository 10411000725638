import styled from 'styled-components';
import { COLOR, SCREEN } from 'assets';

export const BoxImage3Styled = styled.div`
  /*===============================================
 Container 
===============================================*/
  height: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard_BoxImage3 {
    height: 98%;
      // height: 258px;
      // width: 172px;
      cursor: pointer;
      border-radius: 0.6rem;
      background: ${COLOR.YELLOW_3};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .img_viewmore {
        // width: ;
      }
      .txt_viewmore {
        padding-top: 10px;
        color: ${COLOR.BLUE_7};
        font-weight: bold;
      }
    }

    &:hover {
      transform: translateY(-0.0825rem);
    }
    &.dynamic {
      width: 100%;
      height: 0;
      padding-bottom: 150%;
      .name_layer {
        margin-top: 11px;
        font-size: 1.3em;
      }
      .detail_layer {
        font-size: 1em;
      }
    }
  }
  @media (max-width: 1024px) {
    .theme_standard_BoxImage3 {

    }
  }
  
  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard_BoxImage3 {
      height: 100%;
      width: 165px;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard_BoxImage3 {
      height: 100%;
      width: 165px;
    }
  }
`;
