import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const CategoriesBarStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
    .box_center{
        display: flex;
        justify-content: center;
        justify-items:center;
        margin-top: 10px;
        margin-bottom: 20px;
        .box_wrap {
            width: 70%;
            display: flex;
            justify-content: space-around;
            .button_menu{
                cursor: pointer;
                display: flex;
                flex-direction:column;
                text-align: center;
                justify-content: center;
                justify-items:center;
                background: #f1f1f1;
                width:120px;
                height:40px;
                border-radius:20px;
                color: ${COLOR.YELLOW_1};
                font-weight: 700;
                font-size: ${FONT.SIZE_8};
                &:hover {
                  background: #DCDCDC;
                  color: #0059b3;
                }
            }
        }
    }
  @media (max-width: ${SCREEN.TABLET}) {
    .box_center{
      .box_wrap {
        width: 100%;
        justify-content: space-between;
        .button_menu{
          font-size: ${FONT.SIZE_6};
          width:95px;
          height:40px;
          margin: 0 10px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .box_center{
      justify-content: flex-start;
      justify-items:flex-start;
      .box_wrap {
        width: 300%;
        .button_menu{
          font-size: ${FONT.SIZE_4};
          width:90px;
          height:40px;
        }
      }
    }
    
  }
`;
