import styled from "styled-components"
import {
    // COLOR,
    // FONT
} from 'assets'

export const AgencyProfileContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
  
`