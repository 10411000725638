import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { PrivacyPolicyContainerStyled } from './styled';
// import { userService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import {} from 'components';

class PrivacyPolicyContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.scrollToTop();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <PrivacyPolicyContainerStyled>
        <h1 className="title">Privacy Policy</h1>
        <p>Last Updated: July 2, 2020</p>
        <p>
          Welcome to <span className="text_highlight">meWOW</span>.
          We are a <span className="text_highlight">“NEW C-COMMERCE”</span>{' '}
          platform (“a new C” referred to “Celebrity & Creator”) that you can
          book and purchase <span className="text_highlight">meWOW</span>™ video
          from favorite celebrities and creators through our website (referred
          to as our “Site”). This Privacy Policy explains our practices
          concerning the personal data we collected from you, or that you
          provide to us.
        </p>
        <p>
          From time to time, <span className="text_highlight">meWOW</span> may
          revise this Privacy Policy. We will give you notice by posting the
          revised Privacy Policy on our Site and updating the “Last Updated and
          Effective” date at the top of this Privacy Policy. Any changes to this
          Privacy Policy will be effective immediately on posting the updated
          Privacy Policy. By continuing to use our Site, you agree to the
          revised Privacy Policy to the fullest extent permitted by applicable
          law.
        </p>
        <hr />
        <h2 className="h2_title">1. Scope of meWOW Privacy Policy</h2>
        <p>
          This Privacy Policy applies to all aspects of our Site as well as to
          information we may collect offline. This Site may link to or
          incorporate websites or content hosted and served by others over which
          we have no control, such as third party websites, online properties,
          platforms, social media, or systems, which are each governed by the
          privacy policy and business practices of the third party. You
          understand and agree that meWOW is not responsible for the security,
          privacy, or business practices of any third party.
        </p>
        <hr />
        <h2 className="h2_title">2. Information We Collect</h2>
        <p>
          meWOW collects information from you directly, automatically when you
          visit the Site, create an account, and upload content to the platform.
          This includes technical and behavioral information regarding your use
          of the Platform. Where appropriate, we will use this information for
          the purpose of serving personalized advertising and to tell you about
          new services and opportunities.
        </p>
        <h3>(a) Information You Provide</h3>
        <p>meWOW may collect personal information from you when you:</p>
        <ul className="group_text">
          <li>
            <h4>Create an account or login to the Site.</h4>
            <p>
              meWOW collects your name and email address and the other
              information that you provide, such as a telephone number, when you
              register or login, update, or add information to your account. We
              require you to create a username and password if you create an
              account. meWOW collects information relating to any third-party
              account that you use to login to or link to your meWOW account
              (such as your username, bio, and number of followers).
            </p>
          </li>
          <li>
            <h4>Make a purchase through the Site. </h4>
            <p>
              meWOW collects the information that you provide when requesting a
              meWOW Video, including your name and email address, any mobile
              phone, contact number (of you or the recipient) you choose to
              provide, the name of the recipient, and the details that you
              provide as part of your request. We also process payment
              information you provide when you pay for a purchase and collect
              information of the recipients that you provide to us to the extent
              necessary to provide the meWOW Video.
            </p>
          </li>
          <li>
            <h4>Participate in promotional activities. </h4>
            <p>
              meWOW collects the information that you provide when you enter a
              promotional activity (each, a “Promotion”) through our Site. Each
              Promotion has its own terms and conditions or official rules,
              which you should review as they may contain other requirements.
            </p>
          </li>
          <li>
            <h4>Provide Content to the Site. </h4>
            <p>
              We collect information when you submit content to us, such as a
              video, review, photo, email, text, social media post, or other
              communication (each, a “Submission”) and, if you are a Creator
              User, when you submit “Creator Content”. Each Submission, whether
              submitted to our Site directly or through a Social Media Platform,
              and all Talent Content, is subject to the applicable Terms of
              Service.
            </p>
          </li>
          <li>
            <h4>Use Social Media with the Site. </h4>
            <p>
              We collect information when you interact with our Site through
              social media platforms such as Facebook, Instagram, and Twitter
              (collectively, “Social Media Platforms”). Some of your disclosed
              information, such as your username and profile photo, may be
              publicly displayed.
            </p>
          </li>
          <li>
            <h4>Use the App as a Creator User. </h4>
            <p>
              When you use our App to become or as a Creator User, we collect,
              in addition to information provided when you create your account,
              your name, email address, phone number, and the social media
              usernames you provide, meWOW Videos prepared by you, and other
              information relating to our relationship with you, such as payment
              information.
            </p>
          </li>
          <li>
            <h4>Contact Customer Service through the Site. </h4>
            <p>
              meWOW collects the information that you provide in connection with
              providing customer service.
            </p>
          </li>
        </ul>
        <h3>(b) Information from Third Parties</h3>
        <p>
          We may also obtain information, such as contact details (for example,
          name and email address), country, IP address, purchase histories,
          marketing preferences, cookie information, hardware and software
          information and other technical information about you from third
          parties, such as analytics companies, Social Media Platforms, and
          other third party business partners.
        </p>
        <hr />
        <h2 className="h2_title">3. Cookies and Tracking Technologies</h2>
        <p>
          When you visit our Site, we and third-party advertising and analytics
          companies and other third party business partners may use cookies and
          other similar technologies (e.g. web beacons, flash cookies, etc.)
          that automatically collect certain information, measure and analyze
          how you use the platform. We use these technologies for a number of
          reasons, including to enhance your experience using the platform, save
          your preferences for future visits to our Site, keep you logged in
          between visits, and to provide you with improved services.
        </p>
        <p>The information we collect may include (but is not limited to):</p>
        <ul>
          <li>Date and time of your visit to our Site;</li>
          <li>Areas you visit within our Site;</li>
          <li>Links that you click on within our Site;</li>
          <li>
            Websites or advertisements you visit before or after visiting our
            Site;
          </li>
          <li>
            Terms you entered into a search engine that lead you to our Site;
          </li>
          <li>
            IP address, mobile device identifier, or other unique identifier and
            usage information for the device used to access our Site;
          </li>
          <li>Address or other information that we collect;</li>
          <li>
            Whether an email message we sent was opened and whether a link in
            the email message was clicked.
          </li>
        </ul>
        <hr />
        <h2 className="h2_title">4. How We Use Information</h2>
        <p>
          We may combine information that we have received from you with
          information from other sources to operate, tailor content,
          personalize, adjust, and improve our Site. We use your information:
        </p>
        <ul>
          <li>
            To fulfill your request for a meWOW Video, accept your payment,
            deliver your orders and process information you provide through our
            Site;
          </li>
          <li>
            To maintain your account, facilitate use of the Site, and
            communicate with you about your account and your use of the Site,{' '}
          </li>
          <li>
            To send you promotional email or text messages communications from
            meWOW (not from any third party) if you have opted in to receive
            them.
          </li>
          <li>
            To better understand your personal preferences and to enable us to
            provide you with improved and personalized communications and
            services;
          </li>
          <li>To compile aggregate data about Site traffic and interaction;</li>
          <li>
            To tailor the content and advertising we display to you or others,
            on our Site or elsewhere, and to analyze trends and statistics;
          </li>
          <li>
            To create advertising models through lookalike modelling or other
            research methodologies;
          </li>
          <li>
            To help us detect and combat abuse, harmful activity, fraud, spam
            and illegal activity on the Platform
          </li>
          <li>
            For other reasons that we disclose when you provide your
            information, with your consent, and as described in this Privacy
            Policy.
          </li>
        </ul>
        <hr />
        <h2 className="h2_title">5. How We Share Information</h2>
        <p>We share your data with the following selected third parties: </p>
        <h3>(a) Business Partners</h3>
        <p>
          If you choose to register to use the Platform using your social
          network account details (e.g. Facebook, Twitter, Instagram, Google),
          you will provide us or allow your social network to provide us with
          your username and public profile.
        </p>
        <h3>(b) Service Provider</h3>
        <p>
          We share information and content to service providers who support our
          business such as cloud service providers and providers of content
          moderation services to ensure that the Platform is a safe and fun.
        </p>
        <h3>(c) Analytics Provider</h3>
        <p>
          We use analytics provider to help us in the optimization and
          improvement of the platform. Our third party analytics providers also
          helps us serve targeted adverts.
        </p>
        <h3>(d) Advertisers and Advertising Network</h3>
        <p>
          We share information with advertisers and third party measurement
          companies to show how many and which users of the Platform have viewed
          or clicked on advertisement.
        </p>
        <h3>(e) Law Enforcement</h3>
        <p>
          We will share your information with law enforcement agencies, public
          authorities or other organizations if legally required to do so.
        </p>
        <h3>(f) Business Transfers</h3>
        <p>
          In evaluating or engaging in a sale of assets, merger, acquisition,
          reorganization, bankruptcy, or other transaction, we reserve the right
          to disclose, transfer, or assign your personal information and
          communications. By providing your personal information, you agree that
          we may transfer such information to the acquiring entity without your
          further consent.
        </p>
        <hr />
        <h2 className="h2_title">6. Children and Parents</h2>
        <p>
          Our Site is intended for a general audience and not for use by anyone
          younger than the age of 16. We do not knowingly collect personal
          information from children younger than the age of 16 without the
          consent of a parent or legal guardian; if we learn that we have done
          so, we will promptly remove the information from our active databases.
          If you are a parent or legal guardian who believes that we have
          collected or used their child’s personal information, please contact
          us using the contact details in the Contact Us section.
        </p>
        <hr />
        <h2 className="h2_title">7. Your Choices</h2>
        <p>
          You can access and edit most of your profile information by signing
          into meWOW. You can delete the User Content you uploaded. We also
          provide a number of tools in Settings that allow you to control, among
          others, who can view your videos. If you have any questions on how to
          use those tools, please contact us at privacy@mewow.app.{' '}
        </p>
        <hr />
        <h2 className="h2_title">
          8. Accessing Personal Information and Retention Period
        </h2>
        <h3>(a) Accessing Your Account</h3>
        <p>
          Please note that if you choose not to provide us with certain personal
          information, we may not always be able to provide you with the
          relevant meWOW product or service that you request. For example,
          without providing an email address, you will not be able to create a
          Site account.
        </p>
        <ul className="group_number_text">
          <li>
            You may access, view, correct, or update certain personal
            information collected about you by accessing your Site account
            through meWOW.app or the mobile application. If you want to
            deactivate your account, please email support@meWOW.app.
          </li>
          <li>
            If you have any concerns about how we are using your personal
            information, would like to correct or remove correct the personal
            information that you have provided to us, please send your request
            using the contact details in the Contact Us section.
          </li>
        </ul>
        <h3>(b) Retention Period</h3>
        <p>
          When you request that we delete your personal information, we will
          take commercially reasonable steps to remove it from our active
          databases but will keep original information consistent with our
          business practices to the extent permitted by applicable law (for
          example, for purposes of dispute resolution, enforcement of
          agreements, complying with legal requirements, recordkeeping, backup,
          and document retention).
        </p>
        <hr />
        <h2 className="h2_title">9. Data Security</h2>
        <p>
          meWOW takes commercially reasonable security measures to protect the
          information submitted to us. However, no method of transmission over
          the Internet, or method of electronic storage, is 100% secure and you
          use our Site and transmit information to us at your own risk. If you
          have any questions about security on our Site, contact us using the
          contact details in the Contact Us section at the end of this policy.
        </p>
        <hr />
        <h2 className="h2_title">10. Contact Us</h2>
        <ul>
          <li>
            To contact us about this Privacy Policy, you can send an email to us
            at <a href={'mailto:privacy@meWOW.app'}>privacy@meWOW.app</a>
          </li>
          <li>
            To contact us about our Site or other matters, you can contact us at{' '}
            <a href={'mailto:support@meWOW.app'}>support@meWOW.app</a>
          </li>
        </ul>
        <hr />
      </PrivacyPolicyContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicyContainer);
