import React from 'react';
import { connect } from 'react-redux';
import { RefundContainerStyled } from './styled';
import { adminService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { Helmet } from 'react-helmet';
// import { ROUTE_PATH } from 'helpers';
import { ReactTable } from 'components';

class RefundContainer extends React.Component {
  state = { data: [] };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_REFUND_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  handdleClickRefund = async (index, e) => {
    const { data } = this.state;
    let params = {
      status: 'success',
    };
    let res = await adminService.PATCH_REFUND_CREATOR_UPDATE(e, params);
    if (res && res.success) {
      let temp = data;
      temp[index].status = 'SUCCESS';
      this.setState({
        data: temp,
      });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <RefundContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="main_layout">
          <div className="button_layer">
            {/* <ButtonCustom
              theme_standard
              label="New Creator"
              onClick={this.handdleClickAdd}
            /> */}
          </div>
          <ReactTable
            columns={columns({ handdleClickRefund: this.handdleClickRefund })}
            data={data}
          />
        </div>
      </RefundContainerStyled>
    );
  }
}

const columns = ({ handdleClickRefund }) => [
  {
    Header: 'Invoice Id',
    accessor: 'invoice.id',
    style: { width: '70px' },
  },
  {
    Header: 'Method',
    accessor: 'invoice.receipt.method',
    style: { width: '100px' },
  },
  {
    Header: 'Amount',
    accessor: 'invoice.amount_coins',
    style: { width: '100px' },
  },
  {
    Header: 'Bank Swiftcode',
    accessor: 'bank_swiftcode',
    style: { width: '150px' },
  },
  {
    Header: 'Account No',
    accessor: 'account_no',
    style: { width: '100px' },
  },
  {
    Header: 'Account Name',
    accessor: 'account_name',
  },
  {
    Header: 'Phone',
    accessor: 'phone_no',
    style: { width: '100px' },
  },
  {
    Header: 'Status',
    accessor: 'status',
    style: { width: '100px' },
  },
  {
    Header: 'Detail',
    accessor: 'id',
    style: { width: '10px', textAlign: 'center' },
    Cell: ({ row: { index, original } }) =>
      original.status === 'REQUESTED' && (
        <button onClick={() => handdleClickRefund(index, original.id)}>
          Refund
        </button>
      ),
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundContainer);
