import React, { useRef, useEffect } from 'react';
import { BannerCategoriesStyled } from './styled';
// import { BASE_API } from '../../apiService/apiConfig';
export const BannerCategories = (props) => {
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.play();
  }, []);

  return (
    <BannerCategoriesStyled>
      <div className="banner_layout">
        <img src={props.data && props.data.poster_photo_uri} alt="logo" />
        <video
          ref={videoRef}
          src={(props.data && props.data.poster_video_uri) && props.data.poster_video_uri}
          loop
          muted="muted"
          playsInline
        />
        <div className="txt_top">{props.data && props.data.title}</div>
      </div>
    </BannerCategoriesStyled>
  );
};

// const data = [
//     {
//         title : 'World of Entertainer',
//         src : 'entertainer.png'
//     },
//     {
//         title : 'Underworld of Hiphop',
//         src : 'hiphop.png'
//     },
//     {
//         title : 'Heaven of Models',
//         src : 'model.png'
//     },
//     {
//         title : 'Field of Sport',
//         src : 'sport.png'
//     },
//     {
//         title : 'Hall of Music',
//         src : 'music.png'
//     },
// ]
