import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';
// import eiei from '../../assets/img/icon/Loading.png'
export const NewArrivalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .background-image {
    background-size: cover;
    background-position: center;
    border-radius: 0.6rem;
    position: relative;
    min-height: 344px;
  }
  .title_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
    .text_title {
      font-size: ${FONT.SIZE_20};
      color: ${COLOR.YELLOW_4};
      text-shadow: 2px 2px 2px ${COLOR.BLUE_6};
      text-align: center;
      padding: 20px;
      background: ${COLOR.WHITE_1};
    }
    .button_layout {
      padding: 20px;
    }
  }
  .hightlight_layer {
    display: flex;
    .custom {
      padding: 5px 0;
    }
    .carousel-container {
      .react-multi-carousel-item {
        width: auto !important;
        margin-right: 10px;
      }
    }
    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
      z-index: 1;
    }
    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
      z-index: 1;
    } 
    margin: 0 -5px;
    .box_wrap {
      padding: 5px 0;
      margin: 0 5px;
      height: 100%;
    }
    .box_booking_container_mobile {
      display: none;
    }
    .carousel_wrap {
      width: 100%;
    }
  }

  .box_booking_container {
    width: 100%;
    display: flex;
    overflow: auto;
    padding: 30px 0px;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #dcdcdc;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #708090;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: grey;
    }
  }
  .box_booking_container_mobile {
    display: none;
  }
  .carousel_wrap {
    width: 83%;
  }
  @media (max-width: 1024px) {
    .hightlight_layer {
      .carousel_wrap {
        width: 100%;
        .box_wrap {
          .background-image {
            min-height: 291px;
          }
        }
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .title_container {
      .text_title {
        font-size: ${FONT.SIZE_10};
        padding: 10px;
      }
      .button_layout {
        padding: 10px;
      }
    }
    .hightlight_layer {
      display: none;
    }
    .box_booking_container {
      display: none;
    }
    .box_booking_container_mobile {
      width: 100%;
      display: flex;
      overflow: auto;
      // padding: 30px 0px;
      ::-webkit-scrollbar {
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #dcdcdc;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #708090;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: grey;
      }
      .box_wrap {
        margin-right: 10px;
        .background-image {
          min-height: 215px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .title_container {
      .text_title {
        font-size: ${FONT.SIZE_10};
        padding: 10px;
      }
      .button_layout {
        padding: 10px;
      }
    }
    .hightlight_layer {
      display: none;
    }
    .box_booking_container {
      display: none;
    }
    .box_booking_container_mobile {
      width: 100%;
      display: flex;
      overflow: auto;
      // padding: 30px 0px;
      ::-webkit-scrollbar {
        height: 5px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #dcdcdc;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #708090;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: grey;
      }
      .box_wrap {
        margin-right: 10px;
        .background-image {
          min-height: 215px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
