import styled from 'styled-components';
import {
  // COLOR,
  FONT,
} from 'assets';
import { COLOR } from '../../assets/styledComponent/constant';

export const LoginFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
input.input {
  font-family: monospace !important;
}
  .title {
    width: 100%;
    font-size: ${FONT.SIZE_12};
    font-weight: 700;
    margin: 0 0 30px;
    line-height: 1.1;
    letter-spacing: 0.1px;
  }
  .btn_layer {
    padding-top: 9px;
    padding-bottom: 16px;
    .btnFacebook {
      width: 100%;
      padding: 10px 21px;
      font-family: unicaOne_regular;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: ${FONT.SIZE_7};
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 0.3s, border-color 0.3s;
      background-color: ${COLOR.BLUE_6};
      border: calc(0.06887vw + 0.67769px) solid ${COLOR.BLUE_6};
      border-radius: 4px;
      line-height: 1.5;
      -webkit-box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
      -moz-box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
      box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
      letter-spacing: 0.1px;
    }
    .fa-facebook {
      margin-right: 5px;
    }
  }
  .or_layer {
    margin: 10px 0;
    border-bottom: 1px solid #ddd;
    position: relative;
    display: flex;
    justify-content: center;
    line-height: 1.5;
    .or_span {
      position: absolute;
      top: -11px;
      background-color: #fff;
      padding: 0 15px;
      font-weight: 800;
      font-size: ${FONT.SIZE_3};
      letter-spacing: 0.1px;
    }
  }
  .form_layout {
    margin: 30px 0px;
    .group_input {
      text-align: left;
      margin-bottom: 20px;
      .label_name {
        margin: 10px 0;
        font-weight: 600;
        font-size: 20px;
        display: inline-block;
        line-height: 1.5;
        max-width: 100%;
      }
    }
    .button {
      background: transparent;
      font-family: spinnler, Courier, monospace;
      text-transform: uppercase;
      border: none;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
      transition: all 0.4s;
      font-size: ${FONT.SIZE_7};
      font-weight: 600;
      padding: 10px 21px;
      display: block;
      width: 100%;
      line-height: 1.5;
      border-radius: 3px;
      margin: 40px 0px 0px 0px;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      color: inherit;
      font-family: inherit;
    }
  }
  .bottom_layout {
    margin-bottom: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    font-size: ${FONT.SIZE_7};
    a {
      color: #337ab7;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .forget_layout {
    margin-top: 25px;
    text-align: center;
    letter-spacing: 0.1px;
    font-size: ${FONT.SIZE_7};
    line-height: 1.5;
    color: inherit;
    a {
      font-weight: 600;
      margin: 0 3px;
      text-decoration: none;
      font-family: inherit;
      color: inherit;
    }
    a:hover {
      text-decoration: underline;
    }
  }
`;
