import styled from "styled-components"
import { 
    // COLOR,
    // FONT
 } from 'assets'
 
export const EnrollContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/

`