import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const VoteStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .text_title_2 {
    display: flex;
    justify-content: center;
    background-size: contain;
    background-position: center;
    padding-top: 60px;
    margin: 10px 0;
    width: 100%;
    .group_text {
      padding: 20px;
      background: ${COLOR.WHITE_1};
      .text_layer_2 {
        font-size: ${FONT.SIZE_20};
        color: ${COLOR.YELLOW_4};
        text-shadow: 2px 2px 2px ${COLOR.BLUE_6};
        text-align: center;
        background: ${COLOR.WHITE_1};
      }
      .text_detail {
        padding-top: 10px;
        text-align: center;
      }
    }
  }
  .vote_form {
    width: 80%;
    margin: auto;
  }
  .group_vote {
    overflow-x: auto;
    display: flex;
    width: calc(100% - 60px);
    margin: 30px 30px;
    .box_wrap {
      flex-shrink: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .chart_layout {
    width: 80%;
    margin: auto;
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .group_vote {
      width: 100%;
      margin: 30px 0px;
      justify-content: unset;
    }
    .chart_layout {
      width: 100%;
    }
  }
`;
