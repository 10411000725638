import styled from 'styled-components';
import { FONT ,SCREEN } from 'assets';

export const NavbarStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
.navbar_layout{
  width: 100vw;
  height: 65px;
  /* background-color: #003399; */
  background-image: linear-gradient(to right, #003399 , #6699FF);
  border-bottom: 3px solid #FFCC00;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .txt_title{
    width:90%;
    font-weight: 700;
    font-size: ${FONT.SIZE_12};
    color: #fbc210;
    text-shadow: 2px 2px 2px #24478f;
    text-align: center;
  }
  .avatar{
    margin: 5px 20px 0 0 ;
    img{
      width:50px;
      border-radius:50%;
      border: 2px solid #FFCC00;
    }
  }
}

@media (max-width: ${SCREEN.TABLET}) {
  .navbar_layout{
    width: 100vw;
    height: 50px;
    background-image: linear-gradient(to right, #003399 , #6699FF);
    border-bottom: 3px solid #FFCC00;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .txt_title{
      width:90%;
      font-weight: 700;
      font-size: ${FONT.SIZE_5};
      margin-right: 10px;
      color: #fbc210;
      text-shadow: 2px 2px 2px #24478f;
      text-align: end;
    }
    .avatar{
      margin: 5px 20px 0 0 ;
      img{
        width:30px;
        border-radius:50%;
        border: 2px solid #FFCC00;
      }
    }
  }
}

@media (max-width: ${SCREEN.PHONE}) {
  .navbar_layout{
    .txt_title{
      font-size: ${FONT.SIZE_5};
      margin-right: 10px;
      text-align: end;
    }
    .avatar{
      img{
        width:30px;
      }
    }
  }
}
`;
