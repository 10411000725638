import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const NavbarWebStyled = styled.div`
  /*===============================================
 Container
===============================================*/

  /*===============================================
Theme
===============================================*/

.invite_friends {
  color: limegreen;
}
  width: 100%;
  position: fixed;
  z-index: 5;
  .web_menu_layout {
    background: ${COLOR.WHITE_1};
    border-top: 1px solid ${COLOR.GREY_2};
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .header_item {
      font-size: ${FONT.SIZE_7};
      cursor: pointer;
      position: relative;
      &:hover {
        color: ${COLOR.YELLOW_3};
        text-decoration: underline;
      }
      .search_layout_2 {
        margin-right: 10px;
        position: absolute;
        right: -12px;
        top: 38px;
      }
    }
    .group_avatar {
      display: flex;
      margin-left: 16%;
      align-items: center;
      .search_layout {
        margin-right: 10px;

      }
      .avatar {
        border: 1px solid ${COLOR.GREY_2};
        background: ${COLOR.GREY_26};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // margin-left: 16%;
        overflow: hidden;
        .person {
          height: 42px;
          width: 42px;
        }
        &:hover {
          border: 1px solid ${COLOR.YELLOW_3};
        }
      }
    }
    .logo_wrap {
      position: relative;
      height: 110px;
      width: 310px;
      cursor: pointer;
      .img_header {
        position: absolute;
        width: 100%;
      }
    }
  }
  .mobile_menu_layout {
    display: none;
    padding: 10px 15px;
    background: ${COLOR.WHITE_1};
    display: none;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .logo_wrap {
      position: relative;
      height: 60px;
      width: 180px;
      cursor: pointer;
      .img_header {
        position: absolute;
        width: 100%;
      }
    }
    .mb_right_layout {
      display: flex;
      align-items: center;
      .hamburger_wrap {
        margin-left: 10px;
        cursor: pointer;
      }
      .item {
        padding: 0.3rem;
        font-size: ${FONT.SIZE_7};
        cursor: pointer;
        &:hover {
          color: ${COLOR.YELLOW_3};
          text-decoration: underline;
        }
      }
      .group_avatar {
        display: flex;
        align-items: center;
        .avatar {
          margin-left: 5px;
          border: 1px solid ${COLOR.GREY_2};
          background: ${COLOR.GREY_26};
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          .person {
            height: 42px;
            width: 42px;
          }
          &:hover {
            border: 1px solid ${COLOR.YELLOW_3};
          }
        }
        .avatar_search {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          .search_icon {
            width: 28px;
            height: 28px;
          }
          .person {
            height: 42px;
            width: 42px;
          }
          &:hover {
            border: 1px solid ${COLOR.YELLOW_3};
          }
        }
      }
    }
  }
  .sideber_mobile {
    display: block;
    position: absolute;
    top: 80px;
    right: 2px;
    background: ${COLOR.WHITE_1};
    border: 1px solid ${COLOR.BLACK_1}33;
    border-radius: 5px;
    .header_mb_item {
      display: block;
      min-width: 200px;
      padding: 5px 10px;
      cursor: pointer;
      text-decoration: none;
      color: ${COLOR.BLACK_1};
      &:hover {
        color: ${COLOR.YELLOW_3};
      }
    }
  }
  .sideber_web {
    display: block;
    position: absolute;
    top: 97px;
    right: 2px;
    background: ${COLOR.WHITE_1};
    border: 1px solid ${COLOR.BLACK_1}33;
    border-radius: 5px;
    .header_mb_item {
      display: block;
      min-width: 200px;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        color: ${COLOR.YELLOW_3};
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .web_menu_layout {
      display: none;
    }
    .mobile_menu_layout {
      display: flex;
    }
  }
`;
