import styled from 'styled-components';
import { SCREEN } from 'assets';

export const LandingCreatorContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .content_mainlayout {
    padding-top: 131px;
    padding-bottom: 30px;
    padding-left: 7px;
    padding-right: 7px;
    .landing_layout {
      border: 2px solid #24478f;
      margin-left: 12%;
      margin-right: 7%;
      padding: 30px;
      border-radius: 9px;
      box-shadow: 3px 3px 5px 0px #9e9e9e;
      display: flex;
      justify-content: center;
      margin-top: 3%;
      max-width: 70%;
      .left_layout {
      }
      .right_layout {
        width: 46%;
        margin-left: 20px;
        .landing_btn {
          text-align: center;
          display: flex;
          .btn_twt {
            background: #1da1f2;
            padding: 12px;
            border: 0;
            color: white;
            font-weight: bold;
            cursor: pointer;
            width: 100%;
            border-radius: 10px;
            margin: 4px;
            .btn_lay {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 20px;
                height: auto;
                margin-right: 5px;
              }
            }
            &.face {
              background: #4267b2;
            }
            &.btn_dow {
              a {
                text-decoration: none;
              }
              background: #ffd11a;
              color: #0059b3;
            }
          }
        }
        .go_booking {
          margin-top: 35px;
          text-align: center;
          border: 2px solid #24478f;
          padding: 2%;
          border-radius: 10px;
          box-shadow: 3px 4px 5px 0px #9e9e9e;
          .img_wrap {
            text-align: center;
            .member_img {
              width: 50px;
              height: 50px;
              object-fit: cover;
              border-radius: 100%;
            }
          }
          .displayname {
            font-size: 30px;
            font-weight: bold;
            text-align: center;
          }
          .description {
            font-weight: bold;
            text-align: center;
          }
          .btn_book {
            cursor: pointer;
            font-size: 16px;
            color: #ffd11a;
            background: #0059b3;
            margin-top: 5px;
            padding: 10px 10px;
            border: 1px solid #d0d3cd;
            font-weight: 600;
            border-radius: 5px;
            width: 60%;
          }
        }
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .content_mainlayout {
      padding-top: 80px;
      .landing_layout {
        display: block;
        margin-left: 0;
        margin-right: 0;
        padding: 25px 10px;
        max-width: 100%;
        .left_layout {
          text-align: center;
        }
        .right_layout {
          margin: 0;
          width: 100%;
          .landing_btn {
            .btn_twt {
              width: 100%;
              .btn_lay {
                justify-content: center;
              }
            }
          }
          .go_booking {
            margin-top: 5%;
            padding-bottom: 20px;
            padding-top: 20px;
            border: unset;
            box-shadow: unset;
          }
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .content_mainlayout {
      .landing_layout {
        margin-left: 0;
        margin-right: 0;
        .right_layout {
          width: 100%;
          .landing_btn {
            .btn_twt {
              padding: 10px 5px;
              .btn_lay {
                flex-direction: column;
                img {
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
