import React, { useState, useEffect } from 'react';
import { InputSuggestCreatorStyled } from './styled';
import { adminService } from 'apiService';
import Autosuggest from 'react-autosuggest';
// import { Button } from 'components';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

export const InputSuggestCreator = ({ input, onChangeValue, meta: { touched, error } }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [_valueSelected, _setValueSelected] = useState('');

  useEffect(() => {
    if (_valueSelected) {
      onChangeValue && onChangeValue(_valueSelected);
      input.onChange(_valueSelected);
    }
  }, [input, _valueSelected, onChangeValue]);

  const onChange = (event, { newValue }) => {
    _setValueSelected(null);
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    let res = await adminService.GET_CREATOR_SEARCH_LIST(
      value === '' ? undefined : value
    );
    if (res && res.success) {
      setSuggestions(res.data);
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'search creator',
    value,
    id: 'inputtext',
    onChange: onChange,
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    _setValueSelected(suggestion);
    setValue(suggestion.displayname);
  };

  // const shouldRenderSuggestions = () => {
  //   return true;
  // };

  return (
    <InputSuggestCreatorStyled>
      <div className="input_layout">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={(suggestion) => (
            <div>{suggestion.displayname}</div>
          )}
          // shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
        />
        {touched && error && <div className="error_wrap">{error}</div>}
      </div>
    </InputSuggestCreatorStyled>
  );
};
