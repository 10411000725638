import styled from "styled-components"
import {
    // COLOR,
    // FONT
} from 'assets'

export const CreatorServicesContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .button_layout {
        text-align: right;
        padding-top: 10px;
    }
    .div_right {
        text-align: right;
    }
`