import React from 'react';
import { BoxImage3Styled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export const BoxImage3 = ({
  theme_standard_BoxImage3,
  index_key,
  src,
  classManual,
}) => {
  const customClass = cx({
    theme_standard_BoxImage3: theme_standard_BoxImage3,
  });
  return (
    <LazyLoadComponent>
      <BoxImage3Styled src={src}>
        <div key={index_key} className={`${customClass} ${classManual}`}>
          <div className="viewmore_block">
            <img className="img_viewmore" src={iconImages['logo2_web.png']} alt="View More" />
            <div className="txt_viewmore">ดูศิลปินต่อทั้งหมด</div>
          </div>
        </div>
      </BoxImage3Styled>
    </LazyLoadComponent>
  );
};
