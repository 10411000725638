import React from 'react';
import { WaitingPaymentStyled } from './styled';

export const WaitingPayment = (props) => {
  const { textStatus, textDetail } = props;
  return (
    <WaitingPaymentStyled>
      <div className="title_container">
        <p>{textStatus}</p>
        <p>{textDetail}</p>
      </div>
    </WaitingPaymentStyled>
  );
};
