import React from 'react';
import { HowItworkStyled } from './styled';
import { iconImages } from 'assets';
import { BoxImage2 } from 'components';

export const HowItwork = (props) => {
  return (
    <HowItworkStyled>
      <div className="title">HOW IT WORKS ?</div>
      <div className="howtobuy_title">วิธีการสั่งซื้อวิดีโอเซอร์ไพรส์ จากศิลปินดารา</div>
      <div className="box_image_layout_2">
        {locatePic2.map((e, i) => (
          <div key={i} className="box_wrap">
            <BoxImage2
              theme_standard
              index_key={i}
              src={iconImages[e.src]}
              icon_pic={iconImages[e.icon_pic]}
              text1={e.text1}
              text2={e.text2}
              text3={e.text3}
            />
          </div>
        ))}
      </div>
    </HowItworkStyled>
  );
};

const locatePic2 = [
  {
    src: '',
    text1: '1',
    text2: 'ส่งคำขอ',
    text3:' ',
    icon_pic: 'sendfile.png',
    
  },
  {
    src: '',
    text1: '2',
    text2: 'รอการตอบรับ',
    text3:' ',
    icon_pic: 'moblie.png',
  },
  {
    src: '',
    text1: '3',
    text2: 'รับวิดีโอ',
    text3:'',
    icon_pic: 'like.png',
  },
];
