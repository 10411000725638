import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { ReferalContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { userService } from 'apiService';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'helpers';
import { ShraeSocial } from 'widgets';

class ReferalContainer extends React.Component {
  state = {};

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();

    this.fetchData();
  }

  fetchData = async () => {
    const { authRedux } = this.props;
    let res = await userService.GET_REFERAL_DETAIL(
      authRedux.gifter.referral_code
    );
    if (res && res.success) {
      this.setState({
        data: res.data,
        dataRef: `${window.location.origin}${ROUTE_PATH.SIGN_UP}/${res.data.referral_code}`,
      });
    }
  };

  handleClickCopy = () => {
    const el = this.textArea;
    el.select();
    document.execCommand('copy');
    toast.success('copy ลิงค์เรียบร้อย');
    el.blur();
    // Tracking user event
    ReactGA.event({
      category: 'referral',
      action: 'view_link',
    });
  };

  render() {
    const { dataRef } = this.state;
    return (
      <ReferalContainerStyled>
        <div className="Referal_display">
          <div className="Referal_layout">
            <h1 className="title">{`แชร์ลิ้งค์ของคุณเพื่อรับส่วนลด 5%`}</h1>
            <div className="pp">
              <div>
                อยากได้ส่วนลดสำหรับวิดีโอต่อไปของคุณใช่ไหม?
                <p>
                  แนะนำ <span className="span_wow">WOW</span>ให้เพื่อนของคุณสิ
                </p>
                <p>{`เราจะมอบส่วนลด 5% ให้กับคุณและเพื่อน`}</p>
                <p>เมื่อเพื่อนของคุณลงทะเบียนกับเรา </p>
              </div>
            </div>
            <div className="copy">
              <input
                ref={(textarea) => (this.textArea = textarea)}
                name="referral"
                defaultValue={dataRef}
              />
              <div className="btn_getcode share">
                <ShraeSocial url={dataRef}>แชร์ส่วนลด</ShraeSocial>
              </div>
              <div className="btn_getcode" onClick={this.handleClickCopy}>
                รับลิงก์ส่วนลด
              </div>
            </div>
          </div>
        </div>
      </ReferalContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferalContainer);
