import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { OrderContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { RecommendHappyNext } from 'components';
import { OrderForm } from 'forms';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'helpers';

class OrderContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
    this.fetchAuth();
    this.fetchCategories();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await userService.GET_CREATOR_DETAIL(this.props.match.params.id);
    if (res && res.success) {
      this.setState({
        dataCreator: res.data,
      });
    }
  };

  fetchAuth = () => {
    const { authRedux } = this.props;

    this.setState({
      initialValues: {
        is_public: true,
        giftee_type: 'ME',
        giftee_name: authRedux.gifter.displayname,
        gifter_name: authRedux.gifter.displayname,
      },
    });
  };

  fetchCategories = async () => {
    let res = await userService.GET_CATEDGORY_GROUP_LIST_SERVICE(
      this.props.match.params.id,
      this.props.match.params.service
    );
    if (res && res.success) {
      this.setState({
        serviceSelected: res.data,
        categoryOptions: res.data.category_groups,
      });
      this.fetchCoupon('auto', res.data.rate_coins);
    }
  };

  fetchCoupon = async (type, current_rate_coins) => {
    let res = await userService.GET_COUPON_CAL_DETAIL(type, current_rate_coins);
    if (res && res.success && res.data) {
      this.setState({
        dataCoupon: res.data,
      });
      toast.success('ใช้รหัสส่วนลด');
    } else {
      if (type !== 'auto') {
        toast.error('ไม่มีรหัสส่วนลดนี้ในระบบ');
        this.props.setCouponField('');
      }
    }
  };

  handleClickUseCoupon = () => {
    const { serviceSelected } = this.state;
    const {
      reduxForm: {
        values: { coupon },
      },
    } = this.props;
    if (coupon) {
      this.fetchCoupon(coupon, serviceSelected.rate_coins);
    }
  };

  handleClickRequest = async () => {
    const { dataCoupon } = this.state;
    const {
      reduxForm: { values, syncErrors },
      match: {
        params: { id, service },
      },
    } = this.props;
    let params = {
      creator_id: id,
      ...values,
      service_id: service,
      is_private: values.is_public ? false : true,
    };

    if (dataCoupon) {
      params.coupon_code = dataCoupon.code;
      delete params.coupon;
    }
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      this.setState({
        disabledSubmit: true,
      });
      let res = await userService.POST_ORDERS_DETAIL(params);
      if (res && res.success) {
        // Tracking user event
        ReactGA.event({
          category: 'booking',
          action: 'submit_success',
        });

        toast.success('ซื้อสำเร็จ');
        this.props.history.push(`${ROUTE_PATH.ORDER_SUCCESS}/${res.data.id}`);
      } else {
        // Tracking user event
        ReactGA.event({
          category: 'booking',
          action: 'submit_fail',
        });
        toast.error('ซื้อไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่');
      }
    }
  };

  render() {
    const {
      categoryOptions,
      initialValues,
      dataCreator,
      dataCoupon,
      disabledSubmit,
      serviceSelected,
    } = this.state;
    return (
      <OrderContainerStyled>
        <div className="left_layout">
          <OrderForm
            initialValues={initialValues}
            disabledSubmit={disabledSubmit}
            dataCreator={dataCreator}
            dataCoupon={dataCoupon}
            categoryOptions={categoryOptions}
            serviceSelected={serviceSelected}
            handleClickUseCoupon={this.handleClickUseCoupon}
            handleClickRequest={this.handleClickRequest}
          />
        </div>
        <div className="right_layout">
          <div className="advice">
            <div className="inside_advice">
              <div>
                <h3>คำแนะนำ</h3>
                <div className="text_detail">
                  <span className="text_hightlight">
                    กรุณาระบุรายละเอียดที่ชัดที่สุด เท่าที่จะเป็นไปได้
                  </span>{' '}
                  ไม่ว่าจะเป็นชื่อ,เพศผู้รับ,การออกเสียง และอื่นๆ
                  <span className="span_advice">
                    <div>
                      เช่น รบกวนบอกเตย(ออกเสียงเหมือนใบเตย)(ผู้ชาย) ให้หน่อยค่ะ
                      ว่ากิ๊ฟคิดถึงมากๆ มาพาไปกินขนมได้แล้ว
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <RecommendHappyNext
            theme_standard
            creatorName={dataCreator && dataCreator.displayname}
          />
        </div>
      </OrderContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.OrderForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setCouponField: (data) => dispatch(change('OrderForm', 'coupon', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
