import styled from 'styled-components';
import { SCREEN } from 'assets';

export const SignUpContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  border: 1px solid #d0d3cd;
  border-radius: 5px;
  padding: 15px;
  margin: 5%;
  .logo_wrap {
    margin: auto;
    text-align: center;
    img {
      width: 290px;
    }
  }
  .container_layout {
    display: flex;
    justify-content: center;
    align-items: baseline;
    .left_layout {
      max-width: 300px;
      font-size: 20px;
      padding: 0 30px;
      color: black;
      font-weight: normal;
      .gifter_referral {
        color: #ffd11a;
        text-shadow: 1px 1px 0px #24478f;
        font-size: 28px;
        padding-right: 9px;
      }
      .money_referral {
        color: #ffd11a;
        text-shadow: 1px 1px 0px #24478f;
        font-size: 23px;
        padding: 0 9px;
      }
      .size_wow_ref {
        color: #ffd11a;
        text-shadow: 1px 1px 0px #24478f;
        font-size: 34px;
      }
      .size_me_ref {
        margin-left: 9px;
        color: #ffd11a;
        text-shadow: 1px 1px 0px #24478f;
        font-size: 23px;
      }
    }
    .right_layout {
      width: 57%;
      text-align: center;
      h1 {
        color: #ffd11a;
        text-shadow: 2px 2px 0px #24478f;
        font-size: 45px;
        margin-top: 5px;
      }
      .size_wow {
        font-size: 62px;
      }
      .fa-facebook {
        margin-right: 5px;
      }
      .btnFacebook {
        width: 100%;
        padding: 10px 21px;
        font-family: unicaOne_regular;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 18px;
        -webkit-text-decoration: none;
        text-decoration: none;
        text-transform: uppercase;
        -webkit-transition: background-color 0.3s, border-color 0.3s;
        transition: background-color 0.3s, border-color 0.3s;
        background-color: #24478f;
        border: calc(0.06887vw + 0.67769px) solid #24478f;
        border-radius: 4px;
        line-height: 1.5;
        -webkit-box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
        -moz-box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
        box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
        -webkit-letter-spacing: 0.1px;
        -moz-letter-spacing: 0.1px;
        -ms-letter-spacing: 0.1px;
        letter-spacing: 0.1px;
      }
      .btn_spacing {
        padding-top: 20px;
        .btn_email {
          width: 100%;
          padding: 10px 21px;
          font-family: unicaOne_regular;
          font-weight: 700;
          -webkit-font-smoothing: antialiased;
          color: #0059b3;
          cursor: pointer;
          display: inline-block;
          font-size: 18px;
          -webkit-text-decoration: none;
          text-decoration: none;
          text-transform: uppercase;
          -webkit-transition: background-color 0.3s, border-color 0.3s;
          transition: background-color 0.3s, border-color 0.3s;
          background-color: #ffd11a;
          border: calc(0.06887vw + 0.67769px) solid #ffd11a;
          border-radius: 4px;
          line-height: 1.5;
          -webkit-box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
          -moz-box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
          box-shadow: 0px 1px 3px 1px rgba(209, 201, 209, 1);
          -webkit-letter-spacing: 0.1px;
          -moz-letter-spacing: 0.1px;
          -ms-letter-spacing: 0.1px;
          letter-spacing: 0.1px;
        }
      }
      .link_wrap {
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .logo_wrap {
      margin-bottom: 15px;
      img {
        width: 200px;
      }
    }
    .container_layout {
      flex-direction: column;
      .left_layout {
        margin: 0px;
        padding: 0;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 100%;
        margin-bottom: 15px;
      }
      .right_layout {
        width: 100%;
      }
    }
  }
`;
