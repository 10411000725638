import React from 'react';
import { InputDropdownLineStyled } from './styled';
import cx from 'classnames';

export const InputDropdownLine = (props) => {
  const {
    theme_standard,
    input,
    children,
    meta: { touched, error },
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <InputDropdownLineStyled>
      <div className={customClass}>
        <select {...input}>
          <option value="">กรุณาเลือกข้อมูล</option>
          {children}
        </select>
        {touched && error && <div className="error_wrap">{error}</div>}
      </div>
    </InputDropdownLineStyled>
  );
};
