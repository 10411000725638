import React, { useRef, useState } from 'react';
import { InputUploadFIleStyled } from './styled';
import cx from 'classnames';
import { ButtonCustom } from 'components';

export const InputUploadFIle = ({
  theme_standard,
  label,
  buttonLabel,
  input,
  disabled,
  width,
  showOnly,
  placeholder,
  title,
  haveFilePhoto,
  haveFileVideo,
  accept,
  meta: { touched, error },
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const inputEl = useRef(null);
  const [nameUpload, setNameUpload] = useState();

  const _onChange = (e) => {
    if (e.target.files.length > 0) {
      setNameUpload(e.target.files[0].name);
      input.onChange(e.target.files[0]);
    }
  };
  return (
    <InputUploadFIleStyled isInvalid={touched && error} width={width}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {showOnly ? (
          input.value
        ) : (
          <>
            <div className="upload_btn_location">
              {nameUpload ? (
                <div className="label_upload">{nameUpload}</div>
              ) : (
                input.value &&(haveFilePhoto || haveFileVideo) ? (
                  <>
                    <div>{title}:</div>
                    <a className="label_link" target="_blank" rel="noopener noreferrer" href={input.value}>
                      link
                    </a>
                  </>
                ):(
                  <>
                    <div className="label_link_not">N/A</div>
                  </>
                )
              )}
              <ButtonCustom
                type="button"
                label={buttonLabel}
                onClick={() => inputEl.current.click()}
              />
              <input
                type="file"
                ref={inputEl}
                hidden
                disabled={disabled}
                placeholder={placeholder}
                onChange={_onChange}
                accept={accept}
              />
              {touched && error && <div className="error">{error}</div>}
            </div>
          </>
        )}
      </div>
    </InputUploadFIleStyled>
  );
};
 