import styled from 'styled-components';
import {
  COLOR,
  // FONT
  SCREEN,
} from 'assets';

export const OrderContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  padding: 0 15px;
  max-width: 1100px;
  margin: auto;
  .left_layout {
    width: 100%;
    padding-right: 10px;
    *::placeholder {
      color: #d0d3d4 !important;
    }

  }
  .right_layout {
    width: 580px;
    padding-left: 10px;
  }

  .advice {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
    .inside_advice {
      display: inline-block;
      border-radius: 16px;
      padding: 15px;
      border: 2px solid #0059b3;
      background-color: rgb(240, 248, 255, 0.1);
      line-height: 2;
      h3{
        margin-top: -5px;
      }
      .text_detail{
        margin-top: -10px;
        .text_hightlight {
          color: ${COLOR.GREEN_6};
        }
      }
    }
  }
}
  @media (max-width: ${SCREEN.TABLET}) {
    flex-direction: column;
    .left_layout {
      width: 100%;
      padding: 0;
    }
    .right_layout {
      width: 100%;
      padding: 0;
    }
    .inside_advice {
      h3{
        margin-top: -5px;
      }
      display: inline-block;
      border-radius: 16px;
      padding: 15px;
      border: 4px solid #0059b3;
      background-color: rgb(240, 248, 255, 0.1);
      line-height: 1.8;
      width: 100%;
    }
  }
`;
