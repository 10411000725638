import React, { useRef, useState } from 'react';
import { InputUploadFIleBase64Styled } from './styled';
import cx from 'classnames';
import { ButtonCustom } from 'components';

export const InputUploadFIleBase64 = ({
  theme_standard,
  label,
  input,
  disabled,
  width,
  showOnly,
  placeholder,
  labelButton,
  meta: { touched, error },
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const inputEl = useRef(null);
  const [nameUpload, setNameUpload] = useState();

  const _onChange = (e) => {
    if (e.target.files.length > 0) {
      setNameUpload(e.target.files[0].name);
      getBase64(e.target.files[0]);
    }
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      input.onChange(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <InputUploadFIleBase64Styled isInvalid={touched && error} width={width}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {showOnly ? (
          input.value
        ) : (
            <>
              <div className="upload_btn_location">
                {nameUpload && <div className="label_upload">{nameUpload}</div>}
                <ButtonCustom
                  type="button"
                  label={labelButton ? labelButton : `"Upload your picture profile"`}
                  onClick={() => inputEl.current.click()}
                />
                <input
                  type="file"
                  ref={inputEl}
                  // {...input}
                  hidden
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={_onChange}
                  accept="image/x-png,image/gif,image/jpeg"
                />
                {touched && error && <div className="error">{error}</div>}
              </div>
            </>
          )}
      </div>
    </InputUploadFIleBase64Styled>
  );
};
