import { ROUTE_PATH } from 'helpers';
import HomeContainer from 'containers/HomeContainer';
import CreatorContainer from 'containers/CreatorContainer';
import OrderContainer from 'containers/OrderSystem/OrderContainer';
import OrderSuccessContainer from 'containers/OrderSystem/OrderSuccessContainer';
import ProfileContainer from 'containers/ProfileContainer';
import BookingDetailContainer from 'containers/BookingSystem/BookingDetailContainer';
import ReferalContainer from 'containers/ReferalContainer';
import HighLigtContainer from 'containers/HighLigtContainer';
import WheelsContainer from 'containers/WheelsContainer';
import NewArrivalContainer from 'containers/NewArrivalContainer';
import ExploreContainer from 'containers/ExploreContainer';
import UpComingContainer from 'containers/UpComingContainer';
import CategoriesContainer from 'containers/CategoriesContainer';
import FollowingContainer from 'containers/FollowingContainer';
import PaymentProcessContainer from 'containers/PaymentSystem/PaymentProcessContainer';
import CreatorRegisterContainer from 'containers/CreatorRegisterContainer';
import SearchContainer from 'containers/SearchContainer';

const pageRoutes = [
  {
    keyIndex: 1,
    path: ROUTE_PATH.HOME,
    exact: true,
    name: 'login',
    icon: 'login',
    component: HomeContainer,
  },
  {
    keyIndex: 2,
    path: ROUTE_PATH.CREATOR_DETAIL,
    exact: true,
    name: 'profile',
    icon: 'profile',
    component: CreatorContainer,
  },
  {
    keyIndex: 3,
    path: ROUTE_PATH.ORDER_CREATOR_DETAIL,
    exact: true,
    name: 'order',
    icon: 'order',
    component: OrderContainer,
  },
  {
    keyIndex: 4,
    path: ROUTE_PATH.ORDER_SUCCESS_DETAIL,
    exact: true,
    name: 'orderSuccess',
    icon: 'orderSuccess',
    component: OrderSuccessContainer,
  },
  {
    keyIndex: 5,
    path: ROUTE_PATH.PROFILE,
    exact: true,
    name: 'profile',
    icon: 'profile',
    component: ProfileContainer,
  },
  {
    keyIndex: 6,
    path: ROUTE_PATH.BOOKING_DETAIL,
    exact: true,
    name: 'booking detail',
    icon: 'booking detail',
    component: BookingDetailContainer,
  },
  {
    keyIndex: 7,
    path: ROUTE_PATH.REFERAL,
    exact: true,
    name: 'referal',
    icon: 'referal',
    component: ReferalContainer,
  },
  {
    keyIndex: 8,
    path: ROUTE_PATH.FOLLOWING,
    exact: true,
    name: 'following',
    icon: 'following',
    component: FollowingContainer,
  },
  {
    keyIndex: 9,
    path: ROUTE_PATH.PAYMENT_PROCESS,
    exact: true,
    name: 'following',
    icon: 'following',
    component: PaymentProcessContainer,
  },
  {
    keyIndex: 10,
    path: ROUTE_PATH.HIGH_LIGHT,
    exact: true,
    name: 'hight light',
    icon: 'hight light',
    component: HighLigtContainer,
  },
  {
    keyIndex: 11,
    path: ROUTE_PATH.UPCOMING,
    exact: true,
    name: 'up coming',
    icon: 'up coming',
    component: UpComingContainer,
  },
  {
    keyIndex: 12,
    path: ROUTE_PATH.EXPLORE,
    exact: true,
    name: 'explore',
    icon: 'explore',
    component: ExploreContainer,
  },
  {
    keyIndex: 13,
    path: ROUTE_PATH.CATEGORIES,
    exact: true,
    name: 'categories',
    icon: 'categories',
    component: CategoriesContainer,
  },
  {
    keyIndex: 14,
    path: ROUTE_PATH.CREATOR_REGISTER,
    exact: true,
    name: 'creator_register',
    icon: 'creator_register',
    component: CreatorRegisterContainer,
  },
  {
    keyIndex: 15,
    path: ROUTE_PATH.CREATOR_SEARCH_DETAIL,
    exact: true,
    name: 'creator_register',
    icon: 'creator_register',
    component: SearchContainer,
  },
  {
    keyIndex: 16,
    path: ROUTE_PATH.NEW_ARRIVAL,
    exact: true,
    name: 'new arrival',
    icon: 'new arrival',
    component: NewArrivalContainer,
  },
  {
    keyIndex: 17,
    path: ROUTE_PATH.WHEELS_DETAIL,
    exact: true,
    name: 'wheels',
    icon: 'wheels',
    component: WheelsContainer,
  },
];
export default pageRoutes;
