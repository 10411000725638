import styled from 'styled-components';
import { COLOR } from 'assets';

export const WaitingPaymentStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  min-height: calc(100vh - 356px);
  display: flex;
  align-items: center;
  justify-content: center;
  .title_container {
    padding: 65px 50px;
    background: ${COLOR.YELLOW_1};
    color: ${COLOR.WHITE_1};
    text-align: center;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  }
`;
