import styled from 'styled-components';
import {  FONT, SCREEN } from 'assets';
export const PopupRegisterStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 80;
    }
    .box{
        width: 400px;
        height: 250px;
        background: #000066;
        border-radius: 40px;
        border: 3px solid #FFCC00;
        .header_layer_right {
            margin-left: 360px;
            margin-top: -25px;
            width: 50px;
            height: 50px;
            background: #7f7e7e;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 5px solid white;
            cursor: pointer;
            img {
                width: 24px;
                height: 24px;
            }
        }
        .txt_title{
            color: #FFCC00;
            font-size: ${FONT.SIZE_14};
            font-weight: 700;
            text-align: center;
        }
        .txt_detail1{
            color: white;
            font-size: ${FONT.SIZE_8};
            font-weight: 500;
            text-align: center;
            margin-top: 22px;
            line-height:1px;
        }
        .txt_detail2{
            color: white;
            font-size: ${FONT.SIZE_6};
            text-align: center;
            margin-top: 22px;
        }
        .img_box{
            display: flex;
            justify-content: center;
            margin-top: 5px;
            img{
                object-fit: cover;
                width: 100px;
                height:100px;
                object-position: 0 0;
            }
        }
    }
    @media (max-width: ${SCREEN.PHONE}) {
        .box{
            width: 280px;
            height: 250px;
            .txt_title{
                color: #FFCC00;
                font-size: ${FONT.SIZE_14};
                font-weight: 700;
                text-align: center;
            }
            .txt_detail2{
                color: white;
                font-size: ${FONT.SIZE_4};
                text-align: center;
                margin-top: 22px;
            }
            .txt_detail1{
                color: white;
                font-size: ${FONT.SIZE_6};
                font-weight: 500;
                text-align: center;
                margin-top: 22px;
                line-height:1px;
            }
            .header_layer_right {
                margin-left: 240px;
                margin-top: -14px;
                width: 40px;
                height: 40px;
               
            }
        }
    }
`;
