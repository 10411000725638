import React from 'react';
import { connect } from 'react-redux';
import { SearchContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import queryString from 'query-string';
import { ROUTE_PATH } from 'helpers';
import Helmet from 'react-helmet';
import { BoxImage, InputSearchName } from 'components';

class SearchContainer extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const { keyword } = queryString.parse(nextProps.location.search);
    if (keyword !== prevState.keyword) {
      return { keyword: keyword };
    } else return null;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { keyword } = queryString.parse(prevProps.location.search);
    if (keyword !== this.state.keyword) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { keyword } = this.state;
    if (keyword) {
      let res = await userService.GET_CREATOR_SEARCH_LIST(keyword);
      if (res && res.success) {
        this.setState({
          dataCreatorList: res.data,
        });
      }
    }
  };

  fetchDataMobile = async () => {
    const { keywordMobile } = this.state;
    if (keywordMobile) {
      let res = await userService.GET_CREATOR_SEARCH_LIST(keywordMobile);
      if (res && res.success) {
        this.setState({
          dataCreatorList: res.data,
        });
      }
    }
  };

  handleClickCreatorDetail = (id) => {
    this.props.history.push(`${ROUTE_PATH.CREATOR}/${id}`);
  };

  handleEnter = (e) => {
    this.setState(
      {
        keywordMobile: e,
      },
      () => {
        this.fetchDataMobile();
      }
    );
  };

  render() {
    const { dataCreatorList } = this.state;
    return (
      <SearchContainerStyled>
        {dataCreatorList && (
          <Helmet>
            <title>search result</title>
          </Helmet>
        )}
        <div className="text_title">Search Result</div>
        <div className="input_search_wrap">
          <InputSearchName
            theme_mobile_standard
            placeHolder="Search"
            handleEnter={this.handleEnter}
          />
        </div>
        {dataCreatorList && (
          <>
            {dataCreatorList.length === 0 ? (
              <div className="no_result_container">
                <p>หาไม่พบ น้องขอโทษษษษ :(</p>
                <p>ตอนนี้ยังไม่มี แต่น้องจะพยายามน้า</p>
                <p>เพื่อให้เรารู้ว่า</p>
                <p>คุณอยากได้ใคร สามารถ Vote ชื่อได้ที่นี่นะคะ</p>
              </div>
            ) : (
              <div className="container">
                {dataCreatorList &&
                  dataCreatorList.map((e, i) => (
                    <div
                      key={i}
                      className="item_wrap"
                      onClick={() => this.handleClickCreatorDetail(e.id)}
                    >
                      <BoxImage
                        theme_standard
                        classManual="dynamic"
                        name={e.displayname}
                        detail={e.description}
                        src={
                          (e.posters &&
                            e.posters.length > 0 &&
                            e.posters[0].uri) ||
                          iconImages['Loading.png']
                        }
                      />
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </SearchContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
