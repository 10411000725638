import styled from 'styled-components';
import { COLOR } from 'assets';

export const PaypalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background: ${COLOR.WHITE_1};
  width: 100%;
  .pay_btn {
    cursor: pointer;
    font-size: 16px;
    background: #ffd11a;
    color: #0059b3;
    padding: 10px 15px;
    width: 100%;
    border: 1px solid #d0d3cd;
    font-weight: 600;
    border-radius: 5px;
  }
`;
