import styled from 'styled-components';
import { COLOR, SCREEN } from 'assets';

export const ReactTableExpandStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid ${COLOR.GREY_2};
    width: 100%;
    tr {
      td {
        font-size: 14px;
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${COLOR.GREY_2};
      border-right: 1px solid ${COLOR.GREY_2};

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
    text-align: center;
  }

  @media (max-width: ${SCREEN.PHONE}) {
    font-size: 13px;
  }
`;
