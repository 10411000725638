import styled from 'styled-components';
import { SCREEN } from 'assets';

export const PageNormalMainLayoutStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .content_mainlayout {

  }
  @media (max-width: ${SCREEN.TABLET}) {
  
  }
`;
