import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { reducer as formReducer } from 'redux-form';
import { USER_AUTH } from '../actions/actionTypes';

const auth = (state = false, action) => {
  switch (action.type) {
    case USER_AUTH:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  form: formReducer,
  auth
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer);
let persistor = persistStore(store);

export { store, persistor };
