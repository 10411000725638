import styled from 'styled-components';
import { SCREEN } from 'assets';

export const CmsMainlayoutStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  
  .right_mainlayout_layout {
    width: 100%;
    .navbar_layout {
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .body_layout {
      margin-left:110px;
      margin-top: 65px;
      font-family: prompt_light;
      overflow: auto;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .right_mainlayout_layout {
      .body_layout {
        margin-left:60px;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .right_mainlayout_layout {
      .body_layout {
        margin-left: 0px;
      }
    }
  }
  
`;
