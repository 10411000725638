import React from 'react';
import { connect } from 'react-redux';
import { AgencyDetailContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { ROUTE_PATH } from 'helpers';
import {
  AgentContainer,
  AgencyProfileContainer,
  CreatorContainer,
} from '../SectionsAgencyDetail';

class AgencyDetailContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    if (this.props.match.params.id !== 'create') {
      this.setState({
        agencyID: this.props.match.params.id,
      });
    }
  };

  callback = (e) => {
    this.props.history.push(ROUTE_PATH.A_AGENCY + '/' + e);
    this.setState({
      agencyID: e,
    });
  };

  render() {
    const { agencyID } = this.state;
    return (
      <AgencyDetailContainerStyled>
        <div className="form_item_layout">
          <AgencyProfileContainer
            agencyID={
              this.props.match.params.id === 'create'
                ? undefined
                : this.props.match.params.id
            }
            callback={this.callback}
            propsHistory={this.props.history}
          />
        </div>
        {agencyID && (
          <>
            <div className="form_item_layout">
              <AgentContainer agencyID={agencyID} />
            </div>
            <div className="form_item_layout last">
              <CreatorContainer agencyID={agencyID} />
            </div>
          </>
        )}
      </AgencyDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CreatorProfileFormSec1,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyDetailContainer);
