import styled from 'styled-components';
import // COLOR,
// FONT
'assets';

export const CreatorDetailContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  margin: 20px 15px;
  .form_item_layout {
    max-width: 900px;
    margin: auto;
    margin-bottom: 30px;
    &.last {
      margin-bottom: 0px;
    }
  }
`;
