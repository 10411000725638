import React from 'react';
import { ReactTableExpandStyled } from './styled';
import { useTable, useExpanded, usePagination } from 'react-table';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

export const ReactTableExpand = ({
  columns: userColumns,
  data,
  renderRowSubComponent,
}) => {
  let location = useLocation();
  let history = useHistory();
  const { page: pageUrl = 1 } = queryString.parse(location.search);
  const pathname = location.pathname;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    visibleColumns,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    page,
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: { pageIndex: Number(pageUrl) - 1 || 0, pageSize: 10 },
    },
    useExpanded, // We can useExpanded to track the expanded state
    // for sub components too!
    usePagination
  );

  return (
    <ReactTableExpandStyled>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                <th {...column.getHeaderProps({ style: { ...column.style } })}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              // Use a React.Fragment here so the table markup is still valid
              <React.Fragment {...row.getRowProps()}>
                <tr>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <br />
      {/* <div>Showing the first 20 results of {rows.length} rows</div> */}
      <div className="pagination">
        <button
          onClick={() => {
            history.push(`${pathname}?page=1`);
            gotoPage(0);
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>{' '}
        <button
          onClick={() => {
            history.push(`${pathname}?page=${Number(pageUrl) - 1}`);
            previousPage();
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          onClick={() => {
            // console.log('pathname', pathname);
            history.push(`${pathname}?page=${Number(pageUrl) + 1}`);
            nextPage();
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        <button
          onClick={() => {
            history.push(`${pathname}?page=${pageCount - 1}`);
            gotoPage(pageCount - 1);
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              history.push(`${pathname}?page=${page}`);
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            history.push(`${pathname}?page=${Number(e.target.value)}`);
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </ReactTableExpandStyled>
  );
};
