import React from 'react';
import { BookingNoStyled } from './styled';
import { iconImages } from 'assets';

export const BookingNo = (props) => {
  return (
    <BookingNoStyled>
      <div
        className="title_container"
        style={{  backgroundImage: `url(${iconImages['bg1.png']})` }}
      >
        <div className="text_title">Booking Now</div>
      </div>
    </BookingNoStyled>
  );
};
