import styled from 'styled-components';
import { SCREEN } from 'assets';

export const ProfileFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .info_container {
    border: 2px solid #24478f;
    border-radius: 5px;
    padding: 20px 15px;
    .row_col {
      display: flex;
    }
    .left_col {
      width: 150px;
      flex-shrink: 0;
    }
    .right_col {
    }
  }
  .row_col {
    padding: 5px;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    input {
      max-width: 70%;
    }

    .right_col {
      font-size: 12px;
    }
  }
`;
