import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BannerCategoriesStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  height: 360px;
  width:100%;
  color: black;
  font-size: 60px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* border-radius: 36px; */
  .banner_layout{
    display: flex;
    direction: row;
    img {
        object-fit: cover;
        width: 40%;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }
    video {
        object-fit: cover;
        object-position: 0 0;
        width: 60%;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }
    .txt_top {
        position: absolute;
        z-index:99;
        margin: 250px 0 0 2%;
        font-size: ${FONT.SIZE_13};
        color: ${COLOR.WHITE_1};
        font-weight: 600;
        text-shadow: 2px 2px 2px #24478f;
    }
  }
  @media(max-width: ${SCREEN.TABLET} ){
    height: 190px;
    .banner_layout{
      /* img {
        width: 68%;
      }
      video {
        width: 32%;
      } */
      .txt_top {
        margin-top: 125px ;
        font-size: ${FONT.SIZE_6};
      }
    }
  }
  @media(max-width: ${SCREEN.PHONE}){
    height: 190px;
    .banner_layout{
      img {
        width: 68%;
      }
      video {
        width: 32%;
      }
      .txt_top {
        margin-top: 140px ;
        font-size: ${FONT.SIZE_6};
      }
    }
  }
`;
