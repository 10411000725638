import styled from 'styled-components';
// import { COLOR, FONT } from 'assets';
import { SCREEN } from 'assets';

export const BookingDetailContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .title {
    text-align: center;
  }
  .order_detail_layout {
    border: 2px solid #24478f;
    padding: 15px;
    max-width: 900px;
    border-radius: 5px;
    margin: auto;
    .topic_layout {
      display: flex;
      margin: 4px;
      .label {
        font-weight: bold;
        width: 170px;
        flex-shrink: 0;
      }
      .text_right {
        display: unset !important;
        padding-left: 10px;
      }
    }
    .btn_pay_profile_layout {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      .btn_wrap {
        width: 210px;
        margin-right: 10px;
      }
      .btn_cancel {
        cursor: pointer;
        font-size: 16px;
        color: #ffd11a;
        background: #0059b3;
        padding: 10px 49px;
        border: 1px solid #d0d3cd;
        font-weight: 600;
        border-radius: 5px;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .order_detail_layout {
      .btn_pay_profile_layout {
        flex-direction: column;
        .btn_wrap {
          width: 100%;
          margin-bottom: 5px;
          margin-right: 0px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .order_detail_layout {
      .topic_layout {
        font-size: 12px;
        .label {
          font-weight: bold;
          width: 150px;
        }
        .text_right {
          font-size: 12px !important;
        }
      }
      .btn_pay_profile_layout {
        .btn_cancel {
          padding: 11px 58px !important;
        }
      }
    }
  }
`;
