import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { CreatorProfileFormSec1Styled } from './styled';
// import { iconImages } from 'assets'
import { VALIDATE } from 'helpers';
import {
  Input,
  InputTextareaNormal,
  ButtonCustom,
  InputCheckbox,
  InputSuggestUser,
} from 'components';

class CreatorProfileFormSec1 extends React.Component {
  state = {};

  render() {
    const { handdleSave, handleSubmit } = this.props;
    return (
      <CreatorProfileFormSec1Styled>
        <form onSubmit={handleSubmit(handdleSave)}>
          <fieldset>
            <legend>Creator Profile</legend>
            <div className="grop_form">
              <div className="box_label">
                <div className="label">Name</div>
                <Field
                  theme_standard_2
                  name="displayname"
                  placeholder="Enter Name"
                  component={Input}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="box_label">
                <div className="label">Description</div>
                <Field
                  theme_standard_2
                  name="description"
                  placeholder="Enter Description"
                  component={Input}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="box_label">
                <div className="label">About</div>
                <Field
                  theme_standard
                  name="about"
                  placeholder="Enter About"
                  component={InputTextareaNormal}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              {/* <div className="box_label">
                <div className="label">Rate</div>
                <Field
                  theme_standard_2
                  name="current_rate_coins"
                  placeholder="Enter Rate"
                  component={Input}
                  validate={VALIDATE.NUMBER}
                />
              </div> */}
              <div className="box_label">
                <div className="label">User</div>
                <Field
                  theme_standard
                  name="user_id"
                  label="Highlight"
                  component={InputSuggestUser}
                />
              </div>
              <div className="box_label">
                <div className="label">Option</div>
                <div className="item_checkout">
                  <Field
                    theme_standard
                    name="is_highlight"
                    label="Highlight"
                    component={InputCheckbox}
                  />
                </div>
                <div className="item_checkout">
                  <Field
                    theme_standard
                    name="is_upcoming"
                    label="Upcoming"
                    component={InputCheckbox}
                  />
                </div>
                <div className="item_checkout">
                  <Field
                    theme_standard
                    name="is_active"
                    label="Active"
                    value={true}
                    component={InputCheckbox}
                  />
                </div>
                <div className="item_checkout">
                  <Field
                    theme_standard
                    name="is_newarrival"
                    label="New Arrival"
                    value={true}
                    component={InputCheckbox}
                  />
                </div>
                <div className="item_checkout">
                  <Field
                    theme_standard
                    name="is_explore"
                    label="Explore"
                    value={true}
                    component={InputCheckbox}
                  />
                </div>
              </div> 
              <div className="button_layer">
                <ButtonCustom theme_standard label="SAVE" />
              </div>
            </div>
          </fieldset>
        </form>
      </CreatorProfileFormSec1Styled>
    );
  }
}

export default reduxForm({
  form: 'CreatorProfileFormSec1', // a unique identifier for this form
  enableReinitialize: true,
})(CreatorProfileFormSec1);
