import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const NotificationStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .Toastify__toast {
    border-radius: 15px;
    border: 1px solid ${COLOR.WHITE_1};
    font-family: 'prompt_light';
    font-size: ${FONT.SIZE_3};
    padding: 0 13px;
    .Toastify__close-button.Toastify__close-button--error {
      display: none;
    }
    .Toastify__close-button.Toastify__close-button--success {
      display: none;
    }
    .Toastify__close-button.Toastify__close-button--info {
      display: none;
    }

    &.Toastify__toast--error {
      background: ${COLOR.RED_1};
      color: ${COLOR.WHITE_1};
      &:after {
        position: absolute;
        font-family: 'prompt_regular';
        content: 'x';
        font-size: 20px;
        right: 13px;
        top: 0px;
      }
    }
    &.Toastify__toast--success {
      background: ${COLOR.YELLOW_4};
      color: ${COLOR.WHITE_1};
      &:after {
        position: absolute;
        font-family: 'prompt_regular';
        content: 'x';
        font-size: 20px;
        right: 13px;
        top: 0px;
      }
    }
    &.Toastify__toast--info {
      &:after {
        position: absolute;
        font-family: 'prompt_regular';
        content: 'x';
        font-size: 20px;
        right: 13px;
        top: 0px;
      }
    }
  }
`;
