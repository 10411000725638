import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { BookingDetailContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'helpers';
import { Helmet } from 'react-helmet';
import { BookingForm } from 'forms';

class BookingDetailContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();

    // Tracking user event
    ReactGA.event({
      category: 'booking',
      action: 'view',
      nonInteraction: true,
      value: parseInt(this.props.match.params.id),
    });
  }

  fetchData = async () => {
    let res = await userService.GET_BOOKING_DETAIL(this.props.match.params.id);
    if (res && res.success) {
      // console.log('res', res);
      this.renderTitle(res.data.status);
      this.setState({
        orderDetail: res.data,
        initialValues: {
          brief: res.data.brief,
        },
      });
    }
  };

  renderTitle = (key) => {
    switch (key) {
      case 'NEW':
        this.setState({
          title: 'รอชำระเงิน',
        });
        break;
      case 'REQUESTED':
        this.setState({
          title: 'รอการตอบรับ',
        });
        break;
      case 'PENDING':
        this.setState({
          title: 'กำลังดำเนินการ',
        });
        break;
      case 'PROCESSING':
        this.setState({
          title: 'กำลังดำเนินการ',
        });
        break;
      case 'COMPLETED':
        this.setState({
          title: 'คำขอที่ทำเสร็จแล้ว',
        });
        break;
      case 'REJECTED':
        this.setState({
          title: 'คำขอที่ถูกปฎิเสธ',
        });
        break;
      default:
        break;
    }
  };

  handleClickCancelOrder = async () => {
    let res = await userService.DELETE_BOOKING_DETAIL(
      this.props.match.params.id
    );
    if (res && res.success) {
      this.props.history.push(ROUTE_PATH.PROFILE);
      toast.success('ลบการจองสำเร็จ');
    }
  };

  handleSubmit = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    // console.log('value', values);
    let params = { ...values };
    let res = await userService.PATCH_BRIEF_DETAIL(
      this.props.match.params.id,
      params
    );
    if (res && res.success) {
      toast.success('แก้ไขสำเร็จ');
    }
  };

  render() {
    const { title, orderDetail, initialValues } = this.state;
    return (
      <BookingDetailContainerStyled>
        <Helmet>
          <title>booking</title>
        </Helmet>
        <h2 className="title">{title}</h2>
        <BookingForm
          initialValues={initialValues}
          orderDetail={orderDetail}
          orderID={this.props.match.params.id}
          handleSubmit={this.handleSubmit}
        />
        {/* <div className="order_detail_layout">
          {orderDetail && (
            <>
              <div className="topic_layout">
                <div className="label">ครีเอเตอร์</div>
                <div className="text_right">
                  {orderDetail.creator.displayname}
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">ชื่อผู้รับ</div>
                <div className="text_right">{orderDetail.giftee_name}</div>
              </div>
              <div className="topic_layout">
                <div className="label">ชื่อผู้สั่งซื้อ</div>
                <div className="text_right">
                  {orderDetail.gifter.displayname}
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">รายละเอียดงาน</div>
                <div className="text_right">{orderDetail.brief}</div>
              </div>
              <div className="topic_layout">
                <div className="label">ราคา</div>
                <div className="text_right">
                  {orderDetail.invoice.amount_coins.toLocaleString()} บาท
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">คำสั่งซื้อหมดอายุ</div>
                <div className="text_right">
                  {moment(orderDetail.created_at)
                    .add(7, 'days')
                    .format('DD/MM/YYYY')}
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">จัดส่งไปยัง</div>
                <div className="text_right">
                  {orderDetail.order && orderDetail.contact_email}
                </div>
              </div>
              {orderDetail.status === 'NEW' && (
                <>
                  <div className="btn_pay_profile_layout">
                    <div className="btn_wrap">
                      <PaymentPopup
                        invoiceID={orderDetail && orderDetail.invoice.id}
                        amount={orderDetail && orderDetail.invoice.amount_coins}
                      />
                    </div>
                    <button
                      className="btn_cancel"
                      onClick={this.handleClickCancelOrder}
                    >
                      ยกเลิกคำสั่งจอง
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {orderDetail && orderDetail.status === 'REJECTED' && (
          <RefundContainer orderID={this.props.match.params.id} />
        )} */}
      </BookingDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.BookingForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingDetailContainer);
