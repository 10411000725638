import React from 'react';
import { InputDropdownStyled } from './styled';
import cx from 'classnames';

export const InputDropdown = (props) => {
  const { theme_standard, options, input } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <InputDropdownStyled>
      <div className={customClass}>
        <select {...input}>
          <option value="">กรุณาเลือกข้อมูล</option>
          {options &&
            options.map((e, i) => (
              <option key={i} className="option_wrap" value={e.value}>
                {e.label}
              </option>
            ))}
        </select>
      </div>
    </InputDropdownStyled>
  );
};
