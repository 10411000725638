import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const InputTextareaNormalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    textarea {
      width: calc(100% - 16px);
      border: 1.1px solid;
      border-radius: 5px;
      padding: 8px;
      min-height: 100px;
    }
    .error {
      padding-top: 5px;
      color: ${COLOR.RED_1};
      font-size: ${FONT.SIZE_1};
    }
  }
`;
