import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { SignUpContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import FacebookLogin from 'react-facebook-login';
import { ROUTE_PATH } from 'helpers';
import { iconImages } from 'assets';
import { SignUpForm } from 'forms';
import { toast } from 'react-toastify';

class SignUpContainer extends React.Component {
  state = {
    isSignUpWithEmail: false,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();

    this.handleReferral();
  }

  handleReferral = async () => {
    if (this.props.match.params.ref) {
      let res = await userService.GET_REFERAL_DETAIL(
        this.props.match.params.ref
      );
      if (res && res.success) {
        this.setState({
          dataReferralDetail: res.data,
        });
      }
    }
  };

  responseFacebook = async (e) => {
    const { dataReferralDetail } = this.state;
    let params = {
      provider: 'facebook',
      access_token: e.accessToken,
    };
    if (dataReferralDetail) {
      params.referral_code = dataReferralDetail.referral_code;
    }
    const res = await userService.POST_LOG_IN(params);
    if (res && res.success) {
      localStorage.setItem('wowToken', res.token);
      sessionStorage.setItem('wowLogin', true);
      this.fetchProfile();
    }
  };

  fetchProfile = async () => {
    const res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      if (res.data.gifter) {
        ReactGA.set({
          userId: res.data.id,
          gender: res.data.gender,
        });
        this.props.setReduxUserAuth(res.data);
        this.props.history.push('/');
      }
    }
  };

  handleClickLogin = () => {
    this.props.history.push(ROUTE_PATH.LOGIN);
  };

  handleClickPrivacy = () => {
    this.props.history.push(ROUTE_PATH.PRIVACY);
  };

  handleClickTermOfService = () => {
    this.props.history.push(ROUTE_PATH.TERMS);
  };

  //======================================
  //======================================
  //SIGN UP EMAIL PRECESS
  handleClickSignUpWithEmail = () => {
    this.setState((state) => ({
      isSignUpWithEmail: !state.isSignUpWithEmail,
    }));
  };

  handleSubmitWithEmail = async () => {
    const { dataReferralDetail } = this.state;
    const {
      reduxForm: { values, syncErrors },
    } = this.props;
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let params = {
        ...values,
        provider: 'local',
      };
      if (dataReferralDetail) {
        params.referral_code = dataReferralDetail.referral_code;
      }
      let res = await userService.POST_PROFILE_REGISTER(params);
      if (res && res.success) {
        toast.success('สมัครสำเร็จ');
        this.props.history.push(ROUTE_PATH.LOGIN);
      } else {
        toast.error('สมัครไม่สำเร็จ email อาจมีการใช้ซ้ำ');
      }
    }
  };

  render() {
    const { dataReferralDetail } = this.state;
    return (
      <SignUpContainerStyled>
        <div className="logo_wrap">
          <img src={iconImages['logo.png']} alt="logo mewow" />
        </div>
        <div className="container_layout">
          {dataReferralDetail && (
            <div className="left_layout">
              <span className="gifter_referral">
                {dataReferralDetail.displayname}
              </span>
              มอบส่วนลด
              <span className="money_referral">
                {dataReferralDetail.referral_rate}
              </span>
              ลงทะเบียน เพื่อรับส่วนลด
              <span className="money_referral">
                {dataReferralDetail.referral_rate}
              </span>
              บาท สำหรับวิดีโอแรกของคุณใน
              <span className="size_me_ref">me</span>
              <span className="size_wow_ref">WoW</span>
            </div>
          )}
          <div className="right_layout">
            <h1>
              Welcome to me<span className="size_wow">WoW</span>
            </h1>
            <h4>Book personalized video shoutouts from your favorite people</h4>
            <FacebookLogin
              appId={process.env.REACT_APP_FB_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={this.responseFacebook}
              cssClass="btnFacebook"
              redirectUri={process.env.REACT_APP_SELF_ENDPOINT}
              disableMobileRedirect={true}
              icon="fa-facebook"
              textButton="Continue with Facebook"
            />
            <div className="btn_spacing">
              <button
                className="btn_email"
                onClick={this.handleClickSignUpWithEmail}
              >
                SIGN UP WITH EMAIL
              </button>
            </div>
            <h4>
              By signing up, you agree to MeWow's{' '}
              <span
                className="link_wrap"
                onClick={this.handleClickTermOfService}
              >
                Term of Service
              </span>{' '}
              and{' '}
              <span className="link_wrap" onClick={this.handleClickPrivacy}>
                Privacy Policy
              </span>
            </h4>
            <h4>
              Have an account?{' '}
              <span className="link_wrap" onClick={this.handleClickLogin}>
                Log In
              </span>
            </h4>
            {this.state.isSignUpWithEmail && (
              <div className="signup_box">
                <SignUpForm handleSubmitWithEmail={this.handleSubmitWithEmail} />
              </div>
            )}
          </div>
        </div>
      </SignUpContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.SignUpForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
