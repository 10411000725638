import React from 'react';
import { ReactTableStyled } from './styled';
import { useTable, usePagination } from 'react-table';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

export const ReactTable = (props) => {
  const { columns, data = [], notUsePagi, noPadding } = props;
  let location = useLocation();
  let history = useHistory();
  const { page: pageUrl = 1 } = queryString.parse(location.search);
  const pathname = location.pathname;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: Number(pageUrl) - 1 || 0, pageSize: 10 },
    },
    usePagination
  );

  return (
    <ReactTableStyled noPadding={noPadding}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps({ style: { ...column.style } })}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      {!notUsePagi && (
        <div className="pagination">
          <button
            onClick={() => {
              history.push(`${pathname}?page=1`);
              gotoPage(0);
            }}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>{' '}
          <button
            onClick={() => {
              history.push(`${pathname}?page=${Number(pageUrl) - 1}`);
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>{' '}
          <button
            onClick={() => {
              // console.log('pathname', pathname);
              history.push(`${pathname}?page=${Number(pageUrl) + 1}`);
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {'>'}
          </button>{' '}
          <button
            onClick={() => {
              history.push(`${pathname}?page=${pageCount - 1}`);
              gotoPage(pageCount - 1);
            }}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                history.push(`${pathname}?page=${page}`);
                gotoPage(page);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={(e) => {
              history.push(`${pathname}?page=${Number(e.target.value)}`);
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </ReactTableStyled>
  );
};
