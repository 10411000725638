import styled from 'styled-components';
import {
  // COLOR,
  // FONT,
} from 'assets';

export const InputRadioStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    label {
      padding-left: 5px;
    }
  }
`;
