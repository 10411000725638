import styled from 'styled-components';
import {
  // COLOR,
  SCREEN,
  FONT,
} from 'assets';

export const CreatorFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
    .title {
        width: 100%;
        font-size: ${FONT.SIZE_12};
        font-weight: 700;
        margin: 20px 0 30px;
        line-height: 1.1;
        letter-spacing: 0.1px;
    }
    .title1 {
        width: 100%;
        font-size: ${FONT.SIZE_6};
        font-weight: 500;
        margin: 20px 0 30px ;
        line-height: 0.4;
        letter-spacing: 0.1px;
    }
    .form_layout {
        margin: 30px 0px;
        .title3{
          font-size: ${FONT.SIZE_10};
          font-weight: 700;
        }
        .distance{
          margin-left: 10%;
        }
        .group_input {
          text-align: left;
          margin-bottom: 20px;
          width: 50%;
        .label_name {
            margin: 10px 0;
            font-weight: 600;
            font-size: 20px;
            display: inline-block;
            line-height: 1.5;
            max-width: 100%;
        }
      }
    }
    .flex_input{
        display: flex;
    }
    .button {
      background:  #ffd11a;
      font-family: spinnler, Courier, monospace;
      text-transform: uppercase;
      border: none;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
      transition: all 0.4s;
      font-size: ${FONT.SIZE_7};
      font-weight: 600;
      padding: 10px 21px;
      display: block;
      width: 100%;
      line-height: 1.5;
      border-radius: 3px;
      margin: 40px 0px 0px 0px;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      color: #0059b3;
      font-family: inherit;
    }
  }
  .bottom_layout {
    margin-bottom: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    font-size: ${FONT.SIZE_7};
    a {
      color: #337ab7;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .forget_layout {
    margin-top: 25px;
    text-align: center;
    letter-spacing: 0.1px;
    font-size: ${FONT.SIZE_7};
    line-height: 1.5;
    color: inherit;
    a {
      font-weight: 600;
      margin: 0 3px;
      text-decoration: none;
      font-family: inherit;
      color: inherit;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .title {
        font-size: ${FONT.SIZE_8};
        margin: 20px 0 10px 3%;
    }
    .title1 {
        width: 90%;
        font-size: ${FONT.SIZE_3};
        line-height: 2;
        letter-spacing: 0px;
        margin: 10px 0 0 3%;
    }
    .form_layout {
        margin: 10px 10px;
        .flex_input{
          display: flex;
          flex-direction: column;
        }
        .title3{
          font-size: ${FONT.SIZE_7};
        }
        .distance{
          margin-left: 0;
        }
        .group_input {
          text-align: left;
          margin-bottom: 20px;
          width: 100% ;
          .label_name {
              margin: 10px 0;
              font-weight: 600;
              font-size: ${FONT.SIZE_3};
              display: inline-block;
              line-height: 1.5;
              max-width: 100%;
          }
        }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .title {
        font-size: ${FONT.SIZE_8};
        margin: 20px 0 10px 3%;
    }
    .title1 {
        width: 90%;
        font-size: ${FONT.SIZE_3};
        line-height: 2;
        letter-spacing: 0px;
        margin: 10px 0 0 3%;
    }
    .form_layout {
        margin: 10px 10px;
        .flex_input{
          display: flex;
          flex-direction: column;
        }
        .title3{
          font-size: ${FONT.SIZE_7};
        }
        .distance{
          margin-left: 0;
        }
        .group_input {
          text-align: left;
          margin-bottom: 20px;
          width: 100% ;
          .label_name {
              margin: 10px 0;
              font-weight: 600;
              font-size: ${FONT.SIZE_3};
              display: inline-block;
              line-height: 1.5;
              max-width: 100%;
          }
        }
    }
  }
`;
