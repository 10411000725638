import styled from 'styled-components';
import // COLOR,
// FONT
'assets';

export const InputCheckboxStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
  }
`;
