import React from 'react';
import { connect } from 'react-redux';
import { AgentDetailContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { adminService } from 'apiService';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'helpers';
import { AgentProfileForm } from 'forms/AdminFormSystem';

class AgentDetailContainer extends React.Component {
  state = { agentID: this.props.match.params.id !== 'create' ? this.props.match.params.id : 'create' };

  componentDidMount() {
    if (this.props.match.params.id !== 'create') {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { agentID } = this.state;

    let res = await adminService.GET_AGENT_DETAIL(agentID);
    // console.log("ssssssssssssssss ", res)
    if (res && res.success) {
      this.setState({
        data: res.data,
        initialValues: {
          ...res.data,
          user_id: res.data.user ? String(res.data.user.name) : undefined,
        },
      });
    }
  };

  handdleSave = async () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;
    const { data } = this.state;
    let params = {
      ...values,
      is_active: values.is_active ? values.is_active : 0,
    };
    const { agentID } = this.state

    if (values.user_id) {
      params.user_id =
        typeof values.user_id === 'string' ? data.user.id : values.user_id.id;
    }
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (agentID === 'create') {
        let res = await adminService.POST_AGENT_ADD(params);
        if (res && res.success) {
          toast.success('บันทึกสำเร็จ');
          this.props.history.push(ROUTE_PATH.A_AGENT);
        } else {
          toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
        }
      } else {
        let res = await adminService.PATCH_AGENT_UPDATE(agentID, params);
        if (res && res.success) {
          toast.success('บันทึกสำเร็จ');
          this.props.history.push(ROUTE_PATH.A_AGENT);
        } else {
          toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
        }
      }
    }
  };

  handdleClickDetail = (e) => {
    this.props.history.push(ROUTE_PATH.A_AGENCY + '/' + e);
  };

  render() {
    const { initialValues, data } = this.state;
    return (
      <AgentDetailContainerStyled>
        <div className="form_item_layout">
          <AgentProfileForm
            initialValues={initialValues}
            handdleSave={this.handdleSave}
          />
          <div className='shwo_agency_layout'>
            <fieldset>
              <legend>Company</legend>
              <div>
                {
                  data &&
                  data.agencies.length > 0 &&
                  data.agencies.map((e, i) => (
                    <div key={i} className="show_agent_detail" onClick={() => this.handdleClickDetail(e.id)}>
                      {e.displayname}
                    </div>
                  ))
                }
              </div>
            </fieldset>
          </div>
        </div>
      </AgentDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AgentProfileForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentDetailContainer);
