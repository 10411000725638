import React from 'react';
import { connect } from 'react-redux';
import { VideoContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { iconImages } from 'assets';
import { VideoPlayer } from 'components';
import { ShraeSocial } from 'widgets';

class VideoContainer extends React.Component {
  state = {
    isShowPlay: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    if (this.videoRef) this.videoRef.removeEventListener('ended', () => {});
  }

  onRefVideo = (e) => {
    this.videoRef = e;
  };

  fetchData = () => {
    this.videoRef.addEventListener('ended', this.handleEndVideo);
  };

  handleClickPlay = () => {
    this.videoRef.play();
    this.setState({
      isShowPlay: false,
    });
  };

  handleEndVideo = () => {
    this.setState({
      isShowPlay: true,
    });
  };

  render() {
    const { isShowPlay } = this.state;
    const { dataVideo } = this.props;
    return (
      <VideoContainerStyled>
        <VideoPlayer
          useCustomRef
          onRef={this.onRefVideo}
          theme_standard
          url={dataVideo.videos[0].file_original.uri}
          width="320px"
          height="560px"
          muted
          controls={!isShowPlay}
        />
        {isShowPlay && (
          <img
            className="icon_play_wrap"
            src={iconImages['play_btn.png']}
            alt="icon share"
            onClick={this.handleClickPlay}
          />
        )}
        <ShraeSocial
          url={`${window.location.origin}/v/${dataVideo.videos[0].uuid}`}
        >
          <img
            className="icon_share_wrap"
            src={iconImages['icon_share.svg']}
            alt="icon share"
            onClick={this.handleClickShare}
          />
        </ShraeSocial>
        <a
          mimetype="application/octet-stream"
          href={dataVideo.videos[0].file_original.uri}
          download={dataVideo.videos[0].file_original.uri}
        >
          <img
            className="icon_download_wrap"
            src={iconImages['icons_download.png']}
            alt="icon share"
          />
        </a>
      </VideoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoContainer);
