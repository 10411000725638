import React from 'react';
import { connect } from 'react-redux';
import { WheelsCreatorContainerStyled } from './styled';
// import { adminService } from 'apiService';
// import { toast } from 'react-toastify';
import { setReduxUserAuth } from 'actions';
import { WheelsFormCreator } from 'forms/AdminFormSystem';

class WheelsCreatorContainer extends React.Component {
  state = {};

  componentDidMount() {

  }


  render() {
    // const { initialValues } = this.state;
    const { slugId } = this.props;

    return (
      <WheelsCreatorContainerStyled>
        <WheelsFormCreator
          // initialValues={initialValues}
          slugId={slugId}
        // handdleSave={this.handdleSave}
        />
      </WheelsCreatorContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.WheelsFormCreator,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WheelsCreatorContainer);
