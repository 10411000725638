import React from 'react';
import { BoxVoteStyled } from './styled';
import cx from 'classnames';

export const BoxVote = ({
  theme_standard,
  index_key,
  src,
  icon_pic,
  vote,
  num_text,
  name_text,
  onClick,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BoxVoteStyled src={src}>
      <div className={customClass}>
        <div className="top_vote_layout">{vote} Vote</div>
        <div className="bottom_vote_layout">
          <div className="group_text">
            <div className="text_layer">{num_text}</div>
            <div className="text_layer fix_width">{name_text}</div>
            {icon_pic && <img src={icon_pic} alt="vote" />}
          </div>
          <button
            className="button"
            onClick={() => {
              onClick(index_key);
            }}
          >
            VOTE NOW
          </button>
        </div>
      </div>
    </BoxVoteStyled>
  );
};
