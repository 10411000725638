import styled from 'styled-components';
// import { COLOR, FONT } from 'assets';

export const LoadingPageStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  background: rgba(251, 194, 16, 1);
  justify-content: center;
  align-items: center;
  .grounp_img {
    display: flex;
    align-items: center;
  }
`;
