import React, { useState } from 'react';
import { InputLabelStyled } from './styled';
import cx from 'classnames';

export const InputLabel = ({
  theme_standard,
  label,
  input,
  type,
  disabled,
  width,
  meta: { touched, error },
  handleClickSuccess,
  hideIcon,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [isEdit, setIsEdit] = useState();

  const handleClickEdit = (e) => {
    setIsEdit(e);
  };
  
  return (
    <InputLabelStyled isInvalid={touched && error} width={width}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {!isEdit ? (
          <div className="group_label">
            <div>{input.value}</div>
            {!hideIcon ? (
              <i
                className="material-icons"
                onClick={() => handleClickEdit(true)}
              >
                &#xe254;
              </i>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            <input type={type || 'text'} {...input} disabled={disabled} />
            {touched && error && <div className="error">{error}</div>}
            <button
              className="button_profile"
              onClick={() => {
                handleClickEdit(false);
                handleClickSuccess();
              }}
            >
              Subbmit
            </button>
            <button
              className="button_profile2"
              onClick={() => handleClickEdit(false)}
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </InputLabelStyled>
  );
};
