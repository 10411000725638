import styled from 'styled-components';
import { COLOR } from 'assets';
import { SCREEN } from 'assets';

export const InputTextareaLabelStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: ${(props) => (props.width ? props.width : '100%')};
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    textarea {
      width: 100%;
    }
    .error {
      padding-top: 5px;
      color: ${COLOR.RED_1};
    }
  }
  .group_label {
    display: flex;
    align-items: center;
    .material-icons {
      padding-left: 5px;
      cursor: pointer;
      font-size: 14px !important;
    }
  }

  button.button_profile {
    cursor: pointer;
    font-size: 10px;
    font-family: unicaOne_regular;
    color: #ffd11a;
    background: #0059b3;
    padding: 5px 12px;
    border: 1px solid #d0d3cd;
    font-weight: 600;
    border-radius: 5px;
  }

  button.button_profile2 {
    cursor: pointer;
    font-size: 10px;
    font-family: unicaOne_regular;
    color: #0059b3;
    background: #ffd11a;
    padding: 5px 12px;
    border: 1px solid #d0d3cd;
    font-weight: 600;
    border-radius: 5px;
  }

  textarea {
    border: 1px solid;
    border-radius: 5px;
    font-family: 'unicaOne_regular', 'prompt_light';
    padding: 3px;
    min-height: 100px;
  }

  @media (max-width: ${SCREEN.PHONE}) {
    textarea {
      // max-width: 70%;
    }
  }
`;
