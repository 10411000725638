import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { FollowingContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { iconImages } from 'assets';
import { BoxImage } from 'components';
import { ROUTE_PATH } from 'helpers';
import { Helmet } from "react-helmet";

class FollowingContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  fetchData = async () => {
    let res = await userService.GET_ME_FOLLOW_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  handleClickCreatorDetail = (id) => {
    this.props.history.push(`${ROUTE_PATH.CREATOR}/${id}`);
  };

  render() {
    const { data } = this.state;
    const { authRedux } = this.props;
    return (
      <FollowingContainerStyled>
        {data && (
          <Helmet>
            <title>following</title>
            <meta
              name="og:image"
              content={
                data.posters && data.posters.length > 0
                  ? data.posters[0].uri
                  : null
              }
            />
            <meta name="og:title" content={data.displayname} />
          </Helmet>
        )}
        <div className="text_left">{authRedux && authRedux.name}</div>
        <div className="text_title">กำลังติดตาม</div>
        <div className="container">
          {data &&
            data.map((e, i) => (
              <div
                key={i}
                className="item_wrap"
                onClick={() => this.handleClickCreatorDetail(e.id)}
              >
                <BoxImage
                  theme_standard
                  classManual="dynamic"
                  name={e.displayname}
                  detail={e.description}
                  src={
                    (e.posters && e.posters.length > 0 && e.posters[0].uri) ||
                    iconImages['Loading.png']
                  }
                />
              </div>
            ))}
        </div>
        {following.length < 1 && (
          <div className="text_no_follow">
            <div className="text_1">
              คุณยังไม่มีการติดตามศิลปินท่านใดในขณะนี้
            </div>
            <div className="text_1">
              มาร่วมสร้างความปรารถนาของคุณ เเละคนที่คุณรักให้กลายเป็นจริง
            </div>
            <div className="text_2">ดูรายการทั้งหมด</div>
          </div>
        )}
      </FollowingContainerStyled>
    );
  }
}
const following = [
  {
    src: 'yellowbg_member.png',
    name_text: 'martin',
    description: 'YOUTUBER',
  },
  {
    src: 'yellowbg_member.png',
    name_text: 'ozzy',
    description: 'YOUTUBER',
  },
  {
    src: 'yellowbg_member.png',
    name_text: 'wow',
    description: 'YOUTUBER',
  },
  {
    src: 'yellowbg_member.png',
    name_text: 'aerosmith',
    description: 'YOUTUBER',
  },
  {
    src: 'yellowbg_member.png',
    name_text: 'aoy',
    description: 'YOUTUBER',
  },
  {
    src: 'yellowbg_member.png',
    name_text: 'bus',
    description: 'YOUTUBER',
  },
  {
    src: 'yellowbg_member.png',
    name_text: 'martin',
    description: 'YOUTUBER',
  },
  {
    src: 'yellowbg_member.png',
    name_text: 'justin',
    description: 'YOUTUBER',
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowingContainer);
