import styled from 'styled-components';
import // COLOR,
// FONT
'assets';

export const SignUpFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
input[type="password"] {
  font-family: monospace !important;
}
input[type="text"] {
  font-family: monospace !important;
}
  text-align: left;
  h2 {
    margin: 5px 0;
  }
  .position_form {
    .pd_input {
      padding: 7px 0;
      .label {
        display: flex;
        margin-bottom: 5px;
      }
    }
    .btn_submit_wrap {
      padding: 10px 0;
    }
  }
`;
