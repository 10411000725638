import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { ReviewPageContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import ReactStars from 'react-rating-stars-component';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
import { NavbarWeb } from 'widgets';

class ReviewPageContainer extends React.Component {
  state = {
    rating: 5,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  OnRef = (e) => {
    this.upLoad = e;
  };

  onChangeReview = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  handleChangeRating = (e) => {
    this.setState({
      rating: e,
    });
  };

  handleClickUpload = () => {
    const refUp = this.upLoad;
    refUp.click();
  };

  handleUpload = (e) => {
    this.setState({
      video_file: e.target.files[0],
    });
  };

  handleClickSuccess = async () => {
    const { rating, message, video_file } = this.state;

    // Reject if form not completed
    if (!rating || !message) {
      alert('กรุณาระบุข้อความที่ต้องการส่ง');
      return false;
    }

    let formdata = new FormData();
    formdata.set('rating', rating);
    formdata.set('message', message);
    if (video_file) {
      formdata.append('video_file', video_file);
    }
    let res = await userService.POST_REVIEW__DETAIL(
      this.props.match.params.id,
      formdata
    );
    if (res && res.success) {
      this.props.history.push(ROUTE_PATH.HOME);
    } else {
      alert('Something wrong, please try again');
    }
  };

  render() {
    const { rating } = this.state;
    return (
      <ReviewPageContainerStyled>
        <NavbarWeb theme_standard propsHistory={this.props.history} />
        <div className="content_mainlayout">
          <div className="pasotion_rating">
            <div className="rating">
              <h1>รีวิว</h1>
              <ReactStars
                value={rating}
                size={50}
                half={false}
                onChange={this.handleChangeRating}
              />
              <div className="text_wdut">คุณคิดยังไง</div>
              <textarea
                maxLength="250"
                onChange={this.onChangeReview}
                placeholder={
                  'ขอบคุณมากค่ะพี่ เพื่อนของหนูชอบวิดีโอของพี่มากค่ะ ดีใจน้ำตาไหลเลย'
                }
              />
              <div>
                <button
                  className="btn_upload "
                  onClick={this.handleClickUpload}
                >
                  Upload
                </button>
                <span className="upload_text">
                  อัพโหลดไฟล์ วิดีโอหรือรูป ปฏิกิริยาของเพื่อนคุณเมื่อเห็นวิดีโอ
                  (ถ้ามี)
                </span>
              </div>
              <input
                hidden
                name="video"
                type="file"
                accept=".jpg,.jpeg,.gif,.png,.mp4,.mpg,.avi,.m4v,.mkv,.mov"
                ref={this.OnRef}
                onChange={this.handleUpload}
              />
              <div
                className="btn_submit_layout"
                onClick={this.handleClickSuccess}
              >
                <button className="btn_submit">ตกลง</button>
              </div>
            </div>
          </div>
        </div>
      </ReviewPageContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewPageContainer);
