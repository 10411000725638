import styled from 'styled-components';
import { COLOR } from 'assets';

export const InputUploadFIleBase64Styled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: ${(props) => (props.width ? props.width : '100%')};
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    input {
    }
    .error {
      padding-top: 5px;
      color: ${COLOR.RED_1};
    }
    .upload_btn_location {
      display: flex;
      align-items: center;
      .label_upload {
        margin-right: 5px;
      }
      .button {
        cursor: pointer;
        font-family: unicaOne_regular;
        color: #0059b3;
        background: #ffd11a;
        border: 1px solid #d0d3cd;
        font-weight: 600;
        border-radius: 5px;
      }
    }
  }
`;
