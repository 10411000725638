import styled from 'styled-components';
import {
  // COLOR,
  // FONT,
  SCREEN,
} from 'assets';

export const CreatorProfileFormSec1Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .grop_form {
    .box_label {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .label {
        min-width: 100px;
        margin-right: 10px;
      }
      .item_checkout {
        margin-right: 8px;
      }
    }
    .button_layer {
      text-align: right;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .grop_form {
      .box_label {
        flex-direction: column;
        align-items: flex-start;
        .label {
          text-align: left;
        }
      }
    }
  }
`;
