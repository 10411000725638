import styled from 'styled-components';
import // COLOR,
  // FONT
  'assets';

export const AdminOrderFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .group_form {
    .line_input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &.no_margin_bottom {
        margin-bottom: 0px;
      }
      .left_layout {
        width: 38px;
        margin-right: 10px;
      }
      .right_layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 600px;
        &.text_right {
          justify-content: flex-end;
        }
        .button_layout {
          margin-left: 5px;
        }
      }
    }
  }
`;
