import React from 'react';
import { InputCustomStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';

export const InputCustom = ({
  input,
  theme_standard,
  theme_footer,
  theme_log_in,
  placeHolder,
  showButton,
  labelButton,
  showIconButton,
  type,
  meta
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_footer: theme_footer,
    theme_log_in: theme_log_in,
  });
  return (
    <InputCustomStyled>
      <div className={customClass}>
        <div className="input_layer">
          <input
            className="input"
            {...input}
            type={type || 'text'}
            placeholder={placeHolder}
          />
          {meta && meta.touched && meta.error && <span className="error_wrap">{meta.error}</span>}
        </div>
        {showButton && (
          <button className="button">
            {showIconButton && (
              <img src={iconImages['triangle_right.png']} alt="" />
            )}
            {labelButton}
          </button>
        )}
      </div>
    </InputCustomStyled>
  );
};
