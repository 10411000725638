import styled from "styled-components"
import {
  // COLOR, FONT 
} from 'assets';

export const OrderContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .main_layout {
      .tab_layout {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 50%;
        .title_layout {
          margin-bottom: 10px;
          font-weight: bold;
        }
        .group_tab {
          padding: 3px;
          border-radius: 8px;
          display: flex;
          out-line: none;
          border: none;
          
          .button_tab_1 {
            width: 25%;
            cursor: pointer;
            text-align: center;
            border: none;
            outline: none;
            padding: 6px 0px;
            font-weight: bold;
            border-top: 1px solid #d0d3cd;
            border-left: 1px solid #d0d3cd;
            border-bottom: 1px solid #d0d3cd;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background: white;
            &.active {
              background: gold;
              border: none;
              outline: none;
            }
          }
          .button_tab_2 {
            width: 25%;
            cursor: pointer;
            text-align: center;
            border: none;
            outline: none;
            padding: 6px 0px;
            font-weight: bold;
            border-top: 1px solid #d0d3cd;
            border-bottom: 1px solid #d0d3cd;
            background: white;
            &.active {
              background: gold;
              border: none;
              outline: none;
            }
          }
          .button_tab_3 {
            width: 25%;
            cursor: pointer;
            text-align: center;
            border: none;
            outline: none;
            padding: 6px 0px;
            font-weight: bold;
            border-top: 1px solid #d0d3cd;
            border-bottom: 1px solid #d0d3cd;
            background: white;
            &.active {
              background: gold;
              border: none;
              outline: none;
            }
          }
          .button_tab_4 {
            width: 25%;
            cursor: pointer;
            text-align: center;
            border: none;
            outline: none;
            padding: 6px 0px;
            font-weight: bold;
            border-top: 1px solid #d0d3cd;
            border-right: 1px solid #d0d3cd;
            border-bottom: 1px solid #d0d3cd;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            background: white;
            &.active {
              background: gold;
              border: none;
              outline: none;
            }
          }
        }
      }
      .button_edit {
        padding: 2px 10px;
        font-size: 16px;
        background: #0059b3;
        color: white;
        font-weight: bold;
        border: 1px solid #0032b3;
        outline: none;
      }
      .div_right {
        text-align: right;
      }
      .div_center {
        text-align: center;
      }
    }
`