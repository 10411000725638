import styled from 'styled-components';

export const InputMultiSelectStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 100%;
`;
