import styled from 'styled-components';
import { FONT, SCREEN } from 'assets';

export const HowItworkStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .title {
    text-align: center;
    color: #636363;
    font-size: ${FONT.SIZE_15};
    padding-top: 20px;
    width: 100%;
  }
  .howtobuy_title {
    text-align: center;
    padding-bottom: 20px;
  }
  .box_image_layout_2 {
    display: flex;
    overflow-x: auto;
    width: 100%;
    max-width: 800px;
    margin: auto;
    .box_wrap {
      width: 33%;
      margin-right: 15px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .box_image_layout_2 {
      flex-direction: column;
      .box_wrap {
        width: 100%;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .title {
      padding-bottom: 20px;
    }
  }
`;
