import React from 'react';
import { connect } from 'react-redux';
import { CouponContainerStyled } from './styled';
import { adminService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { ReactTablePagiDB } from 'components';
import { ROUTE_PATH } from 'helpers';
import { Helmet } from "react-helmet";
import { CouponForm } from 'forms';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';

class CouponContainer extends React.Component {
  state = { data: [], isShowForm: false, labelSave: 'SAVE', isSave: true, labelButton: 'Create' };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (offset = 0) => {
    let res = await adminService.GET_COUPON_LIST(offset);
    if (res && res.success) {
      const { current_page } = res.page;
      this.setState({
        data: res.data,
        currentPageData: current_page - 1,
        pageList: res.page,
        totalPages: res.page.last_page,
      });
    }
  };

  handlePageClick = (e) => {
    const { pageList } = this.state;

    this.fetchData(e.selected * pageList.per_page);
  };

  handdleClickAdd = (e) => {
    this.props.history.push(ROUTE_PATH.A_CREATOR + '/create');
  };

  handdleClickDetail = (e) => {
    this.props.history.push(ROUTE_PATH.A_CREATOR + '/' + e);
  };

  handdleShowForm = () => {
    const { isShowForm } = this.state

    this.setState({
      initialValues: null,
      isShowForm: !isShowForm,
      coupon_code: '',
      isSave: true,
      labelSave: 'CREATE',
      labelButton: 'Create Coupon',
    })
  }

  handleSubmit = async () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;

    const { isSave, coupon_code } = this.state

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let params = {
        ...values,
        type: values.type ? values.type : 'SINGLE',
      };

      if (params.discount_coins === undefined && params.discount_percents === undefined) {
        toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({
          submitViewError: true,
        });
      } else {
        if (isSave) {
          let res = await adminService.POST_COUPON_ADD(params);
          if (res && res.success) {
            toast.success('บันทึกสำเร็จ');
            this.fetchData();
            this.handdleShowForm();
          } else {
            toast.error('Code ซ้ำ');
          }
        } else {
          let res = await adminService.PATCH_COUPON_EDIT(params, coupon_code);
          if (res && res.success) {
            toast.success('บันทึกสำเร็จ');
            this.fetchData();
            this.handdleShowForm();
          } else {
            toast.error('บันทึกไม่สำเร็จ');
          }
        }
      }
    }
  }

  handleDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteCoupon()
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  deleteCoupon = async () => {
    const { coupon_code } = this.state

    let res = await adminService.DELETE_COUPON_DELETE(coupon_code);
    if (res && res.success) {
      toast.success('ลบสำเร็จ');
      this.fetchData();
      this.handdleShowForm();
    } else {
      toast.error('ลบไม่สำเร็จ');
    }
  }

  handdleClickDetail = (e) => {
    this.setState({
      initialValues: {
        ...e
      },
      coupon_code: e.code,
      isShowForm: true,
      isSave: false,
      labelSave: 'SAVE',
      labelButton: 'Edit Coupon',
    })
  }

  render() {
    const { data, isShowForm, labelSave, isSave, initialValues, labelButton, currentPageData, totalPages } = this.state;
    return (
      <CouponContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="main_layout">
          <div className="tab_layout">
            <div className="title_layout">
              Coupon System
            </div>
          </div>
          <ReactTablePagiDB
            columns={columns({ handdleClickDetail: this.handdleClickDetail })}
            data={data}
            totalPages={totalPages}
            currentPageData={currentPageData}
            _handlePageClick={this.handlePageClick}
          />
          <div className="form_layout">
            <button className="button_coupon" onClick={this.handdleShowForm}>{labelButton}</button>
            {
              isShowForm &&
              <div className="form_layout">
                <CouponForm
                  handleSubmit={this.handleSubmit}
                  handleDelete={this.handleDelete}
                  labelSave={labelSave}
                  isSave={isSave}
                  initialValues={initialValues}
                />
              </div>
            }
          </div>
        </div>
      </CouponContainerStyled>
    );
  }
}

const columns = ({ handdleClickDetail }) => [
  {
    Header: 'Code',
    accessor: 'code',
    Cell: (e) => (
      <div style={{ cursor: 'pointer', color: '#0059b3', fontWeight: 'bold' }} onClick={() => handdleClickDetail(e.row.original)}>{e.value}</div>
    ),
    // style: {width: '350px' },
  },
  {
    Header: 'Quantity',
    accessor: 'type',
  },
  {
    Header: 'Discount',
    accessor: 'discount_percents',
    Cell: (e) => (
      <div style={{ textAlign: 'right' }}>{e.value ? e.value + '%' : ''}</div>
    ),
  },
  {
    Header: 'Limit',
    accessor: 'discount_coins',
    Cell: (e) => (
      <div style={{ textAlign: 'right' }}>{e.value ? e.value + '฿' : ''}</div>
    ),
  },
  {
    Header: 'Expire',
    accessor: 'end_date',
    Cell: (e) => (
      // <button className="button_edit" onClick={() => handdleClickDetail(e.value)}>Edit</button>
      <div style={{ textAlign: 'center' }}>{e.value ? moment(e.value).format('DD/MM/YYYY') : ''}</div>
    ),
  },
  {
    Header: '# Used',
    accessor: 'used_count',
    Cell: (e) => (
      <div style={{ textAlign: 'right' }}>{e.value ? e.value : ''}</div>
    ),
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CouponForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponContainer);
