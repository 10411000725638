import styled from 'styled-components';
import { COLOR } from 'assets';
export const InputTextareaStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  textarea {
    outline: none;
    border: none;
    border-bottom: 1px solid ${COLOR.GREEN_1};
    width: 100%;
    resize: none;
    overflow: hidden;
    height: 28px;
  }
`;
