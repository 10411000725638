import styled from 'styled-components';
import { SCREEN } from 'assets';

export const PageMainLayoutStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .content_mainlayout {
    padding-top: 131px;
    padding-bottom: 30px;
    padding-left: 7px;
    padding-right: 7px;
  }


  @media (max-width: ${SCREEN.TABLET}) {
    .content_mainlayout {
      padding-top: 80px;
    }
  }
`;
