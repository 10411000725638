import React, { useState } from 'react';
import { PaymentPopupStyled } from './styled';
import { Modal } from 'components';
// import { Paypal } from 'widgets/PaymentsSystem';
import {
  CreditCard,
  InternetBanking,
  TrueWallet,
  PromtPay,
} from 'widgets/PaymentsSystem/OmisePaymentSystem';

import { iconImages } from 'assets';

export const PaymentPopup = (props) => {
  const { invoiceID, amount } = props;
  const [isModal, setIsModal] = useState();
  const [isModalCustom, setIsModalCutom] = useState();
  const [renderModal, setRenderModal] = useState();

  const handleClickSelectePayment = () => {
    setIsModal(true);
  };

  const handleClickCloseModal = () => {
    setIsModal(false);
  };

  const _callback = (type, qr_uri) => {
    switch (type) {
      case 'truewallet':
        setIsModalCutom(true);
        setRenderModal(
          <TrueWallet
            invoiceID={invoiceID}
            amount={amount}
            callback={_callback}
          />
        )
        break;

      case 'promptpay':
        setIsModalCutom(true);
        setRenderModal(
          <PromtPay
            qr_uri={qr_uri}
            invoiceID={invoiceID}
            amount={amount}
            callback={_callback}
          />
        )
        break;

      default:
        break;
    }

  };

  const handleClickCloseModalCustom = () => {
    setIsModalCutom(false);
  };

  return (
    <PaymentPopupStyled>
      <button className="pay_btn" onClick={handleClickSelectePayment}>
        เลือกช่องทางการชำระ
      </button>
      <Modal
        theme_modal_standard
        isShow={isModal}
        handleClickCloseModal={handleClickCloseModal}
      >
        <div className="container_select_payment">
          <h2 className="h1_text">กรุณาเลือกวิธีการชำระเงิน</h2>
          <div className="btn_layout">
            <div className="payment_item_wrap2" onClick={handleClickCloseModal}>
              <CreditCard
                invoiceID={invoiceID}
                amount={amount}
                callback={_callback}
              >
                <div>
                  <img
                    className="icon_img"
                    src={iconImages['new-visa-mastercard-logo.png']}
                    alt=" "
                    width="auto"
                    height="100px"
                  />
                </div>
                <p>ชําระผ่าน</p>บัตรเครดิต
              </CreditCard>
            </div>
            <div className="payment_item_wrap2" onClick={handleClickCloseModal}>
              <InternetBanking
                invoiceID={invoiceID}
                amount={amount}
                callback={_callback}
              >
                <div>
                  <img
                    className="icon_img"
                    src={iconImages['bank.png']}
                    alt=" "
                    width="auto"
                    height="100px"
                  />
                </div>
                <p>ชําระผ่าน</p> Internet Banking
              </InternetBanking>
            </div>
            <div className="payment_item_wrap2" onClick={handleClickCloseModal}>
              <PromtPay
                invoiceID={invoiceID}
                amount={amount}
                callback={_callback}
              >
                <div className="img_show">
                  <img
                    className="icon_img"
                    src={iconImages['qr1.jpg']}
                    alt=" "
                    width="100%"
                    height="10%"
                  />
                </div>
                <p>ชําระผ่าน</p> PromptPay
              </PromtPay>
            </div>
            <div className="payment_item_wrap3" onClick={handleClickCloseModal}>
              <TrueWallet
                invoiceID={invoiceID}
                amount={amount}
                callback={_callback}
              >
                <div>
                  <img
                    className="icon_img"
                    src={iconImages['truemoneywallet.jpg']}
                    alt=" "
                    width="auto"
                    height="100px"
                  />
                </div>
                <p>ชําระผ่าน</p> True Wallet
              </TrueWallet>
            </div>
          </div>
        </div>
      </Modal>
      {isModalCustom && (
        <Modal
          theme_modal_standard
          isShow={isModalCustom}
          handleClickCloseModal={handleClickCloseModalCustom}
        >
          {renderModal}
        </Modal>
      )}
    </PaymentPopupStyled>
  );
};
