import React from "react";
import { Field, reduxForm } from "redux-form";
import { GenreFormStyled } from "./styled";
import { ButtonCustom, Input, InputCheckbox } from "components";
// InputUploadFIleBase64
import { VALIDATE } from 'helpers';

class GenreForm extends React.Component {
  state = {};

  componentDidMount() {

  }

  render() {
    // const { } = this.state
    const { handdleSave } = this.props
    return (
      <GenreFormStyled>
        <fieldset>
          <legend>Genre</legend>
          <div className="box_label">
            <div className="label">Order No.</div>
            <Field
              theme_standard_2
              name="order"
              placeholder="Enter Order No."
              component={Input}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="box_label">
            <div className="label">Slug</div>
            <Field
              theme_standard_2
              name="slug"
              placeholder="Enter Slug"
              component={Input}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="box_label">
            <div className="label">Label</div>
            <Field
              theme_standard_2
              name="label"
              placeholder="Enter Label"
              component={Input}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="box_label">
            <div className="label">Title</div>
            <Field
              theme_standard_2
              name="title"
              placeholder="Enter Title"
              component={Input}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="box_label">
          <div className="label">Option</div>
            <div className="item_checkout">
              <Field
                theme_standard
                name="is_active"
                label="Active"
                value={true}
                component={InputCheckbox}
              />
            </div>
          </div>
          <div className="button_layer">
            <ButtonCustom theme_standard label="Save" onClick={handdleSave} />
          </div>
        </fieldset>
      </GenreFormStyled>
    );
  }
}

export default reduxForm({
  form: "GenreForm", // a unique identifier for this form
  enableReinitialize: true,
})(GenreForm);
