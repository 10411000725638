import React from 'react';
import { FooterPageStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';

export const FooterPage = (props) => {
  const { theme_standard } = props;
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const handleClickMenu = (key) => {
    switch (key) {
      case 'privacy':
        props.propsHistory.push(ROUTE_PATH.PRIVACY);
        break;
      case 'terms':
        props.propsHistory.push(ROUTE_PATH.TERMS);
        break;
      default:
        break;
    }
  };

  const handleClickRegister = () => {
    props.propsHistory.push(ROUTE_PATH.CREATOR_REGISTER);
  };

  return (
    <FooterPageStyled>
      <div className={customClass}>
        <div className="footer_left">
          <img className="img_footer" src={iconImages['logo_web.png']} alt="" />
          <div className="group_form">
            <div className="text1">ศิลปินที่ต้องการเข้าร่วม</div>
            <div className="text2">โปรดคลิ๊กเพื่อลงทะเบียน</div>
            <div className="input_wrap">
              <img
                onClick={handleClickRegister}
                src={iconImages['Regist.png']}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="footer_right">
          <div className="right_1">
            <div className="text_follow">Follow us here</div>
            <div>
              <a href={'https://www.facebook.com/mewowapp/'}>
                <img
                  className="img_right_footer"
                  src={iconImages['facebook.png']}
                  alt=""
                  style={{ width: '22px', marginBottom: '2px' }}
                />
              </a>
              <a href={'https://www.instagram.com/mewowapp'}>
                <img
                  className="img_right_footer"
                  src={iconImages['ig.png']}
                  alt=""
                  style={{ width: '22px', marginBottom: '2px' }}
                />
              </a>
              <a href={'https://twitter.com/AppMewow'}>
                <img
                  className="img_right_footer"
                  src={iconImages['twitter.png']}
                  alt=""
                  style={{ width: '22px', marginBottom: '2px' }}
                />
              </a>
              <a href={'mailto:support@mewow.app'}>
                <img
                  className="img_right_footer"
                  src={iconImages['mail.png']}
                  alt=""
                  style={{ width: '22px', marginBottom: '2px' }}
                />
              </a>
            </div>
            <div className="text_right">
              <div
                className="text_1"
                onClick={() => handleClickMenu('privacy')}
              >
                Privacy
              </div>
              <div className="text_1" onClick={() => handleClickMenu('terms')}>
                Terms
              </div>
            </div>
          </div>
          <div className="right_3">
            <div className="text_show">
              @ 2020, MEWOW COMPANY LIMITED 55/240 Moo 9 PAK KRET,
              Nonthaburi, 11120 Thailand{' '}
            </div>
            {/* <div className="text_show"> | </div> */}
            {/* <div className="text_show_2">Contact Us: </div> */}
            <div className="text_show_3">
              Contact Us :{' '}
              <a href={'mailto:support@mewow.app'}>support@mewow.app</a>{' '}
            </div>
            {/* <div className="text_show"> | </div> */}
            <div className="text_show_4">Call center : 02-007-2407</div>
          </div>
        </div>
      </div>
    </FooterPageStyled>
  );
};
