import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxTitleStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;
        .box_left {
            color: ${COLOR.BLACK_4};
            font-size: ${FONT.SIZE_8};
            font-weight: 800;
        }
        .box_right {
            font-size: ${FONT.SIZE_2};
            font-weight: 600;
        }
    }
`