import React, { useRef, useEffect } from 'react';
import { BannerStyled } from './styled';
// import { BASE_API } from '../../apiService/apiConfig';
import gift_icon_desktop from '../../assets/img/icon/icons8-gift-50_black.png';
import video_banner from '../../assets/video/banner.mp4';

export const Banner = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.play();
  }, []);

  return (
    <BannerStyled>
      <div className="group_text">
        <div className="text_banner">คุณสามารถสั่งซื้อ</div>
        <div className="text_mid_banner">วิดีโอเซอร์ไพรส์ จากศิลปิน ดารา </div>
        <div className="text_banner">
          ที่คุณชื่นชอบได้แล้ววันนี้
          <img className="gift_icon" src={gift_icon_desktop} alt="" />
        </div>
      </div>
      <video ref={videoRef} src={video_banner} loop muted="muted" playsInline/>
      {/* <div
        className="pic_layer"
        style={{
          backgroundImage: `url("${BASE_API}/storage/banner_images/banner1.jpg")`,
        }}
      ></div>
      <div
        className="pic_layer_1"
        style={{
          backgroundImage: `url("${BASE_API}/storage/banner_images/banner2.jpg")`,
        }}
      ></div>
      <div
        className="pic_layer_1"
        style={{
          backgroundImage: `url("${BASE_API}/storage/banner_images/banner3.jpg")`,
        }}
      ></div>
      <div
        className="pic_layer_1"
        style={{
          backgroundImage: `url("${BASE_API}/storage/banner_images/banner4.jpg")`,
        }}
      ></div>
      <div
        className="pic_layer_2"
        style={{
          backgroundImage: `url("${BASE_API}/storage/banner_images/banner5.jpg")`,
        }}
      ></div> */}
    </BannerStyled>
  );
};
