import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { ProfileFormStyled } from './styled';
// import { VALIDATE } from 'helpers';
import { InputLabel } from 'components';

class ProfileForm extends React.Component {
  state = {};

  render() {
    const { handleSubmit } = this.props;
    return (
      <ProfileFormStyled>
        <div className="info_container">
          <div className="row_col">
            <div className="left_col">Display Name</div>
            <div className="right_col">
              <Field
                theme_standard
                name="gifter.displayname"
                component={InputLabel}
                handleClickSuccess={handleSubmit}
              />
            </div>
          </div>
          <div className="row_col">
            <div className="left_col">About</div>
            <div className="right_col">
              <Field
                theme_standard
                name="gifter.about"
                component={InputLabel}
                handleClickSuccess={handleSubmit}
              />
            </div>
          </div>
          <div className="row_col">
            <div className="left_col">Your E-mail</div>
            <div className="right_col">
              <Field
              hideIcon={true}
                theme_standard
                name="email"
                component={InputLabel}
                handleClickSuccess={handleSubmit}
              />
            </div>
          </div>
        </div>
      </ProfileFormStyled>
    );
  }
}

export default reduxForm({
  form: 'ProfileForm', // a unique identifier for this form
  enableReinitialize: true,
})(ProfileForm);
