import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { TermsOfServiceContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';

class TermsOfServiceContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.scrollToTop();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <TermsOfServiceContainerStyled>
        <h1 className="title">Site Terms of Service</h1>
        <p>Last Updated: July 2, 2020</p>
        <p>
          Welcome to meWOW, the ‘C-commerce’ Platform where you can book a
          shoutout 15 secs-video-gift from your favorite Celebrities, Artists,
          and Creators to make special day for your beloved ones.
        </p>
        <p>
          This Site Terms of Service Agreement (“Terms”) governs your use of the
          meWOW™ platform offered by meWOW Company Limited,
          doing business as meWOW (“we”, “us”, or “meWOW”), including our
          website (meWOW.app), mobile application (“App”), and services we
          provide through them (collectively, the website, App, and services
          referred to as our “Site”). “You” refers to you as a user of the Site.
        </p>
        <p>
          These Terms apply to users of, including visitors to, our Site. Use of
          our Site is also subject to our current Acceptable Use Policy. Your
          use of our Site as a Creator User (defined below) is governed by the
          Creator Terms of Service. (“Creator Terms”).
        </p>
        <p className="title_normal">
          PLEASE READ THESE TERMS CAREFULLY. By using our Site or otherwise
          indicating your acceptance (for example, by agreeing when creating or
          logging into your account, clicking “I Agree,” etc.), you represent
          and warrant that you have read, understand, and agree to be bound by
          these Terms. If you do not agree, do not access or use our Site.
        </p>
        <p className="title_normal">Table of Contents</p>
        <ul className="group_number_text title_normal">
          <li>meWOW</li>
          <li>meWOW Videos</li>
          <li>Acknowledgement</li>
          <li>Additional Terms</li>
          <li>Eligibility</li>
          <li>Fees and Payment</li>
          <li>Ownership</li>
          <li>Copyright and Intellectual Property Policy</li>
          <li>Privacy</li>
          <li>Third Party Content and Interactions</li>
          <li>Links</li>
          <li>Changes to our Site</li>
          <li>Termination and Reservation of Rights</li>
          <li>Indemnification</li>
          <li>Disclaimers and Limitations on our Liability</li>
          <li>Arbitration Agreement and Waiver of Certain Rights</li>
          <li>Changes to these Terms</li>
        </ul>
        <p className="title_normal">1. meWOW</p>
        <p>
          By creating an account on our Site, you agree to provide true,
          accurate, up-to-date, and complete information. It is important that
          you maintain and promptly update your details and any other
          information you provide to us, to keep such information current and
          complete.
        </p>
        <p>
          You agree not to create a Site account using a false identity or
          providing false information or if you have previously been removed or
          banned from our Site.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your Site
          account information, including your username and password.{' '}
        </p>
        <p>
          You agree that you are solely responsible for all activities that
          occur on or in connection with your Site account, including the use of
          account by the third-party authorized by you. And, you agree to notify
          us immediately of any unauthorized access or use of your Site account
          at support@meWOW.app. You acknowledge and agree that we are not
          responsible or liable for any damages, losses, costs, expenses, or
          liabilities related to any unauthorized access to or use of your Site
          account.
        </p>
        <p className="title_normal">2. meWOW Videos</p>
        <ul className="group_word_text">
          <li>
            Through our Site, you may obtain personalized VDO gift from
            celebrities, artists and Creators (each, a “Creator User”). You may
            submit a request to a Creator User for a meWOW Video that is
            personalized for you or a third party that you identify as a
            recipient (“Recipient”).
          </li>
          <li>
            You acknowledge and agree that the Creator User has sole discretion
            to determine how to fulfill your request and the content of the
            meWOW Video created, and may not follow your request exactly. We
            reserve the right to reject any request in our sole discretion. The
            Creator User has up to seven days (at our sole discretion) to
            fulfill or decline your request. Once your request is fulfilled,
            your payment method will be charged the amount specified on the
            Creator User’s booking page on our Site at the time you submitted
            the request.
          </li>
          <li>
            meWOW Videos are licensed, not sold. You are buying the right (or
            license) to use it, not the actual meWOW Video itself.
          </li>
          <li>
            Subject to your payment in full, the Creator User hereby grants to
            you the following limited rights to use the meWOW Video solely for
            your own personal, non-commercial, and non-promotional purposes,
            subject to these Terms: a non-exclusive, royalty-free, fully paid,
            worldwide, sublicensable, revocable license to use, distribute, and
            publicly display that meWOW Video, in any and all media (for
            example, on social media platforms), whether now known or hereafter
            invented or devised.
          </li>
          <li>
            You may not sell, re-sell, or encumber your rights in any meWOW
            Video. You may sublicense your rights in a meWOW Video only to the
            extent necessary for you to use the meWOW Video as permitted under
            these Terms (for example, sharing it with friends on a social media
            platform or sending it to a Recipient for personal, non-commercial,
            and non-promotional purposes as set forth above).
          </li>
          <li>
            You may not use the service for any illegal or unauthorized purpose.
            You agree to comply with all laws, rules and regulations applicable
            to your use of the Service and Content, including but not limited
            to, copyright laws.
          </li>
          <li>
            You may use a meWOW Video only in accordance with these Terms, which
            includes our Acceptable Use Policy. We may terminate all or part of
            the foregoing licenses at any time for any reason. We reserve the
            right to remove a meWOW Video from our Site at any time for any
            reason without any notice to you.
          </li>
        </ul>
        <p className="title_normal">3. Acknowledgement</p>
        <p>You acknowledge and agree that:</p>
        <ul className="group_word_text">
          <li>
            meWOW will not be liable or responsible for any meWOW Video or other
            offering requested by you or any Submission (defined below) you
            make;
          </li>
          <li>
            meWOW is able to access and verify any meWOW Video request by you or
            any submission (defined below) you make, and that you will not make
            any request or Submission that infringes on the privacy or other
            rights of a third party such as harassment, violence and
            discrimination based on race, sex, religion, nationality,
            disability, sexual, orientation or age;{' '}
          </li>
          <li>
            the meWOW watermark on each meWOW Video must remain intact and you
            agree not to edit, change, modify, cover, or remove the watermark
            from any meWOW Video or assist or encourage any third party to do
            so; you further agree not to edit, change, modify, or create any
            derivative work of a meWOW Video or assist or encourage any third
            party to do so;
          </li>
          <li>
            if you breach any provisions of these Terms, we terminate your
            access to our Site, or we remove or ban you (or any Site account you
            created or control), your license to use any meWOW Video, or other
            offering under these Terms terminates and you must: promptly remove
            all copies of any meWOW Video, or other offering in your possession
            or control, including from any social media platform; notify any
            Recipient of the termination and instruct them to do the same, and
            take any other action we reasonably request, including identifying
            each Recipient; and
          </li>
          <li>
            without limiting any of our rights, any request you submit through
            our Site may be rejected by us or by a Creator User; if any request
            or submission infringes on the privacy or other rights of a third
            party, we may terminate your access to our Site, remove or ban you
            (and any Site account you created or control), or take other
            appropriate action in meWOW’s sole discretion, including terminating
            your license to use any meWOW Video or other offering under these
            Terms.
          </li>
        </ul>
        <p className="title_normal">4. Additional Terms</p>
        <p>
          Some products or services offered through the Site may have additional
          terms and conditions (“Additional Terms”). If Additional Terms apply,
          we will make them available to you in connection with that product or
          service. By using that product or service, you agree to the Additional
          Terms. To the extent that the Additional Terms conflict with any of
          these Terms, these Terms will govern unless the Additional Terms say
          that some or all of these Terms don’t apply.
        </p>
        <p className="title_normal">5. Eligibility</p>
        <ul className="group_word_text">
          <li>
            Age: You must be at least 15 years old to use our Site. You must be
            18 years old to use meWOW’s commercial use functionality, unless a
            parent or legal guardian provides written consent. If you are under
            the age of 18, you may only purchase Product and Service (VDO gifts)
            with the permission of a parent or guardian.
          </li>
          <li>
            Eligibility Representations and Warranties: You represent and
            warrant that:
            <ul className="group_roman_text">
              <li>
                you have not been prohibited from using or accessing any aspect
                of our Site by us or pursuant to any applicable law or
                regulation;
              </li>
              <li>
                you will comply with all applicable terms of any third party
                payment provider we select, and you are not on a prohibited list
                of that payment provider;
              </li>
              <li>
                you (and any Site account that you created or control) have not
                been previously banned or removed from our Site for any reason;
                and
              </li>
              <li> you are not a convicted sex offender.</li>
            </ul>
          </li>
        </ul>
        <p className="title_normal">6. Fees and Payment</p>
        <ul className="group_word_text">
          <li>
            Fees: The fee for a meWOW Video or other offering is specified on
            the Creator User’s booking page on our Site when you make your
            request. You agree to pay all amounts due in accordance with the
            payment terms in effect when you submit your request or purchase
            merchandise.
          </li>
          <li>
            Currency: All transactions are in Thai Baht (“THB”) unless otherwise
            specified at point of purchase.
          </li>
          <li>
            Payment: You may request a meWOW Video by using a valid payment card
            and internet banking through the applicable third party payment
            provider. You must provide the third party payment provider with
            valid payment information (Visa, MasterCard, or other issuer
            accepted by the payment provider). You acknowledge and agree that
            meWOW does not operate, own, or control the payment provider. Your
            use of your payment card and internet banking is governed by your
            agreement with and the privacy policy of the payment provider, not
            these Terms. You agree to immediately notify the payment provider of
            any change in your billing address (or other information) for your
            payment card. You may not return or exchange a meWOW Video and no
            refunds will be issued.
          </li>
          <li>
            By providing your payment information, you agree that meWOW may
            place a pre-authorization hold and, after your request has been
            fulfilled, authorize the payment provider to immediately charge you
            for all amounts due and payable with no additional notice to or
            consent from you.
          </li>
          <li>
            You may cancel the order if the Creator has not yet accepted your
            request. Your payments will be refunded to you within 15 days.
          </li>
          <li>
            In case that the Creator does not submit the video within 7 days
            after accepting your request, your payments will be refunded to you
            within 15 days.
          </li>
          <li>
            meWOW reserves the right (but is under no obligation) to cancel your
            meWOW Video request if: (i) your payment method is declined; or (ii)
            you have previously been banned or removed from our Site for any
            reason. meWOW also reserves the right at any time to change its fees
            and payment procedures, including its payment options and terms,
            either immediately upon posting on our Site or by other notice to
            you.
          </li>
          <li>
            Payment Questions: If you have a question about a purchase made on
            the App or a charge to your payment card, please contact us at
            support@meWOW.app. We have the sole discretion to determine how
            billing disputes between us will be resolved.
          </li>
        </ul>
        <p className="title_normal">7. Ownership</p>
        <ul className="group_word_text">
          <li>
            You acknowledge and agree that each meWOW Video or other offering
            from a Creator User is owned by the Creator User who created it.
          </li>
          <li>
            We or our licensors own all right, title, and interest in and to:
            (i) our Site and the “look and feel” of our Site, including all
            software, ideas, processes, data, text, media, and other content
            available on our Site (individually, and collectively, “meWOW
            Content”); and (ii) our trademarks, logos, and brand elements
            (“Marks”). Our Site, meWOW Content, and Marks are each protected
            under Thai and international laws. You may not duplicate, copy, or
            reuse any portion of the HTML/CSS, JavaScript, visual design
            elements, or concepts without our prior express written consent.
          </li>
          <li>
            You hereby grant to us a non-exclusive, royalty-free, fully paid,
            unlimited, universal, sublicensable (through multiple tiers of
            sublicenses), perpetual, and irrevocable license, in any and all
            manner and media, whether now known or hereinafter invented or
            devised (including social media platforms), to use, reproduce,
            license, distribute, modify, adapt, reformat, publicly perform,
            publicly display, create derivative works of, and otherwise use the
            following for the purposes of operating and providing our Site,
            developing and improving our products and services, and advertising,
            marketing, and promoting our Site and our products and services: (i)
            any request (video, text, or otherwise) that you make or send to any
            Creator User, including information concerning any Recipient; and
            (ii) any submission that you make to meWOW, whether through our
            Site, a social media platform, third party website, or otherwise,
            including a reaction video, idea, intellectual property, publicity
            rights, Feedback (defined below), review, photo, video, email, text,
            post, or other communication, whether relating to you, or a third
            party (i) and (ii) each, individually, and collectively, a
            “Submission”). You represent and warrant that you either: (x) own
            all rights to any Submission; or (y) have all rights necessary,
            including with respect to any third party that contributed to, is
            included in, or is referred to, in any Submission, to grant to us
            the foregoing rights. You will not make any Submission that is
            confidential or proprietary or that contains or includes any
            information that you do not have the right to disclose or that you
            or any Recipient do not wish to be disclosed. meWOW will not be
            responsible or liable for any use or disclosure of a Submission,
            including any personal information belonging to you, a Recipient, or
            a third party.
          </li>
          <li>
            We may, for any reason, refuse to accept or transmit a Submission or
            refuse to remove a Submission from our Site. Further, we reserve the
            right to decide whether a Submission violates these Terms and may,
            at any time, without notice to you and in our sole discretion,
            remove your Submission, terminate your access to our Site, remove or
            ban you (and any Site account you created or control), or take other
            appropriate action in our sole discretion for violation of these
            Terms.
          </li>
        </ul>
        <p className="title_normal">
          8. Copyright and Intellectual Property Policy
        </p>
        <p>
          We respect intellectual property rights and ask you to do the same. As
          a condition of your access to and use of the Services, you agree not
          to use the Services to infringe on any intellectual property rights.
          We reserve the right, with or without notice, at any time and in our
          sole discretion to block access to and/or terminate the accounts of
          any user who infringes or is alleged to infringe any copyrights or
          other intellectual property rights
        </p>
        <p className="title_normal">9. Privacy</p>
        <p>
          Your privacy is important to us. Our Privacy Policy explains how we
          collect, use, and share personal information and other data. By using
          our Site, you agree to our Privacy Policy.
        </p>
        <p className="title_normal">10. Third Party Content and Interactions</p>
        <p>
          Our Site may contain features and functionalities that link to or
          provide you with access to third party content, that is completely
          independent of meWOW, including meWOW Videos, websites, platforms,
          directories, servers, networks, systems, information, databases,
          applications, software, programs, products or services, and the
          Internet in general.{' '}
        </p>
        <p>
          Your interactions with third parties, including users and Creator
          Users, found on or through our Site are solely between you and the
          third party. You should make whatever investigation you feel necessary
          or appropriate before proceeding with any contact or interaction, in
          connection with our Site or otherwise.{' '}
        </p>
        <p>
          However, you agree not to contact or interact with any Creator User
          except as expressly permitted through our Site. You also agree that
          meWOW may, in its sole discretion, intercede in any dispute and you
          will reasonably cooperate with meWOW if it does so.{' '}
        </p>
        <p>
          You acknowledge and agree that we are not responsible or liable for
          any damages, losses, costs, expenses, or liabilities of any kind or
          nature incurred as the result of any such interaction.{' '}
        </p>
        <p className="title_normal">11. Links</p>
        <p>
          Our Site may contain links to social media platforms or third party
          websites. You acknowledge and agree that: (a) the link does not mean
          that we endorse or are affiliated with the platform or website; and
          (b) we are not responsible or liable for any damages, losses, costs,
          expenses, or liabilities related to your use of the platform or
          website. You should always read the terms and conditions and privacy
          policy of a platform or website before using it.
        </p>
        <p className="title_normal">12. Changes to our Site</p>
        <p>
          You acknowledge and agree we may change or discontinue any aspect of
          our Site at any time, without notice to you
        </p>
        <p className="title_normal">
          13. Termination and Reservation of Rights
        </p>
        <p>
          You may cancel your Site account at any time by contacting a member of
          the meWOW team at support@mewow.app. We reserve the right to terminate
          access to our Site to any person, including you, at any time, for any
          reason, in our sole discretion. If you violate any of these Terms or
          any applicable law and regulation, your permission to use our Site
          automatically terminates.
        </p>
        <p className="title_normal">14. Indemnification</p>
        <p>
          You agree to indemnify, defend, and hold harmless meWOW and its
          parents, subsidiaries, affiliates, officers, employees, directors,
          shareholders, agents, partners, vendors, and licensors (each, a “meWOW
          Party,” and collectively, “meWOW Parties”) from and against any and
          all claims, costs, proceedings, demands, losses, damages, and expenses
          (including reasonable attorneys’ fees and costs) of any kind or nature
          arising from, out of, in connection with, or relating to: (a) these
          Terms; or (b) use of our Site. meWOW may select counsel for and
          control the defense of any claim that you are indemnifying. You will
          reasonably cooperate with us in connection with any claim.
        </p>
        <p className="title_normal">
          15. Disclaimers and Limitations on our Liability
        </p>
        <ul className="group_word_text">
          <li>
            You acknowledge and agree that your use of our Site is at your own
            risk and that our Site is provided on an “as is” and “as available”
            basis. To the extent permitted by applicable law, the meWOW Parties
            disclaim all warranties, conditions, and representations of any
            kind, whether express, implied, statutory, or otherwise, including
            those related to merchantability, and arising out of course of
            dealing or usage of trade.
          </li>
          <li>
            In particular, the meWOW Parties make no representations or
            warranties about the accuracy or completeness of content available
            on or through our Site or the content of any social media platform
            or third party website linked to or integrated with our Site. You
            acknowledge and agree that the meWOW Parties will have no liability
            for any: (i) errors, mistakes, or inaccuracies of content; (ii)
            personal injury, property damage, or other harm resulting from your
            access to or use of our Site; (iii) any unauthorized access to or
            use of our servers, any personal information, or user data; (iv) any
            interruption of transmission to or from our Site; (v) any bugs,
            viruses, trojan horses, or the like that may be transmitted on or
            through our Site; or (vi) any damages, losses, costs, expenses, or
            liabilities of any kind incurred as a result of any content posted
            or shared through our Site.
          </li>
          <li>
            You acknowledge and agree that any material or information
            downloaded or otherwise obtained through our Site, including any
            meWOW Video, is done at your own risk and that you will be solely
            responsible for any damages, losses, costs, expenses, or liabilities
            arising from or in connection with doing so. No advice or
            information, whether oral or written, obtained by you from us or
            through our Site, including through a meWOW Video, will create any
            warranty not expressly made by us.
          </li>
          <li>
            You acknowledge and agree that when using our Site, you will be
            exposed to content from a variety of sources, and that meWOW is not
            responsible for the accuracy, usefulness, safety, legality, or
            intellectual property rights of or relating to any such content. You
            further understand and acknowledge that you may be exposed to
            content that is inaccurate, offensive, indecent, objectionable, or
            harassing, and you agree to waive, and hereby do waive, any legal or
            equitable rights or remedies you have or may have against any meWOW
            Party with respect thereto.
          </li>
          <li>
            To the fullest extent permitted by applicable law, you acknowledge
            and agree that in no event will any meWOW Party be liable to you or
            to any third party for any indirect, special, incidental, punitive,
            or consequential damages (including for loss of profits, revenue, or
            data) or for the cost of obtaining substitute products, arising out
            of or in connection with these Terms, however caused, whether such
            liability arises from any claim based upon contract, warranty, tort
            (including negligence), strict liability, or otherwise, and whether
            or not meWOW has been advised of the possibility of such damages.
          </li>
          <li>
            To the maximum extent permitted by applicable law, our total
            cumulative liability to you or any third party under these Terms,
            including from all causes of action and all theories of liability,
            will be limited to and will not exceed the fees actually received by
            meWOW from you during the 12 months preceding the claim giving rise
            to such liability.
          </li>
          <li>
            You agree that the limitations of damages set forth above are
            fundamental elements of the basis of the bargain between meWOW and
            you.
          </li>
        </ul>
        <p className="title_normal">meWOW CUSTOMER REFERRAL PROGRAM</p>
        <p>Last Updated: July 2, 2020</p>
        <p>
          These Additional Terms (which are Additional Terms as described in
          Section 4 of the Site Terms of Service (“Site Terms”) govern your use
          of the Customer Referral Program (defined below). “You” and “User”
          refers to you as a user of the Site and of the Customer Referral
          Program.
        </p>
        <ul className="group_number_text">
          <li>
            We provide each User who has created a Site account with a unique
            referral URL (“URL”) that may be shared with the User’s friends,
            family members, or other personal contacts and used by them when
            they create a User Site account for the first time, pursuant to the
            terms set out below (“Customer Referral Program”).
          </li>
          <li>
            Participation in the Customer Referral Program is limited to: (a)
            each User; and (b) User’s friends, family members, or personal
            contacts who have never either: (i) purchased a meWOW Video or(ii)
            created a Site account.{' '}
          </li>
          <li>
            For each eligible friend, family member, or personal contact who
            creates a User Site account for the first time using your URL (each,
            a “Referred User”), meWOW will issue 5% discount code to both
            Referring user and the Referred User for the first purchase of a
            meWOW Video
            <ul className="group_word_text">
              <li>
                After the Referred User creates a Site account, meWOW will issue
                a Customer Referral Code to the Referred User’s Site account;
                and
              </li>
              <li>
                After the Referred User makes their first request on the Site
                for a meWOW Video and that request is paid for and fulfilled
                (excluding any meWOW Video for which a promo code was used and,
                at meWOW’s discretion, that was subject to a promotion), meWOW
                will issue a Customer Referral Credit for that Referred User to
                the referring User’s (your) account (in approximately one or two
                business days from the date on which the request for the meWOW
                video was fulfilled).
              </li>
            </ul>
          </li>
          <li>
            Any Customer Referral Credit received from meWOW will be maintained
            in your Site account and may be redeemed only for your purchase of a
            meWOW Video within 30 days after receiving referral credit from our
            Site. If your Site account has a credit balance when you make a
            purchase while logged into your Site account, the balance will be
            redeemed for that purchase (until fully redeemed) and your payment
            card or internet-banking will be charged for any remaining portion
            of the purchase price; any remaining balance will remain in your
            Site account to be redeemed for your next purchase. If you have a
            credit balance in your Site account, you may not use a promo code
            and, at meWOW’s discretion, might not be able to participate in an
            offering that is subject to a promotion. A credit balance is not
            refundable, may not be redeemed for cash, cannot be transferred,
            cannot be used outside of the Site, and expires or extinguishes
            immediately when redeemed.
          </li>
          <li>
            The Customer Referral Program may be used only for personal purposes
            and you may share your URL only with your personal connections. You
            may not advertise your URL or share it with anyone other than your
            friends, family, or other personal contacts. The Customer Referral
            Program cannot be used for any commercial purpose, including
            affiliate lead generation. The Customer Referral Program is void
            where prohibited by law.
          </li>
          <li>
            You represent and warrant that when you share your URL with your
            personal connections, you will: (a) distribute it in a personal
            manner; (b) not distribute it in a bulk manner; and (c) not create
            or send any unsolicited commercial email or a “spam” message under
            any applicable law or regulation. Any sharing that does not meet
            these requirements is expressly prohibited and constitutes grounds
            for immediate termination of your Site account, including deletion
            of any Customer Referral Credits, and your participation in the
            Customer Referral Program. Any Site account created using a URL that
            was distributed through an unauthorized channel will not be valid
            and any Customer Referral Fees issued in connection with that Site
            account may be revoked.
          </li>
          <li>
            We reserve the right to deactivate your Site account and any
            Referred User’s Site account, cancel all related Customer Referral
            Credits, and seek repayment, if we determine, in our sole
            discretion, that either you or the Referred User has: (a) tampered
            with the Customer Referral Program; (b) used (or attempted to use)
            the Customer Referral Program in a fraudulent, abusive, unethical,
            unsportsmanlike, or otherwise questionable or suspicious manner; (c)
            breached any of these Additional Terms; or (d) violated any law or
            regulation or infringed or violated the rights of any third party.
          </li>
          <li>
            The terms of the Customer Referral Program are subject to change at
            any time, for any reason, in our sole discretion. meWOW reserves, at
            any time, for any reason, in its sole discretion to:
            <ul className="group_word_text">
              <li>suspend or terminate the Customer Referral Program;</li>
              <li>
                disqualify anyone from participating in the Customer Referral
                Program; and
              </li>
              <li>
                revoke, refuse to issue, or refuse to redeem any Customer
                Referral Credits that we suspect were generated through improper
                channels or in violation of these Additional Terms.
              </li>
            </ul>
            Without limiting the foregoing, we reserve the right to review and
            investigate activities undertaken in connection with the Customer
            Referral Program and suspend your Site account, revoke all Customer
            Referral Fees, or take other action in our sole discretion.
          </li>
        </ul>
        <hr />
        <h1 className="title">Acceptable Use Policy</h1>
        <p>Last Updated: July 2, 2020</p>
        <p>
          Your use of our Site (collectively, the meWOW™ website, mobile
          application (“App”), and services we provide through them) is governed
          by this Acceptable Use Policy. Terms that are defined in the Site
          Terms of Service or Creator Terms of Service will have the same
          meaning in our Acceptable Use Policy.
        </p>
        <p>
          You are responsible for your use of any meWOW Video, your Site
          account, our Site, and any Submission (if you are a Site user) or
          CREATOR Content (if you are a Creator User). Our goal is to create a
          positive, useful, and safe user experience. To promote this goal, we
          prohibit certain kinds of conduct that may be harmful.
        </p>
        <ul className="group_number_text">
          <li>
            You represent and warrant that:
            <ul className="group_word_text">
              <li>
                you will not use a false identity or provide any false or
                misleading information;
              </li>
              <li>
                you will not create an account if you (or any Site account that
                you created or controlled) have previously been removed or
                banned from our Site;
              </li>
              <li>
                you will not use or authorize the use of any meWOW Video for any
                purposes other than: (i) the specific limited purposes set forth
                in the Site Terms; (ii) those set out in any applicable
                Additional Terms; or (iii) if you are a Creator User, those set
                out in any other written agreement; and
              </li>
              <li>
                you will not:
                <ul className="group_roman_text">
                  <li>violate any law, regulation, or court order;</li>
                  <li>
                    violate, infringe, or misappropriate the intellectual
                    property, privacy, publicity, moral or "droit moral," or
                    other legal rights of any third party;
                  </li>
                  <li>
                    take any action (even if requested by another) that is, or
                    submit, post, share, or communicate anything that is, or
                    that incites or encourages, action that is, explicitly or
                    implicitly: illegal, abusive, harassing, threatening,
                    hateful, racist, derogatory, harmful to any reputation,
                    pornographic, indecent, profane, obscene, or otherwise
                    objectionable (including nudity);
                  </li>
                  <li>
                    send advertising or commercial communications, including
                    spam, or any other unsolicited or unauthorized
                    communications;
                  </li>
                  <li>
                    engage in spidering or harvesting, or participate in the use
                    of software, including spyware, designed to collect data
                    from our Site, including from any user of our Site;
                  </li>
                  <li>
                    transmit any virus, other computer instruction, or
                    technological means intended to, or that may, disrupt,
                    damage, or interfere with the use of computers or related
                    systems;
                  </li>
                  <li>stalk, harass, threaten, or harm any third party;</li>
                  <li> impersonate any third party;</li>
                  <li>
                    participate in any fraudulent or illegal activity, including
                    phishing, money laundering, or fraud;
                  </li>
                  <li>
                    use any means to scrape or crawl any part of our Site;
                  </li>
                  <li>
                    attempt to circumvent any technological measure implemented
                    by us, any of our providers, or any other third party
                    (including another user) to protect us, our Site, users,
                    Recipients, or any other third party;
                  </li>
                  <li>
                    access our Site to obtain information to build a similar or
                    competitive website, application, or service;
                  </li>
                  <li>
                    attempt to decipher, decompile, disassemble, or reverse
                    engineer any of the software or other underlying code used
                    to provide our Site; or
                  </li>
                  <li>
                    advocate, encourage, or assist any third party in doing any
                    of the foregoing.
                    <p></p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Investigations:
            <p>
              You acknowledge and agree that we are not obligated to monitor
              access to or use of our Site by you or third parties (including
              monitoring any meWOW Video, Creator Content, Submission, or
              Feedback), but we have the right to do so to operate our Site;
              enforce this Policy or our Terms; or comply with applicable law,
              regulation, court order, or other legal, administrative, or
              regulatory request or process; or otherwise.
            </p>
          </li>
        </ul>
      </TermsOfServiceContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsOfServiceContainer);
