import styled from 'styled-components';
import {
  COLOR,
  // FONT
} from 'assets';

export const AgencyFormAgentStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .item_init {
    .label {
      span {
        margin-left: 5px;
        color: ${COLOR.RED_1};
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .box_label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .label {
      min-width: 100px;
      margin-right: 10px;
    }
    .item_checkout {
      margin-right: 8px;
    }
  }
  .group_show {
    margin-bottom: 10px;
  }
  .button_layer {
    text-align: right;
  }
`;
