import { ROUTE_PATH } from 'helpers';
import LoginContainer from 'containers/LoginContainer';
import SignUpContainer from 'containers/SignUpContainer';
import PrivacyPolicyContainer from 'containers/PrivacyPolicyContainer';
import TermsOfServiceContainer from 'containers/TermsOfServiceContainer';

var pageNormalRoutes = [
  {
    keyIndex: 1,
    path: ROUTE_PATH.LOGIN,
    exact: true,
    name: 'login',
    icon: 'login',
    component: LoginContainer,
  },
  {
    keyIndex: 2,
    path: ROUTE_PATH.SIGN_UP,
    exact: true,
    name: 'signup',
    icon: 'signup',
    component: SignUpContainer,
  },
  {
    keyIndex: 3,
    path: ROUTE_PATH.SIGN_UP_REFERAL,
    exact: true,
    name: 'signup',
    icon: 'signup',
    component: SignUpContainer,
  },
  {
    keyIndex: 4,
    path: ROUTE_PATH.PRIVACY,
    exact: true,
    name: 'privacy policy',
    icon: 'privacy policy',
    component: PrivacyPolicyContainer,
  },
  {
    keyIndex: 5,
    path: ROUTE_PATH.TERMS,
    exact: true,
    name: 'privacy policy',
    icon: 'privacy policy',
    component: TermsOfServiceContainer,
  },
];

export default pageNormalRoutes;
