import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { CreatorUploadFormSec2Styled } from './styled';
// import { iconImages } from 'assets'
import { VALIDATE } from 'helpers';
import { ButtonCustom, InputUploadFIle } from 'components'; 

class CreatorUploadFormSec2 extends React.Component {
  state = {};

  uploadFile = (file) => {
    this.setState({
      uploadFile: true,
    })
  }

  render() {
    const { handdleSave, handleSubmit,initialValues } = this.props;
    const {uploadFile} =this.state;
    return (
      <CreatorUploadFormSec2Styled>
        <form onSubmit={handleSubmit(handdleSave)}>
          <fieldset>
            <legend>Creator Poster</legend>
            <div className="item_layout">
              <Field
                theme_standard
                name="photo_file"
                accept="image/x-png,image/gif,image/jpeg"
                buttonLabel="Upload Creator Picture"
                component={InputUploadFIle}
                validate={VALIDATE.REQUIRE}
                title="Photo"
                haveFilePhoto = {initialValues&&initialValues.photo_file ? true : false}
                onChange={this.uploadFile}
              />
            </div>
            <div className="item_layout">
              <Field
                theme_standard
                name="video_file"
                accept="video/mp4,video/x-m4v,video/*"
                buttonLabel="Upload Creator Video"
                component={InputUploadFIle}
                title="Video"
                haveFileVideo = {initialValues&&initialValues.video_file ? true : false}
                onChange={this.uploadFile}
              />
            </div>
            <div className="button_layer">
              <ButtonCustom 
                theme_haveFile 
                label="SAVE" 
                uploadFile ={uploadFile}
                disabled = {uploadFile? false : true}
              />
            </div>
          </fieldset>
        </form>
      </CreatorUploadFormSec2Styled>
    );
  }
}

export default reduxForm({
  form: 'CreatorUploadFormSec2', // a unique identifier for this form
  enableReinitialize: true,
})(CreatorUploadFormSec2);
