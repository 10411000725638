import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxDetailStyled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        .img_icon {
            background-image: ${({ src }) => `url(${src})`};
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 250px;
            border-radius: 8px;
            margin-bottom: 10px;
        }
        .text_layer1 {
            font-size: ${FONT.SIZE_6};
            color: ${COLOR.BLACK_4};
            font-weight: 600;
        }
        .text_layer2 {
            font-size: ${FONT.SIZE_2};
            font-weight: 400;
        }
    }
`