import React, { useState, useMemo, useEffect } from 'react';
import { SidebarStyled } from './styled';
import {
  ProSidebar,
  SidebarContent,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import { iconImages } from 'assets';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';

export const Sidebar = (props) => {
  const [isShow, setisShow] = useState(true);
  const { cmsRoutes } = props;
  let location = useLocation();
  const [menuActive, setMenuActive] = useState();
  const [windowWidth, setWindowWidth] = useState(document.body.clientWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(document.body.clientWidth);
    });
  }, []);

  useMemo(() => {
    if (location.pathname) {
      setMenuActive(`/admin/${location.pathname.split('/')[2].toLowerCase()}`);
    }
  }, [location]);

  const handleClickMenu = (key) => {
    setMenuActive(key);
    setisShow(true);
  };

  const handleClickHide = () => {
    setisShow(!isShow);
  }

  return (
    <SidebarStyled isShow={isShow}>
      <div className="header" >
        {!isShow ? <img className="img_header" src={iconImages['logo.png']} alt="" /> :
          <img className="img_header2" src={iconImages['logo_web.png']} alt="" />
        }
        <div className="menu_icon" onClick={() => handleClickHide()}>
          {windowWidth < 500 && isShow ?
            <img src={iconImages['menu.png']}
              className="menu_icon_phone"
              alt=""
            /> :
            <img src={iconImages['next.png']}
              className={`${isShow ? 'rotate' : ''}`}
              alt=""
            />
          }
        </div>
      </div>
      <div className="body_side">
        <SidebarContent>
          <ProSidebar >
            <Menu iconShape="square" >
              {cmsRoutes &&
                cmsRoutes
                  .filter((e) => !e.notShow)
                  .map((e, i) => {
                    return (
                      <MenuItem
                        key={i}
                        active={e.path === menuActive}
                        onClick={() => handleClickMenu(e.path)}
                      >
                        <div className="txt_body">
                          <div className="flex_1">
                            <img className="icon" src={iconImages[icon[i]]} alt="" />
                          </div>
                          <div className="flex_2">
                            {!isShow && e.name} <Link to={e.path} />
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
            </Menu>
          </ProSidebar>
        </SidebarContent>
      </div>
      <hr className="hr_layout" />
      <div className="footer_layout">
        <a href='/'>
          <div className="flex_exit" >
            <img className="icon_exit" src={iconImages["exit.png"]} alt="" />
            {!isShow && <div className="txt_exit">
              Exit Admin
              </div>}
          </div>
        </a>
      </div>
    </SidebarStyled>
  );
};
const icon = [
  "person_icon.png", "category_icon.png", "cat_product_1.png", "wheels.png","money_icon.png", "bookbank.png", "order.jpg", "coupon.png", "vote.jpg", "agent.jpg", "agent_2.jpeg"
];
