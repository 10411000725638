import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxVideoImageStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    cursor: pointer;
    position: relative;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 215px;
    width: 165px;
    border-radius: 0.6rem;
    .logo_play {
      width: 80px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index:10;
      opacity: 0.8;
    }
    .back_logo{
      background-color:rgb(0,0,0,0.6);
      width:65.5px;
      height:65.5px;
      border-radius:50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index:0;
      margin: auto;
    }
    .txt_label {
      padding: 0 4px;
      position: absolute;
      bottom: 14px;
      right: 10px;
      background: #00000080;
      color: white;
      font-size: 14px;
    }
    .group_text {
      position: absolute;
      bottom: 30px;
      left: 0px;
      right: 0px;
      padding: 5px 15px;
      background: ${COLOR.BLUE_7};
      min-height: 82px;
      .name_layer {
        margin-top: 11px;
        font-size: ${FONT.SIZE_12};
        color: ${COLOR.YELLOW_4};
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .detail_layer {
        font-size: ${FONT.SIZE_8};
        color: ${COLOR.WHITE_1};
        font-weight: 600;
      }
    }
    &:hover {
      transform: translateY(-0.0825rem);
    }
    &.dynamic {
      width: 100%;
      height: 0;
      padding-bottom: 150%;
      .name_layer {
        margin-top: 11px;
        font-size: 1.3em;
      }
      .detail_layer {
        font-size: 1em;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      height: 215px;
      width: 165px;
      .coming_soon {
        img {
          width: 140px;
        }
        .logo_coming {
          margin: 50% 8%;
        }
      }
      .group_text {
        bottom: 10px;
        min-height: 35px;
        .name_layer {
          margin-top: 0px;
          font-size: 14px;
        }
        .detail_layer {
          font-size: 12px;
        }
      }
    }
  }
`;
