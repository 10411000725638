import styled from "styled-components"
import {
  // COLOR, FONT 
} from 'assets';

export const VoteContainerStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .main_layout {
      .tab_layout {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 50%;
        .title_layout {
          margin-bottom: 10px;
          font-weight: bold;
        }
      }
      .button_edit {
        padding: 2px 10px;
        font-size: 16px;
        background: #0059b3;
        color: white;
        font-weight: bold;
        border: 1px solid #0032b3;
        outline: none;
      }
      .div_right {
        text-align: right;
      }
    }
`