import styled from 'styled-components';
import {
  SCREEN,
  // COLOR,
  // FONT
} from 'assets'

export const CreatorServicesFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .grop_form {
    .box_label {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .label {
        min-width: 120px;
        margin-right: 10px;
      }
      .input_layer {
        width: 300px;
      }
      .item_checkout {
        margin-right: 8px;
      }
    }
    .button_layer {
      display: flex;
      justify-content: flex-end;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .grop_form {
      .box_label {
        flex-direction: column;
        align-items: flex-start;
        .label {
          text-align: left;
        }
        .input_layer {
          width: 200px;
        }
      }
    }
  }
`;
