import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxImageStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    cursor: pointer;
    position: relative;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 215px;
    width: 165px;
    border-radius: 0.6rem;
    .coming_soon {
      .logo_coming {
        // margin: 50% 14%;
        width: 85%;
        margin: 50% 7%;
      }
    }
    .group_text {
      position: absolute;
      bottom: 30px;
      left: 0px;
      right: 0px;
      padding: 5px 15px;
      background: ${COLOR.BLUE_7};
      min-height: 82px;
      .name_layer {
        margin-top: 11px;
        font-size: ${FONT.SIZE_12};
        color: ${COLOR.YELLOW_4};
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .detail_layer {
        font-size: ${FONT.SIZE_8};
        color: ${COLOR.WHITE_1};
        font-weight: 600;
      }
    }
    &:hover {
      transform: translateY(-0.0825rem);
    }
    &.dynamic {
      width: 100%;
      height: 0;
      padding-bottom: 150%;
      .name_layer {
        margin-top: 11px;
        font-size: 1.3em;
      }
      .detail_layer {
        font-size: 1em;
      }
    }
  }
  .theme_categories{
    cursor: pointer;
    position: relative;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0.6rem;
    .txt_top {
      padding: 250px 0 0 10%;
      font-size: ${FONT.SIZE_12};
      color: ${COLOR.WHITE_1};
      font-weight: 600;
      text-shadow: 2px 2px 2px #24478f;
    }
    &:hover {
      transform: translateY(-0.0825rem);
    }
    &.dynamic {
      object-fit: cover;
      width: 100%;
      height: 300px;
      .name_layer {
        margin-top: 11px;
        font-size: 1.3em;
      }
      .detail_layer {
        font-size: 1em;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .theme_categories{
      height: 255px;
      width: 400px;
      .txt_top {
        text-align:center;
        padding: 50% 0 0 0;
        font-size: ${FONT.SIZE_10};
      }
    }
    .group_text {
      bottom: 10px;
      min-height: 35px !important;
      .name_layer {
        margin-top: 0px !important;
        font-size: 14px !important;
      }
      .detail_layer {
        font-size: 12px !important;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_categories{
      height: 165px;
      width: 260px;
      .txt_top {
        text-align:center;
        padding: 48% 0 0 0;
        font-size: ${FONT.SIZE_7};
      }
    }
    .theme_standard {
      height: 215px;
      width: 165px;
      .group_text {
        bottom: 10px;
        min-height: 35px;
        .name_layer {
          margin-top: 0px;
          font-size: 14px;
        }
        .detail_layer {
          font-size: 12px;
        }
      }
    }
  }
`;
