import styled from 'styled-components';

export const PaginationCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_pagination {
    .pagination {
      display: flex;
      width: 100%;
      list-style: none;
      justify-content: center;
      margin: 0;
      padding: 0;
      li {
        cursor: pointer;
        a {
          padding: 0 10px;
        }
      }
      .active {
        color: red;
      }
    }
  }
`;
