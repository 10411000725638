import React, { useRef, useState, useEffect } from 'react';
import { VideoPlayerStyled } from './styled';
import { iconImages } from 'assets';

export const VideoPlayer = ({
  url,
  useCustomRef,
  onRef,
  key,
  txtEvent,
  ...props
}) => {
  const videoRef = useRef();
  const [isShowPlayIcon, setIsShowPlayIcon] = useState(true);

  const handleClickPlay = () => {
    videoRef.current.play();
    setIsShowPlayIcon(false);
  };

  const handleEndPlay = () => {
    setIsShowPlayIcon(true);
  };

  useEffect(() => {
    if (!useCustomRef && videoRef) {
      videoRef.current.addEventListener('ended', handleEndPlay);
      return videoRef.current.removeEventListener('ended', () => {});
    }
  }, [useCustomRef, videoRef]);

  const handleClickVideo = () => {
    if (!isShowPlayIcon) {
      videoRef.current.pause();
      setIsShowPlayIcon(true);
    }
  };

  return (
    <VideoPlayerStyled className="video_container" {...props}>
      <video
        ref={useCustomRef ? onRef : videoRef}
        src={url}
        controls={props.controls || true}
        preload="meta"
        {...props}
        onClick={handleClickVideo}
      />
      {!useCustomRef && (
        <>
          <div className="txt_label">{txtEvent}</div>
          {isShowPlayIcon && (
            <div className="icon_play" onClick={handleClickPlay}>
              <img
                src={iconImages['triangle_right-white.png']}
                alt="icon play"
              />
            </div>
          )}
        </>
      )}
    </VideoPlayerStyled>
  );
};
