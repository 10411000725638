import React from 'react';
import { TrueWalletStyled } from './styled';
import { useSelector } from 'react-redux';
import { userService } from 'apiService';
import { toast } from 'react-toastify';
import { TrueWalletForm } from 'forms';

export const TrueWallet = (props) => {
  const reduxForm = useSelector((state) => state.form.TrueWalletForm);
  const { children, invoiceID, amount, callback } = props;

  const handleClickPayment = () => {
    callback && callback('truewallet');
  };

  const handleClickSubmit = async () => {
    const { values } = reduxForm;
    const { Omise } = window;
    Omise.setPublicKey(process.env.REACT_APP_OMISE_PUBLIC_KEY);
    Omise.createSource(
      'truemoney',
      {
        amount: Number(amount) * 100,
        currency: 'THB',
        phone_number: values.contact_phone,
      },
      async (statusCode, response) => {
        let params = {
          gateway: 'omise',
          type: 'truemoney',
          token: response.id,
        };
        let res = await userService.POST_PAYMENT_DETAIL(invoiceID, params);
        if (res && res.success) {
          toast.success('ชำระสำเร็จ');
          window.location.replace(res.data.authorize_uri);
          callback && callback();
        }
      }
    );
  };

  return (
    <TrueWalletStyled>
      {children ? (
        <div onClick={handleClickPayment}>{children}</div>
      ) : (
        <TrueWalletForm handleClickSubmit={handleClickSubmit} />
      )}
    </TrueWalletStyled>
  );
};
