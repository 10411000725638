import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { LoginContainerStyled } from './styled';
import { connect } from 'react-redux';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { LoginForm } from 'forms';
import { toast } from 'react-toastify';

class LoginContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  responseFacebook = async (e) => {
    let params = {
      provider: 'facebook',
      access_token: e.accessToken,
    };
    const res = await userService.POST_LOG_IN(params);
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: 'login',
        action: 'login_success_facebook',
      });

      localStorage.setItem('wowToken', res.token);
      sessionStorage.setItem('wowLogin', true);
      this.fetchProfile();
    }
  };

  fetchProfile = async () => {
    const res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      ReactGA.set({
        userId: res.data.id,
        gender: res.data.gender,
      });
      this.props.setReduxUserAuth(res.data);
      this.props.history.push('/');
    }
  };

  handleClickLoginEmail = async () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let params = {
        ...values,
      };
      let res = await userService.POST_LOG_IN(params);
      if (res && res.success) {
        // Tracking user event
        ReactGA.event({
          category: 'login',
          action: 'login_success_email',
        });

        localStorage.setItem('wowToken', res.token);
        sessionStorage.setItem('wowLogin', true);
        toast.success('ล็อคอินสำเร็จ');
        this.fetchProfile();
      } else {
        toast.error('ข้อมูลไม่ตรงในระบบ');
      }
    }
  };

  render() {
    return (
      <LoginContainerStyled>
        <div className="login_container">
          <div className="layer_one">
            <LoginForm
              responseFacebook={this.responseFacebook}
              handleClickLoginEmail={this.handleClickLoginEmail}
              // handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.LoginForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
