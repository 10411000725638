import React from 'react'
import { BoxImage2Styled } from './styled'
import cx from "classnames";
import { COLOR } from 'assets'
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export const BoxImage2 = ({ theme_standard, index_key, src, text1, text2, text3, icon_pic }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <LazyLoadComponent>
            <BoxImage2Styled src={src}>
                <div key={index_key} className={customClass} style={{ border: src ? `none` : `1px solid ${COLOR.GREY_2}` }}>
                    {
                        !src &&
                        <div className="group_action">
                            <div className="round_layer">
                                {text1}
                            </div>
                            <img src={icon_pic} alt="" />
                            <div>
                                {text2}
                            </div>
                            <div>
                                {text3}
                            </div>
                        </div>
                    }
                </div>
            </BoxImage2Styled>
        </LazyLoadComponent>
    )
}